import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { JOBService } from 'src/app/utilities/job.service';
import { SignaturePad } from 'angular2-signaturepad';
import { DomSanitizer } from '@angular/platform-browser';
import * as markerjs2 from 'markerjs2';
import { CommonService } from 'src/app/utilities/common.service';

@Component({
  selector: 'app-tp-shf',
  templateUrl: './shf.component.html',
  styleUrls: ['./shf.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService]
})
export class TpShfComponent implements OnInit {
  @Input() jobId: any
  @Input() jobAllocationId: any;
  @Input() jobAllocationDetails: any;
  companyId: any;
  userDetails: any;

  prework_yn: any;
  accidents_yn: any;
  pdfUrl: any;
  reportData: any;
  pdfGenerated: any;
  declarationValue: any = false;
  statusUI: any;
  edited: any;
  shfId: any;
  visibleSidebar2: boolean;
  selectedValues: any;
  completedDate: Date;
  imageList: any[] = [];
  loading: Boolean = false;
  appProps: any;

  control_1_options: any[] = [];
  control_2_options: any[] = [];
  control_3_options: any[] = [];
  control_4_options: any[] = [];
  criteria_1_options: any[] = [];
  criteria_2_options: any[] = [];
  criteria_3_options: any[] = [];
  criteria_4_options: any[] = [];
  risk_1_options: any[];
  risk_2_options: any[];
  risk_3_options: any[];
  risk_4_options: any[];
  selectedRisk_1: any;
  selectedRisk_2: any;
  selectedRisk_3: any;
  selectedRisk_4: any;
  selectedControls_1: any[] = [];
  selectedControls_2: any[] = [];
  selectedControls_3: any[] = [];
  selectedControls_4: any[] = [];
  selectedCriteria_1: any[] = [];
  selectedCriteria_2: any[] = [];
  selectedCriteria_3: any[] = [];
  selectedCriteria_4: any[] = [];
  criteria_name_1: any;
  criteria_name_2: any;
  criteria_name_3: any;
  criteria_name_4: any;
  criteria_name_5: any;
  selectedCriteria_1_desc: any;
  selectedCriteria_2_desc: any;
  selectedCriteria_3_desc: any;
  selectedCriteria_4_desc: any;
  selectedCriteria_5_desc: any;
  selectedControls_1_desc: any;
  selectedControls_2_desc: any;
  selectedControls_3_desc: any;
  selectedControls_4_desc: any;
  usersList: any[] = [];
  usersListA: any[] = [];
  selectedUsers: any[] = [];
  completedBy: any;
  declaration: any
  majorRisk: any;
  selectedCompUser: any = {};
  ABCUsers: any[] = [];
  signatories: any[] = [];
  maxDate: any;
  minDate: any;
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  disableEdit:boolean=false;
  siteHazardInfo: any;/* = {
    id: null,
    job_id: null,
    job_alloc_id: null,
    completed_by: null,
    completion_time: null,
    persons_on_site: null,
    risk_details: null,
    other_staff_details: null,
    process_id: null,
    criteria_1: null,
    risk_rating_1: null,
    control_1: null,
    criteria_2: null,
    risk_rating_2: null,
    control_2: null,
    criteria_3: null,
    risk_rating_3: null,
    control_3: null,
    criteria_4: null,
    risk_rating_4: null,
    control_4: null,
    criteria_5: null,
    risk_rating_5: null,
    control_5: null,
    declaration: null,
    owner: null,
    created_by: null,
    last_modified_by: null,
    created_at: null,
    last_updated_at: null,
    prework_yn: null,
    accidents_yn: null,
    accident_comments: null,
    equipment: null,
    task: null,
    task_other: null,
    Questionnaire: null,
    status: null,
    other_hazard_1: null,
    other_hazard_2: null,
    other_hazard_3: null,
    other_hazard_4: null,
    other_control_1: null,
    other_control_2: null,
    other_control_3: null,
    other_control_4: null,
    custom_staff_name: null
  }; */

  private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    minWidth: 2,
    dotSize: 1,
    canvasWidth: 500,
    canvasHeight: 200,
  };
  declared: boolean=false;

  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public JobService: JOBService,
    public sanitizer: DomSanitizer,
    private commonservice: CommonService
  ) {
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    //console.log('userinfo', this.userDetails);
    this.staticDropDowns();
  }

  async ngOnInit() {
    this.appProps = this.commonservice.getAppProperties();
    if (typeof this.jobId === 'undefined') {
      if (typeof this.config.data == 'undefined' || typeof (this.config.data.jobId) == 'undefined') {
        if (this.jobId == null || this.jobId === '') {
          this.jobId = parseInt(this.route.snapshot.paramMap.get('jobId'));
          if (this.jobAllocationId === null || this.jobAllocationId === '') {
            this.jobAllocationId = '1';
          } else {
            this.jobAllocationId = parseInt(this.route.snapshot.paramMap.get('jobAllocationId'));
          }
        }
        let smryData: any = await this.crudService.getJobSummary(this.jobId, this.jobAllocationId).toPromise();
        this.jobAllocationDetails = await this.JobService.getJobInfo(smryData[0]);
      } else {
        this.jobId = this.config.data.jobId;
        this.jobAllocationId = this.config.data.jobAllocationId;
        this.jobAllocationDetails = this.config.data.jobSummary;
      }
    }
    this.crudService.getStaffByCID(this.jobAllocationDetails.Contractor).subscribe((data: any[]) => {
      this.usersListA = data;
      data.forEach(user => {
        user.full_name = user.first_name + ' ' + user.last_name;
        user.signature = null;
        user.signatureId = null;
        this.usersList.push(user);
      });
      this.getAllSHFData();
      this.setMinMaxDate();
    })
  }

  setMinMaxDate() {
    var completion_time = new Date();
    this.maxDate = new Date(completion_time.getFullYear(), completion_time.getMonth() + 3, completion_time.getDate());
    this.minDate = new Date(completion_time.getFullYear(), completion_time.getMonth() - 0, completion_time.getDate());
  }


  getAllSHFData() {
    this.crudService.getAllSHFData(this.jobId, this.jobAllocationId).subscribe((data: any[]) => {
      if (data.length > 0) {
        this.siteHazardInfo = data[0];
        if (this.siteHazardInfo.declaration == '2') {
          this.declaration = true;
          this.disableEdit = true; 
        } else {
          this.declaration = false;
          this.disableEdit = false; 
        }
        this.statusUI = this.siteHazardInfo.status;
        //console.log('this.siteHazardInfo', this.siteHazardInfo);
        if (this.siteHazardInfo.criteria_1 != null) {
          for (const criteria of this.siteHazardInfo.criteria_1.split(',')) {
            if (criteria !== '' && criteria !== null && criteria !== 'undefined') {
              let criteriaDetails = this.criteria_1_options.filter(val => criteria === val.id)[0];
              this.selectedCriteria_1.push(criteriaDetails);
            }
          }
        }
        if (this.siteHazardInfo.criteria_2 != null) {
          for (const criteria of this.siteHazardInfo.criteria_2.split(',')) {
            if (criteria !== '' && criteria !== null && criteria !== 'undefined') {
              let criteriaDetails = this.criteria_2_options.filter(val => criteria === val.id)[0];
              this.selectedCriteria_2.push(criteriaDetails);
            }
          }
        }
        if (this.siteHazardInfo.criteria_3 != null) {
          for (const criteria of this.siteHazardInfo.criteria_3.split(',')) {
            if (criteria !== '' && criteria !== null && criteria !== 'undefined') {
              let criteriaDetails = this.criteria_3_options.filter(val => criteria === val.id)[0];
              this.selectedCriteria_3.push(criteriaDetails);
            }
          }
        }

        if (this.siteHazardInfo.criteria_4 != null) {
          for (const criteria of this.siteHazardInfo.criteria_4.split(',')) {
            if (criteria !== '' && criteria !== null && criteria !== 'undefined') {
              let criteriaDetails = this.criteria_4_options.filter(val => criteria === val.id)[0];
              this.selectedCriteria_4.push(criteriaDetails);
            }
          }
        }
        if (this.siteHazardInfo.control_1 != null) {
          for (const control of this.siteHazardInfo.control_1.split(',')) {
            if (control !== '' && control !== null && control !== 'undefined') {
              let controlDetails = this.control_1_options.filter(val => control === val.id)[0];
              this.selectedControls_1.push(controlDetails);
            }
          }
        }

        if (this.siteHazardInfo.control_2 != null) {
          for (const control of this.siteHazardInfo.control_2.split(',')) {
            if (control !== '' && control !== null && control !== 'undefined') {
              let controlDetails = this.control_2_options.filter(val => control === val.id)[0];
              this.selectedControls_2.push(controlDetails);
            }
          }
        }

        if (this.siteHazardInfo.control_3 != null) {
          for (const control of this.siteHazardInfo.control_3.split(',')) {
            if (control !== '' && control !== null && control !== 'undefined') {
              let controlDetails = this.control_3_options.filter(val => control === val.id)[0];
              this.selectedControls_3.push(controlDetails);
            }
          }
        }


        if (this.siteHazardInfo.control_4 != null) {
          for (const control of this.siteHazardInfo.control_4.split(',')) {
            if (control !== '' && control !== null && control !== 'undefined') {
              let controlDetails = this.control_4_options.filter(val => control === val.id)[0];
              this.selectedControls_4.push(controlDetails);
            }
          }
        }
        if (this.siteHazardInfo.risk_rating_1) {
          this.selectedRisk_1 = this.risk_1_options.filter(val => this.siteHazardInfo.risk_rating_1 === val.id)[0];
        }
        if (this.siteHazardInfo.risk_rating_2) {
          this.selectedRisk_2 = this.risk_2_options.filter(val => this.siteHazardInfo.risk_rating_2 === val.id)[0];
        }
        if (this.siteHazardInfo.risk_rating_3) {
          this.selectedRisk_3 = this.risk_3_options.filter(val => this.siteHazardInfo.risk_rating_3 === val.id)[0];
        }
        if (this.siteHazardInfo.risk_rating_4) {
          this.selectedRisk_4 = this.risk_4_options.filter(val => this.siteHazardInfo.risk_rating_4 === val.id)[0];
        }

        /* this.selectedRisk_1 = { id: this.siteHazardInfo.risk_rating_1 };
        this.selectedRisk_2 = { id: this.siteHazardInfo.risk_rating_2 };
        this.selectedRisk_3 = { id: this.siteHazardInfo.risk_rating_3 };
        this.selectedRisk_4 = { id: this.siteHazardInfo.risk_rating_4 };
 */

        this.edited = true;
        this.selectedCompUser = { id: this.siteHazardInfo.completed_by };
        if(this.siteHazardInfo.completion_time != null){
          this.siteHazardInfo.completion_time = new Date(this.siteHazardInfo.completion_time);
        }
        else{
          this.siteHazardInfo.completion_time = new Date();
        }
        for (const user of this.siteHazardInfo.other_staff_details.split(',')) {
          this.selectedUsers.push({ id: user });
        }
        this.getSignaturesList();
      } else {
        this.siteHazardInfo = {
          id: null,
          job_id: null,
          job_alloc_id: null,
          completed_by: null,
          completion_time: null,
          persons_on_site: null,
          risk_details: null,
          other_staff_details: null,
          process_id: null,
          criteria_1: null,
          risk_rating_1: null,
          control_1: null,
          criteria_2: null,
          risk_rating_2: null,
          control_2: null,
          criteria_3: null,
          risk_rating_3: null,
          control_3: null,
          criteria_4: null,
          risk_rating_4: null,
          control_4: null,
          criteria_5: null,
          risk_rating_5: null,
          control_5: null,
          declaration: null,
          owner: null,
          created_by: null,
          last_modified_by: null,
          created_at: null,
          last_updated_at: null,
          prework_yn: null,
          accidents_yn: null,
          accident_comments: null,
          equipment: null,
          task: null,
          task_other: null,
          Questionnaire: null,
          status: null,
          other_hazard_1: null,
          other_hazard_2: null,
          other_hazard_3: null,
          other_hazard_4: null,
          other_control_1: null,
          other_control_2: null,
          other_control_3: null,
          other_control_4: null,
          custom_staff_name: null
        };
        this.selectedRisk_1 = null;
        this.selectedRisk_2 = null;
        this.selectedRisk_3 = null;
        this.selectedRisk_4 = null;
        /* this.selectedControls_1: any[] = [];
        this.selectedControls_2: any[] = [];
        this.selectedControls_3: any[] = [];
        this.selectedControls_4: any[] = [];
        this.selectedCriteria_1: any[] = [];
        this.selectedCriteria_2: any[] = [];
        this.selectedCriteria_3: any[] = [];
        this.selectedCriteria_4: any[] = []; */
        //this.setdummydata();
      }
      this.setnewReportData();

    });

    //   getSignatureBySiteHazardId(shfId);
  }

  async getSignaturesList2() {
    let signatureNum = 0;
    const promiseList = [];
    this.imageList = [];
    let res: any;
    res = await this.crudService.getSignatureBySiteHazardId(this.siteHazardInfo.id).toPromise();
    //console.log('signatures');
    // this.selectedUsers=[];
    //console.log(res);
    // count how much signatures in order to compare later
    const data = res.length > 0 ? res : [];
    data.forEach(da => {
      let customStaff = false;
      signatureNum++;
      const item = this.usersList.find(item => {
        if (da.user_id == null) {
          customStaff = true;
        }
        if (item.id == da.user_id) {
          return item;
        }
      });
      // assgin val to item if there is userid_matched
      this.usersList.forEach(item => {

        if ((item.id == da.user_id)) {
          //  item.signature={dataUrl:da.signature},
          //   item.signatureId=da.id
          //  let result = getImgFile("15168/6471/signature12157.jpeg.png");
          const result = this.getImgFile(da.signature);
          promiseList.push(result);
        }

      });
      if (customStaff) {
        const result = this.getImgFile(da.signature);
        promiseList.push(result);
      }
      let tempstaffName = null;
      let tempuserid = null;
      let tempLabel = null;
      if (customStaff) {
        tempstaffName = da.custom_staff_name;
        tempuserid = null;
        tempLabel = da.custom_staff_name;
      } else {
        const label = '';
        if (!(item === 'undefined')) {

        }
        tempstaffName = da.StaffName;
        tempuserid = da.user_id;
        tempLabel = label;
      }
      const obj = {
        id: da.id,
        name: tempLabel,
        site_hazard_id: this.siteHazardInfo.id ? this.siteHazardInfo.id : null,
        user_id: tempuserid,
        StaffName: tempstaffName,
        signature: { dataUrl: da.signature, img: da.signature },
        signatureimg: null
      }

      this.imageList.push({
        image: obj.StaffName,
        comments: 'All qualifications and skill certificates required to perform the work are upto date',
        remarks: 'remarks' + obj.id,
        SignURL: 'C://uploadImgs//' + obj.signature.dataUrl
      });
      // this.selectedUsers.push(obj);
    });
    /* $q.all(promiseList).then(function(values) {
      for (let i = 0; i < values.length; i++) {
        selUsers[i].signatureimg= values[i].data;
      }
      setnewReportData();
    });
    */
    const response = await Promise.all(promiseList);
    for (let i = 0; i < response.length; i++) {
      this.selectedUsers[i].signatureimg = response[i].data;
    }
  }

  updateSignatories() {
    this.selectedUsers.forEach(user => {
      let added = false;
      this.signatories.forEach(suser => {
        if (suser.id === user.id) {
          added = true;
        }
      });
      if (!added) {
        const obj = {
          id: null,
          name: user.full_name,
          site_hazard_id: this.siteHazardInfo.id ? this.siteHazardInfo.id : null,
          user_id: user.id,
          StaffName: user.full_name,
          signature: null,
          signatureimg: null
        }
        this.signatories.push(obj);
      }
    });
    const signatoriesList = this.signatories;
    this.signatories = [];
    signatoriesList.forEach(user => {
      this.selectedUsers.forEach(suser => {
        if (suser.id === user.id) {
          this.signatories.push(user);
        }
      });
    });
  }


  async getImageListforReport() {
    let res: any;
    this.imageList = [];
    res = await this.crudService.getSignatureBySiteHazardId(this.siteHazardInfo.id).toPromise();
    res.forEach(sign => {
      this.imageList.push({
        image: sign.StaffName,
        comments: 'All qualifications and skill certificates required to perform the work are upto date',
        remarks: 'remarks' + sign.id,
        SignURL: 'C://uploadImgs//' + sign.signature
      });
    });
  }

  async getSignaturesList() {
    if(this.siteHazardInfo.persons_on_site == null || this.siteHazardInfo.persons_on_site == '' ){
      this.selectedUsers=[]
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter persons on site', life: 1000 });
    }
    else{
      if(this.selectedUsers.length <= this.siteHazardInfo.persons_on_site){
      }
    }
    
    this.signatories = [];
    if (this.siteHazardInfo.id) {
      let res: any;
      res = await this.crudService.getSignatureBySiteHazardId(this.siteHazardInfo.id).toPromise();
      this.selectedUsers.forEach(user => {
        /* let canvas = document.querySelector("sign_"+user.id);
        let signPad = new SignaturePad(canvas); */

        const filteredRes = res.filter(val => user.id === val.user_id);
        if (filteredRes.length > 0) {
          const signs = filteredRes[0];
          this.crudService.getImageFile(signs.signature).subscribe(blob => {
            const objectURL = URL.createObjectURL(blob);
            let image_url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
            signs.signURL = image_url;
            signs.modified = true;
            signs.savedSignUrl = image_url;
            let users = this.usersList.filter(item => item.id === signs.user_id);
            let userInfo: any;
            if (users.length > 0) {
              userInfo = users[0];
              signs.full_name = userInfo.full_name ? userInfo.full_name : 'Custom Staff';
            } else {
              signs.full_name = userInfo.StaffName ? userInfo.StaffName : userInfo.custom_staff_name;// : 'Custom Staff';
            }
            signs.signPad = '';
            this.signatories.push(signs);
          });
        } else {
          // signs.signPad = signaturePad;
          const signs: any = {};
          const userDetails = this.usersList.filter(val => user.id === val.id);
          const userInfo = userDetails[0]
          this.crudService.getImageFile('dummy/sample/dummyImage.PNG').subscribe(blob => {
            const objectURL = URL.createObjectURL(blob);
            let image_url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
            signs.signURL = image_url;
            signs.savedSignUrl = image_url;
            signs.modified = false;
            signs.user_id = userInfo.id;
            signs.full_name = userInfo.full_name;
            this.signatories.push(signs);
          });
        }
      });
    } else {
      this.selectedUsers.forEach(user => {
        let signs: any = {};
        const userDetails = this.usersList.filter(val => user.id === val.id);
        const userInfo = userDetails[0]
        this.crudService.getImageFile('dummy/sample/dummyImage.PNG').subscribe(blob => {
          const objectURL = URL.createObjectURL(blob);
          let image_url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          signs.signURL = image_url;
          signs.savedSignUrl = image_url;
          signs.user_id = userInfo.id;
          signs.modified = false;
          signs.full_name = userInfo.full_name;
          this.signatories.push(signs);

          this.imageList.push({
            image: signs.StaffName,
            comments: 'All qualifications and skill certificates required to perform the work are upto date',
            remarks: 'remarks' + signs.id,
            SignURL: 'C://uploadImgs//' + image_url
          });
        })
      });
    }
  }

  async getImgFile(imgPath) {
    let res: any;
    res = await this.JobService.getImageFileByPath(imgPath);
    //console.log(res);
  }

  staticDropDowns() {
    this.criteria_name_1 = 'Weather Hazard';
    this.criteria_name_2 = 'Job Site Hazards';
    this.criteria_name_3 = 'Tree Hazards';
    this.criteria_name_4 = 'Manual Tasks Hazard';
    // this.criteria_name_5 = 'PPE (Personal Protective Equipment/Clothing)';
    this.criteria_1_options = [
      { id: '34', value: 'Rain' },
      { id: '35', value: 'Overcast' },
      { id: '36', value: 'Lightning Storm' },
      { id: '37', value: 'High Wind' },
      { id: '38', value: 'Very Hot' }];

    this.control_1_options = [
      { id: '44', value: 'PPE' },
      { id: '45', value: 'WHS' },
      { id: '46', value: 'Hydration' },
      { id: '47', value: 'Sun Cream' },
    ];

    this.risk_1_options = [
      { id: '39', value: 'Very Low', icon: 'red' },
      { id: '40', value: 'Low', icon: 'lightRed' },
      { id: '41', value: 'Medium', icon: 'yellow' },
      { id: '42', value: 'High', icon: 'lightGreen' },
      { id: '43', value: 'Very High', icon: 'green' },
    ];

    this.risk_2_options = [
      { id: '54', value: 'Very Low' },
      { id: '55', value: 'Low' },
      { id: '56', value: 'Medium' },
      { id: '57', value: 'High' },
      { id: '58', value: 'Very High' },
    ];

    this.risk_3_options = [
      { id: '71', value: 'Very Low' },
      { id: '72', value: 'Low' },
      { id: '73', value: 'Medium' },
      { id: '74', value: 'High' },
      { id: '75', value: 'Very High' },
    ];

    this.risk_4_options = [
      { id: '86', value: 'Very Low' },
      { id: '87', value: 'Low' },
      { id: '88', value: 'Medium' },
      { id: '89', value: 'High' },
      { id: '90', value: 'Very High' },
    ];

    this.criteria_3_options = [
      { id: '65', value: 'Deadwood' },
      { id: '66', value: 'Hollow Trunk' },
      { id: '67', value: 'Hangers' },
      { id: '68', value: 'Storm Damage' },
      { id: '69', value: 'Cavities' },
      { id: '70', value: 'Unbalanced Crown' },
    ];
    this.criteria_4_options = [
      { id: '80', value: 'Trees/Slips' },
      { id: '81', value: 'Lifting' },
      { id: '82', value: 'Falls From Heights' },
      { id: '83', value: 'Crush Injuries' },
      { id: '84', value: 'Fatigue' },
    ]
    this.control_2_options = [
      { id: '59', value: 'PPE' },
      { id: '60', value: 'Signs/Cones' },
      { id: '61', value: 'Site Awareness' },
      { id: '64', value: 'Isolated Hazards' },
    ];
    this.criteria_2_options = [
      { id: '48', value: 'Slippery Surfaces' },
      { id: '49', value: 'Falling Debris' },
      { id: '50', value: 'Traffic' },
      { id: '51', value: 'Drains/Pipes' },
      { id: '52', value: 'Loose Surfaces' },
      { id: '53', value: 'Electricity' }
    ];

    this.control_3_options = [
      { id: '76', value: 'Rigging' },
      { id: '77', value: 'Lowering Device' },
      { id: '78', value: 'Climbing Equipment' },
      { id: '79', value: 'Plant Use' },
      { id: '126', value: 'PPE' },
      { id: '127', value: 'SWMS' }

    ]

    this.control_4_options = [
      { id: '91', value: 'PPE' },
      { id: '92', value: 'Mechanical Aids' },
      { id: '93', value: 'Pre-Climbs Checks' },
      { id: '95', value: 'Handling Technique' },
      { id: '96', value: 'Aerial Rescue Kit' },
      { id: '128', value: 'SWMS' },
    ]
  }

  setdummydata() {
    this.selectedCriteria_1 = [{ id: '34' }];
    this.selectedControls_1 = [{ id: '44' }];

    this.selectedCriteria_2 = [{ id: '48' }];
    this.selectedControls_2 = [{ id: '59' }];

    this.selectedCriteria_3 = [{ id: '65' }];
    this.selectedControls_3 = [{ id: '76' }];

    this.selectedCriteria_4 = [{ id: '80' }];
    this.selectedControls_4 = [{ id: '91' }];

    this.selectedRisk_1 = { id: '39' };
    this.selectedRisk_2 = { id: '54' };
    this.selectedRisk_3 = { id: '71' };
    this.selectedRisk_4 = { id: '86' };

  }
  checkValue(declaration){
    if(declaration==true){
      this.declared=true
    }
    else{
      this.declared=false
    }
  }
  
  submit() {
    if(this.declared==true){
      if(this.selectedCompUser == '' || this.selectedCompUser == null || this.siteHazardInfo.persons_on_site == '' || this.siteHazardInfo.persons_on_site == null || this.siteHazardInfo.risk_details =='' || this.siteHazardInfo.risk_details == null || this.selectedUsers.length < 0 || this.selectedUsers == null || this.siteHazardInfo.completion_time== '' || this.siteHazardInfo.completion_time== null || this.selectedCriteria_1.length < 0 || this.selectedCriteria_1 == null || this.selectedControls_1.length < 0 || this.selectedControls_1 == null || this.selectedCriteria_2.length < 0 || this.selectedCriteria_2 == null || this.selectedControls_2.length < 0 || this.selectedControls_2 == null || this.selectedCriteria_3.length < 0 || this.selectedCriteria_3 == null || this.selectedControls_3.length < 0 || this.selectedControls_3 == null || this.selectedCriteria_4.length < 0 || this.selectedCriteria_4 == null || this.selectedControls_4.length < 0 || this.selectedControls_4 == null || !this.declaration ){
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill all criteria', life: 1000 });
      }
      else{
        let signLength = this.signatories.filter(val => val.modified === true).length;
        if(signLength == this.siteHazardInfo.persons_on_site)
        {
          this.confirmationService.confirm({
            message: 'Are you sure you want to Submit?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.save();
            }
          });
        }
        else
        {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Fill All the Signatures', life: 1000 });
        }
       
      }
    
    }
    else{
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please check the declaration', life: 1000 });
    }
   
  }
  async save() {

    /* if (selectUserWithSigNum < signatureNum) { */

    this.siteHazardInfo.completed_by = this.selectedCompUser.id;
    if (typeof this.selectedRisk_1?.id != 'undefined') {
      this.siteHazardInfo.risk_rating_1 = this.selectedRisk_1?.id;
    }
    if (typeof this.selectedRisk_2?.id != 'undefined') {
      this.siteHazardInfo.risk_rating_2 = this.selectedRisk_2?.id;
    }
    if (typeof this.selectedRisk_3?.id != 'undefined') {
      this.siteHazardInfo.risk_rating_3 = this.selectedRisk_3?.id;
    }
    if (typeof this.selectedRisk_4?.id != 'undefined') {
      this.siteHazardInfo.risk_rating_4 = this.selectedRisk_4?.id;
    }

    if (this.selectedUsers.length > 0) {
      const users = this.selectedUsers.map(function (item) {
        return item.id;
      });
      this.siteHazardInfo.other_staff_details = users;
    } else {
      this.siteHazardInfo.other_staff_details = null;
    }

    if (this.selectedCriteria_1.length > 0) {
      const users = this.selectedCriteria_1.map(function (item) {
        return item.id;
      });
      this.siteHazardInfo.criteria_1 = users;
    } else {
      this.siteHazardInfo.criteria_1 = null;
    }

    if (this.selectedCriteria_2.length > 0) {
      const users = this.selectedCriteria_2.map(function (item) {
        return item.id;
      });
      this.siteHazardInfo.criteria_2 = users;
    } else {
      this.siteHazardInfo.criteria_2 = null;
    }

    if (this.selectedCriteria_3.length > 0) {
      const users = this.selectedCriteria_3.map(function (item) {
        return item.id;
      });
      this.siteHazardInfo.criteria_3 = users;
    } else {
      this.siteHazardInfo.criteria_3 = null;
    }

    if (this.selectedCriteria_4.length > 0) {
      const users = this.selectedCriteria_4.map(function (item) {
        return item.id;
      });
      this.siteHazardInfo.criteria_4 = users;
    } else {
      this.siteHazardInfo.criteria_4 = null;
    }


    if (this.selectedControls_1.length > 0) {
      const users = this.selectedControls_1.map(function (item) {
        return item.id;
      });
      this.siteHazardInfo.control_1 = users;
    } else {
      this.siteHazardInfo.control_1 = null;
    }

    if (this.selectedControls_2.length > 0) {
      const users = this.selectedControls_2.map(function (item) {
        return item.id;
      });
      this.siteHazardInfo.control_2 = users;
    } else {
      this.siteHazardInfo.control_2 = null;
    }
    if (this.selectedControls_3.length > 0) {
      const users = this.selectedControls_3.map(function (item) {
        return item.id;
      });
      this.siteHazardInfo.control_3 = users;
    } else {
      this.siteHazardInfo.control_3 = null;
    }
    if (this.selectedControls_4.length > 0) {
      const users = this.selectedControls_4.map(function (item) {
        return item.id;
      });
      this.siteHazardInfo.control_4 = users;
    } else {
      this.siteHazardInfo.control_4 = null;
    }

    this.siteHazardInfo.declaration = '2';
    this.siteHazardInfo.other_hazard_1 = null;
    this.siteHazardInfo.other_hazard_2 = null;
    this.siteHazardInfo.other_hazard_3 = null;
    this.siteHazardInfo.other_hazard_4 = null;
    this.siteHazardInfo.other_control_1 = null;
    this.siteHazardInfo.other_control_2 = null;
    this.siteHazardInfo.other_control_3 = null;
    this.siteHazardInfo.other_control_4 = null;
    this.siteHazardInfo.prework_yn = null;
    this.siteHazardInfo.accident_yn = null;
    this.siteHazardInfo.equipment = null;
    this.siteHazardInfo.task = null;
    this.siteHazardInfo.task_other = null;
    this.siteHazardInfo.Questionnaire = '1,1,1,1,1,1';
    this.siteHazardInfo.status = null;
    this.siteHazardInfo.custom_staff_name = null;

    if (this.siteHazardInfo.id) {
      let data: any = await this.crudService.Update(this.siteHazardInfo, 'JobSiteHazard').toPromise()
      if (data.success == '1') {
        let result = await this.saveSignatures(this.siteHazardInfo.id);
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Site Hazard Details Saved', life: 1000 });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Site Hazard Details Not Saved', life: 1000 });
      }
    } else {
      this.siteHazardInfo.process_id = this.companyId;
      this.siteHazardInfo.job_id = this.jobId;
      this.siteHazardInfo.job_alloc_id = this.jobAllocationId;
      let data: any = await this.crudService.Create(this.siteHazardInfo, 'JobSiteHazard').toPromise();
      if (data.success == '1') {
        this.siteHazardInfo.id = data.id;
        let result = await this.saveSignatures(this.siteHazardInfo.id);
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Site Hazard Details Saved', life: 1000 });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Site Hazard Details Not Saved', life: 1000 });
      }
    }

  }

  async saveSignatures(shfID): Promise<any> {
    this.signatories.forEach(async suser => {
      if (suser.modified) {
        if (!suser.id) {
          const signatureObj = {
            id: null,
            site_hazard_id: shfID,
            user_id: suser.user_id,
            signature: suser.savedSignUrl,
            custom_staff_name: suser.full_name ? suser.full_name : null,
            signaturepath: this.jobId + '/' + this.jobAllocationId + '/' + 'signature' + suser.user_id + '.jpeg'
          }
          const res = this.crudService.uploadImage(signatureObj.signature, this.jobId, this.jobAllocationId, 'signature' + suser.user_id + '.jpeg', 'LOCAL');
          const response = JSON.parse(res);
          if (response.status == '1') {
            signatureObj.signature = signatureObj.signaturepath + '.PNG';
            let data: any = await this.crudService.Create(signatureObj, 'JobSiteHazardSignature').toPromise();
            if (data.success == '1') {
              signatureObj.id = data.id
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          const signatureObj = {
            id: suser.id,
            site_hazard_id: shfID,
            user_id: suser.user_id,
            signature: suser.savedSignUrl,
            custom_staff_name: suser.full_name ? suser.full_name : null,
            signaturepath: this.jobId + '/' + this.jobAllocationId + '/' + 'signature' + suser.user_id + '.jpeg'
            //  testURL:(suser.signature.dataUrl).toDataURL("image/jpeg")
          }
          // suser.signature.dataUrl
          const res = this.crudService.uploadImage(signatureObj.signature, this.jobId, this.jobAllocationId, 'signature' + suser.user_id + '.jpeg', 'LOCAL');
          const response = JSON.parse(res);
          if (response.status == '1') {
            signatureObj.signature = signatureObj.signaturepath + '.PNG';
            let data: any = await this.crudService.Update(signatureObj, 'JobSiteHazardSignature').toPromise();
            if (data.success == '1') {
              signatureObj.id = data.id;
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }
      }
    });
  }



  onChangeSelectedUsers() {
    this.getSignaturesList();
  }


  clearSign() {

  }

  submitSign() {

  }

  showMarkerArea(img, imageDtls) {
    //console.log(img);
    const markerArea = new markerjs2.MarkerArea(img);
    //console.log(markerArea);
    markerArea.settings.displayMode = 'popup';
    markerArea.availableMarkerTypes = [markerjs2.FreehandMarker];
    markerArea.addRenderEventListener((imgURL) => {
      imageDtls.signURL = imgURL
      this.drawComplete(imageDtls, imgURL);
    });
    //console.log(markerArea.getState().markers);
    markerArea.show();
  }

  drawComplete(userDetails, imgURL) {
    // will be notified of szimek/signature_pad's onEnd event
    userDetails.signURL = imgURL;
    userDetails.savedSignUrl = imgURL;
    //    this.signaturePad.clear();
    userDetails.modified = true;
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    //console.log('begin drawing');
  }

  async setnewReportData() {
    let data: any = await this.crudService.getSHFReportData(this.jobAllocationId).toPromise();
    //console.log("set new report -- " + data);
    this.transformCriteriaValues();
    //this.transformRiskValues();      
    this.transformControlValues();

    if (data.length > 0) {
      this.reportData = {
        TEMPLATE_ID: data[0].TEMPLATE_ID, JobNo: data[0].JobNo, JobRefNo: data[0].JobRefNo,
        InspectionDate: data[0].InspectionDate, CompletionDate: data[0].CompletionDate, ClientName: data[0].ClientName,
        ContactNo: 'Test', CustomerDetails: data[0].CustomerDetails, Datetime: '18/12/2020 11AM', SiteAddress: data[0].SiteAddress,
        WorkOrder: data[0].WorkOrderDetails, Feedback: data[0].EnviroAuditDetails,
        ImageURL: 'C:/companyCompliances/' + data[0].WPCompanyId + '/company_logo.png',
        SignExists: null, SIGN: data[0].SignaturePath, SWMSCompiledBy: data[0].SWMSCompiledBy, TPName: data[0].TPName,
        criteria_1: this.selectedCriteria_1_desc, criteria_2: this.selectedCriteria_2_desc, criteria_3: this.selectedCriteria_3_desc, criteria_4: this.selectedCriteria_4_desc,
        risk_1: this.selectedRisk_1.value, risk_2: this.selectedRisk_2.value, risk_3: this.selectedRisk_3.value, risk_4: this.selectedRisk_4.value,
        control_1: this.selectedControls_1_desc, control_2: this.selectedControls_2_desc, control_3: this.selectedControls_3_desc, control_4: this.selectedControls_4_desc
      };
    };
  }

  transformCriteriaValues() {
    if (this.selectedCriteria_1 != null) {
      let desc = '';
      for (const Criteria_1 of this.selectedCriteria_1) {
        if (desc !== null && desc !== '') { desc = desc + ', ' + Criteria_1.value; }
        else { desc = Criteria_1.value; }
      } this.selectedCriteria_1_desc = desc;
    }
    if (this.selectedCriteria_2 != null) {
      let desc = '';
      for (const Criteria_2 of this.selectedCriteria_2) {
        if (desc !== null && desc !== '') { desc = desc + ', ' + Criteria_2.value; }
        else { desc = Criteria_2.value; }
      } this.selectedCriteria_2_desc = desc;
    }
    if (this.selectedCriteria_3 != null) {
      let desc = '';
      for (const Criteria_3 of this.selectedCriteria_3) {
        if (desc !== null && desc !== '') { desc = desc + ', ' + Criteria_3.value; }
        else { desc = Criteria_3.value; }
      } this.selectedCriteria_3_desc = desc;
    } if (this.selectedCriteria_4 != null) {
      let desc = '';
      for (const Criteria_4 of this.selectedCriteria_4) {
        if (desc !== null && desc !== '') { desc = desc + ', ' + Criteria_4.value; }
        else { desc = Criteria_4.value; }
      }
      this.selectedCriteria_4_desc = desc;
    }
    return "";
  }

  transformRiskValues() {
    /*   if (this.selectedRisk_1!= null) {
         let desc = '';
         for(const Risk_1 of this.selectedRisk_1 ){ 
             if (desc !== null && desc !== '') { desc = desc + ', ' + Risk_1.value; } 
             else { desc = Risk_1.value;}  
         }                   this.selectedRisk_1_desc=desc;      
       }
        if (this.selectedRisk_2!= null) {
         let desc = '';
         for(const Risk_2 of this.selectedRisk_2 ){ 
             if (desc !== null && desc !== '') { desc = desc + ', ' + Risk_2.value; } 
             else { desc = Risk_2.value;}  
         }                   this.selectedRisk_2_desc=desc;      
       }
       if (this.selectedRisk_3!= null) {
         let desc = '';
         for(const Risk_3 of this.selectedRisk_3 ){ 
             if (desc !== null && desc !== '') { desc = desc + ', ' + Risk_3.value; } 
             else { desc = Risk_3.value;}  
         }                   this.selectedRisk_3_desc=desc;      
       }if (this.selectedRisk_1!= null) {
         let desc = '';
         for(const Risk_4 of this.selectedRisk_4 ){ 
             if (desc !== null && desc !== '') { desc = desc + ', ' + Risk_4.value; } 
             else { desc = Risk_4.value;}  
         }                   this.selectedRisk_4_desc=desc;      
       }
       return "";  */
  }

  transformControlValues() {
    if (this.selectedControls_1 != null) {
      let desc = '';
      for (const Control_1 of this.selectedControls_1) {
        if (desc !== null && desc !== '') { desc = desc + ', ' + Control_1.value; }
        else { desc = Control_1.value; }
      } this.selectedControls_1_desc = desc;
    }
    if (this.selectedControls_2 != null) {
      let desc = '';
      for (const Control_2 of this.selectedControls_2) {
        if (desc !== null && desc !== '') { desc = desc + ', ' + Control_2.value; }
        else { desc = Control_2.value; }
      } this.selectedControls_2_desc = desc;
    }
    if (this.selectedControls_3 != null) {
      let desc = '';
      for (const Control_3 of this.selectedControls_3) {
        if (desc !== null && desc !== '') { desc = desc + ', ' + Control_3.value; }
        else { desc = Control_3.value; }
      } this.selectedControls_3_desc = desc;
    }
    if (this.selectedControls_4 != null) {
      let desc = '';
      for (const Control_4 of this.selectedControls_4) {
        if (desc !== null && desc !== '') { desc = desc + ', ' + Control_4.value; }
        else { desc = Control_4.value; }
      } this.selectedControls_4_desc = desc;
    }

    return "";
  }

  generatePdfOld() {
    let reportId = 'SHF';
    let date = new Date();
    let tempTime = date.getTime();
    let data = this.setData(reportId);
    data[0].newReportData = this.reportData;
    data[0].imageList = this.imageList;
    this.crudService.generateNewSHFReport(this.jobId, this.jobAllocationId, data, reportId, tempTime, 1).subscribe((res: any) => {
      //console.log('generate job kpi pdf ');
      //console.log(res);
      let response: any = new Uint8Array(res.data)
      /* this.pdfUrl = response;
      return response; */
      this.pdfUrl = this.crudService.getAPIRoot() + '/' + this.jobId + '/' + this.jobAllocationId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf';

      this.deleteDoc();
      this.addUploadDoc(tempTime, reportId);
      this.pdfGenerated = true;
    });
  }

  async generatePdf() {
    this.loading = true;
    let reportId = 'SHF';
    let date = new Date();
    let tempTime = date.getTime();
    let fileDesc = '10035';
    let data = await this.JobService.setSHFData(this.jobId, this.jobAllocationId, reportId, this.jobAllocationDetails)
    let res = await this.crudService.generateNewSHFReport(this.jobId, this.jobAllocationId, data, reportId, tempTime, 1).toPromise();
    const fileName = reportId + '_' + this.jobAllocationDetails.JobNo + '.pdf';
    const filePath = this.jobId + '/' + this.jobAllocationId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf'
    let blob = new Blob([res], { type: "application/pdf" });
    const objectUrl = URL.createObjectURL(blob);
    window.open(objectUrl);
    this.JobService.deleteDoc(this.jobAllocationId, fileName);
    this.JobService.addUploadDocSHF(this.jobId, this.jobAllocationId, this.userDetails.id, fileName, filePath, fileDesc, reportId, tempTime, this.jobAllocationDetails.info.WPCompanyId);
    this.loading = false;

    /* let data = await this.setData(reportId);
    await this.setnewReportData();
    await this.getImageListforReport();
    data[0].newReportData = this.reportData;
    data[0].imageList = this.imageList;
    //console.log('reportData : ', data); 
    //import('file-saver').then(FileSaver => {
    this.crudService.generateNewSHFReport(this.jobId, this.jobAllocationId, data, reportId, tempTime, 1).subscribe((res: any) => {
      this.confirmationService.confirm({
        message: 'Do you want to view the report in separate Window?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Yes, Open in new Tab',
        rejectLabel: 'No, Download the File',
        accept: () => {
          let blob = new Blob([res], { type: "application/pdf" });
          //this.pdfUrl = this.crudService.getAPIRoot() + '/' + this.jobId + '/' + this.jobAllocationId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf';
          const objectUrl = URL.createObjectURL(blob);
          window.open(objectUrl);
          this.deleteDoc();
          this.addUploadDoc(tempTime, reportId);
          this.loading = false;
        },
        reject: () => {
          import('file-saver').then(FileSaver => {
            let blob = new Blob([res], { type: "application/pdf" });
            const a = document.createElement('a')
            const objectUrl = URL.createObjectURL(blob)
            a.href = objectUrl
            a.download = 'SHF Report (' + this.jobAllocationId + ')';
            a.click();
            URL.revokeObjectURL(objectUrl);
            this.deleteDoc();
            this.addUploadDoc(tempTime, reportId);
            this.loading = false;
          })
        }
      })
    })
    //})*/
  }

  async setData(reportId): Promise<any> {
    let item = [];
    let k = 0;
    let scores = [];
    let data: any;

    /* for (let z = 0; z < this.shf[0].risks.length; z++) {
      let obj = {
        id: z + 1,
        hazard: this.shf[0].risks[z].criteria,
        rating: this.shf[0].risks[z].risk_rating,
        control: this.shf[0].risks[z].control,
      }
      item.push(obj);
    } */

    let criteria_1_UI: any[];
    if (this.selectedCriteria_1.length > 0) {
      const users = this.selectedCriteria_1.map(function (item) {
        return item.value;
      });
      criteria_1_UI = users;
    } else {
      criteria_1_UI = null;
    }

    let criteria_2_UI: any[];
    if (this.selectedCriteria_2.length > 0) {
      const users = this.selectedCriteria_2.map(function (item) {
        return item.value;
      });
      criteria_2_UI = users;
    } else {
      criteria_2_UI = null;
    }

    let criteria_3_UI: any[];
    if (this.selectedCriteria_3.length > 0) {
      const users = this.selectedCriteria_3.map(function (item) {
        return item.value;
      });
      criteria_3_UI = users;
    } else {
      criteria_3_UI = null;
    }

    let criteria_4_UI: any[];
    if (this.selectedCriteria_4.length > 0) {
      const users = this.selectedCriteria_4.map(function (item) {
        return item.value;
      });
      criteria_4_UI = users;
    } else {
      criteria_4_UI = null;
    }

    let control_1_UI: any[];
    if (this.selectedControls_1.length > 0) {
      const users = this.selectedControls_1.map(function (item) {
        return item.value;
      });
      control_1_UI = users;
    } else {
      control_1_UI = null;
    }

    let control_2_UI: any[];
    if (this.selectedControls_2.length > 0) {
      const users = this.selectedControls_2.map(function (item) {
        return item.value;
      });
      control_2_UI = users;
    } else {
      control_2_UI = null;
    }

    let control_3_UI: any[];
    if (this.selectedControls_3.length > 0) {
      const users = this.selectedControls_3.map(function (item) {
        return item.value;
      });
      control_3_UI = users;
    } else {
      control_3_UI = null;
    }

    let control_4_UI: any[];
    if (this.selectedControls_4.length > 0) {
      const users = this.selectedControls_4.map(function (item) {
        return item.value;
      });
      control_4_UI = users;
    } else {
      control_4_UI = null;
    }

    let rating_1_UI: any;
    let rating_2_UI: any;
    let rating_3_UI: any;
    let rating_4_UI: any;


    if (this.siteHazardInfo.risk_rating_1) {
      rating_1_UI = this.risk_1_options.filter(val => this.siteHazardInfo.risk_rating_1 === val.id)[0];
      item.push({ id: 1, hazard: criteria_1_UI, rating: rating_1_UI.value, control: control_1_UI });
    } else {
      item.push({ id: 1, hazard: criteria_1_UI, rating: '', control: control_1_UI });
    }
    if (this.siteHazardInfo.risk_rating_2) {
      rating_2_UI = this.risk_2_options.filter(val => this.siteHazardInfo.risk_rating_2 === val.id)[0];
      item.push({ id: 1, hazard: criteria_1_UI, rating: rating_2_UI.value, control: control_1_UI });
    } else {
      item.push({ id: 1, hazard: criteria_1_UI, rating: '', control: control_1_UI });
    }
    if (this.siteHazardInfo.risk_rating_3) {
      rating_3_UI = this.risk_3_options.filter(val => this.siteHazardInfo.risk_rating_3 === val.id)[0];
      item.push({ id: 1, hazard: criteria_1_UI, rating: rating_3_UI.value, control: control_1_UI });
    } else {
      item.push({ id: 1, hazard: criteria_1_UI, rating: '', control: control_1_UI });
    }
    if (this.siteHazardInfo.risk_rating_4) {
      rating_4_UI = this.risk_4_options.filter(val => this.siteHazardInfo.risk_rating_4 === val.id)[0];
      item.push({ id: 1, hazard: criteria_1_UI, rating: rating_4_UI.value, control: control_1_UI });
    } else {
      item.push({ id: 1, hazard: criteria_1_UI, rating: '', control: control_1_UI });
    }

    let preworkValue = null;
    let accidentValue = null;
    if (this.prework_yn == '1') {
      preworkValue = 'Yes';
    } else {
      preworkValue = 'No';
    }
    if (this.accidents_yn == '1') {
      accidentValue = 'No';
    } else {
      accidentValue = 'Yes';
    }

    data = [
      {
        info: {
          id: '67993bdd-f7d9-48a6-93d9-8026b657041a',
          jobid: 1,
          reportid: reportId,
          accident: accidentValue,
          prework: preworkValue

        },
        company: {
          job_num: this.jobAllocationDetails.JobNo,
          contractor: 'TEST COMPANY - Enviro Tree Service'
        },
        items: [
          {
            id: 1,
            detail: 'Site Hazard Conditions Evaluation',
            detail2: 'Site Safety Conditions Evaluation',
            item: item
          }
        ],
      }
    ];
    return data;
  }

  addUploadDoc(tempTime, reportId) {
    //add it to doc list
    let docReadyUpload = {
      id: null,
      job_id: parseInt(this.jobId),
      job_alloc_id: parseInt(this.jobAllocationId),
      company_id: 1,
      upload_type: 0,
      process_id: 1,
      inc_quote: 2,
      upload_at: new Date(),
      file_size: 33000,
      hide: 2,
      upload_by: parseInt(this.userDetails.id),
      file_name: "SHF_" + this.jobAllocationDetails.JobNo + ".pdf",
      file_description: 10035,
      upload_type_detail: 0,
      file_path: this.jobId + '/' + this.jobAllocationId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf',
      status: 1,
      s3_url: null,
      sync_status: 0
    }
    this.crudService.Create(docReadyUpload, 'uploaddocuments').subscribe((data: any) => {
      if (data.success === 1) {
      }
    });
  }

  deleteDoc() {
    let fileName = "SHF_" + this.jobAllocationDetails.JobNo + ".pdf"
    this.crudService.deleteUploadPdf(this.jobAllocationId, fileName).subscribe((data: any) => {
      if (data.success === 1) {
      }
    });
  }

}
