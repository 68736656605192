import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { CRUDService } from './crud.service';
import { WorkFlowService } from './workflow.service';
import { environment } from 'src/environments/environment';
import { delay } from 'rxjs/operators';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';

@Injectable()
export class JOBService {
    private JobSource = new Subject<string>();
    private resetSource = new Subject();
    JobSource$ = this.JobSource.asObservable();
    resetSource$ = this.resetSource.asObservable();

    dateNow = new Date();
    milliSecondsInASecond = 1000;
    hoursInADay = 24;
    minutesInAnHour = 60;
    SecondsInAMinute = 60;

    constructor(
        private crudService: CRUDService,
        private wfService: WorkFlowService,private datePipe: DatePipe
    ) {
    }

    async getJobAllocationResult(jobId, jobAllocationId): Promise<any> {
        //console.log('joballocation');
        let jobAllocationResult;
        const smryData: any = await this.crudService.getJobSummary(jobId, jobAllocationId).toPromise();
        const data = await this.getJobInfo(smryData);
        jobAllocationResult = data;
        return jobAllocationResult;
    }

    async getJobInfo(jobSummary): Promise<any> {
        const JobAllocData: any = await this.crudService.getDataByField('jobAllocation', 'id', jobSummary.AllocationID).toPromise();
        jobSummary.JobAllocDetails = JobAllocData[0];
        let client_type_id = '1';
        const userDetails: any = sessionStorage.getItem('loggedInUser');
        let editJobAllowed: boolean;
        const jobAllocationResults = jobSummary;

        if (client_type_id === '4') {
            jobAllocationResults.adviseTP = '2';
        } else {
            jobAllocationResults.adviseTP = '1';
        }
        if (jobSummary.job_type === '2') {
            jobAllocationResults.JobPrefix = 'INT';
        } else {
            jobAllocationResults.JobPrefix = 'TM';
        }
        jobAllocationResults.location1 = jobSummary.Address ? jobSummary.Address : 'N/A';
        if (environment.hosting == 'UK') {
            jobAllocationResults.accounttype1 = false;
        } else {
            jobAllocationResults.accounttype1 = jobAllocationResults.location1.includes('New Zealand');
        }
        if (jobAllocationResults.accounttype1) {
            jobAllocationResults.EmailAccount1 = 'NZ';
        } else {
            jobAllocationResults.EmailAccount1 = 'AU';
        }
        if (jobSummary.ClientCommType === 10132) {
            jobAllocationResults.ClientCommType = 'Email Client';
        } else if (jobSummary.ClientCommType === 10133) {
            jobAllocationResults.ClientCommType = 'Portal Client';
        } else if (jobSummary.ClientCommType === 10142) {
            jobAllocationResults.ClientCommType = 'Documents in Portal and Comms in Email';
        }
        debugger
        jobAllocationResults.JobNo = jobAllocationResults.JobPrefix + ' ' + jobSummary.job_no;
        jobAllocationResults.info = {
            date: jobSummary.Date,
            AddressForComms: jobSummary.AddressForComms,
            location: jobSummary.Address,
            professional: jobSummary.TreeProfessional + ', ' + jobSummary.TreeProfessionalContact + ', ' + jobSummary.TreeProfessionalEmail,
            client: jobSummary.Client,
            TreeCondition: jobSummary.TreeCondition,
            ClientCommType: jobAllocationResults.ClientCommType,
            ClientName: jobSummary.ClientName,
            Email: jobSummary.Email ? jobSummary.Email : 'NA',
            contact: jobSummary.Contact,
            SiteContactName: jobSummary.SiteContactName,
            address: '13 Churchill Crescent, NSW, Australia',
            details: jobSummary.Details,
            sp_details: jobSummary.sp_details ? jobSummary.sp_details : 'None',
            abn: jobSummary.business_ABN,
            job_claim_no: jobSummary.job_claim_no,
            RefNo: jobSummary.RefNo,
            ClientContact: jobSummary.ClientContact,
            WP_ABN: jobSummary.WP_ABN,
            TPName: jobSummary.TreeProfessional,
            WPCompanyId: jobSummary.WPCompanyId,
            JobReqId: jobSummary.JobReqId,
            JobReqName: jobSummary.JobReqName,
            JobPriorityName: jobSummary.JobPriorityName,
            TPId: jobSummary.Contractor,
            TPEmail: jobSummary.TreeProfessionalEmail,
            TPContactName: jobSummary.TPContactName,
            TPMobile: jobSummary.TreeProfessionalMobile,
            SiteContactEmail: jobSummary.SiteContactEmail,
            SiteContactMobile: jobSummary.SiteContactMobile,
            client_rebate: jobSummary.Client_rebate ? jobSummary.Client_rebate : 0,
            AccountType: jobAllocationResults.EmailAccount1,
            WPType: jobSummary.WPType,
            job_type: jobSummary.job_type,
        };
        jobAllocationResults.invoice = {
            percent: '61'
        }
        jobAllocationResults.email = jobAllocationResults.info.TPEmail;
        jobAllocationResults.mobile = jobAllocationResults.info.TPMobile;
        jobAllocationResults.location = jobAllocationResults.info.location;
        jobAllocationResults.client = jobAllocationResults.info.contact;
        if ((jobAllocationResults.JobStatus == 'Invoice Processed') || (jobAllocationResults.JobStatus == 'Accounts Processed') || (jobAllocationResults.JobStatus == 'Job Completed')) {
            if (!((userDetails.id === '1') || (userDetails.id === '62') || (userDetails.id === '68') || (userDetails.id === '608'))) {
                editJobAllowed = false;
            }
        } else {
            editJobAllowed = true;
        }
        if (environment.hosting == 'UK') {
            jobAllocationResults.accounttype = false;
        } else {
            jobAllocationResults.accounttype = jobAllocationResults?.location?.includes('New Zealand');
        }
        if (jobAllocationResults.accounttype) {
            jobAllocationResults.EmailAccount = 'NZ';
        } else {
            jobAllocationResults.EmailAccount = 'AU';
        }
        if (jobAllocationResults.EmailAccount === 'AU') {
            jobAllocationResults.invReplyTo = 'accounts@envirofrontier.com.au';
            jobAllocationResults.QuotReplyTo = 'makesafe@envirofrontier.com.au';
            jobAllocationResults.EnvWebsite = 'www.envirofrontier.com.au';
            jobAllocationResults.EnvAddressLine1 = 'P.O. Box 617';
            jobAllocationResults.EnvAddressLine2 = 'Narrabeen';
            jobAllocationResults.EnvState = 'NSW';
            jobAllocationResults.EnvCountry = 'Australia';
            jobAllocationResults.EnvPhone = '1300 077 233';
            jobAllocationResults.EnvFax = '1300 077 446';
            jobAllocationResults.EnvABNName = 'ABN';
            jobAllocationResults.EnvZip = '2106';
        } else if (jobAllocationResults.EmailAccount === 'NZ') {
            jobAllocationResults.invReplyTo = 'accounts@envirofrontier.co.nz';
            jobAllocationResults.QuotReplyTo = 'makesafe@envirofrontier.co.nz';
            jobAllocationResults.EnvWebsite = 'www.envirofrontier.co.nz';
            jobAllocationResults.EnvAddressLine1 = '68 Russell Street';
            jobAllocationResults.EnvAddressLine2 = 'Westport';
            jobAllocationResults.EnvState = 'NZ3';
            jobAllocationResults.EnvCountry = 'New Zealand';
            jobAllocationResults.EnvPhone = '+64 800004827';
            jobAllocationResults.EnvFax = '+64 800004827';
            jobAllocationResults.EnvABNName = 'NZBN';
            jobAllocationResults.EnvZip = '7825';
        }
        const pdfInfo: any = {};
        const result: any = await this.crudService.getBankDetail(jobSummary.AllocationID).toPromise();
        if (result.length > 0) {
            pdfInfo.bankDetail = result[0];
        }
        const res2: any = await this.crudService.getSiteInfo(jobSummary.id).toPromise()
        if (res2.length > 0) {
            pdfInfo.siteInfo = res2[0];
        }
        pdfInfo.siteInfo.address = jobAllocationResults.info.location;
        let id = 462;
        if (jobAllocationResults.info.AccountType === 'AU') {
            id = 108;
        }
        const res3: any = await this.crudService.getEnviroBankDetail(108).toPromise();
        if (res3.length > 0) {
            pdfInfo.enviroBankDetail = res3[0];
        }
        jobAllocationResults.pdfInfo = pdfInfo;
        return jobAllocationResults;
    }

    async getJobInfo_dup(jobSummary): Promise<any> {
        const JobAllocData: any = await this.crudService.getDataByField('jobAllocation', 'id', jobSummary.AllocationID).toPromise();
        jobSummary.JobAllocDetails = JobAllocData[0];
        let client_type_id = '1';
        let editJobAllowed: boolean;
        const jobAllocationResults = jobSummary;

        if (client_type_id === '4') {
            jobAllocationResults.adviseTP = '2';
        } else {
            jobAllocationResults.adviseTP = '1';
        }
        if (jobSummary.job_type === '2') {
            jobAllocationResults.JobPrefix = 'INT';
        } else {
            jobAllocationResults.JobPrefix = 'TM';
        }
        jobAllocationResults.location1 = jobSummary.Address ? jobSummary.Address : 'N/A';
        if (environment.hosting == 'UK') {
            jobAllocationResults.accounttype1 = false;
        } else {
            jobAllocationResults.accounttype1 = jobAllocationResults.location1.includes('New Zealand');
        }
        if (jobAllocationResults.accounttype1) {
            jobAllocationResults.EmailAccount1 = 'NZ';
        } else {
            jobAllocationResults.EmailAccount1 = 'AU';
        }
        if (jobSummary.ClientCommType === 10132) {
            jobAllocationResults.ClientCommType = 'Email Client';
        } else if (jobSummary.ClientCommType === 10133) {
            jobAllocationResults.ClientCommType = 'Portal Client';
        } else if (jobSummary.ClientCommType === 10142) {
            jobAllocationResults.ClientCommType = 'Documents in Portal and Comms in Email';
        }

        jobAllocationResults.JobNo = jobAllocationResults.JobPrefix + ' ' + jobSummary.job_no;
        jobAllocationResults.info = {
            date: jobSummary.Date,
            AddressForComms: jobSummary.AddressForComms,
            location: jobSummary.Address,
            professional: jobSummary.TreeProfessional + ', ' + jobSummary.TreeProfessionalContact + ', ' + jobSummary.TreeProfessionalEmail,
            client: jobSummary.Client,
            TreeCondition: jobSummary.TreeCondition,
            ClientCommType: jobAllocationResults.ClientCommType,
            ClientName: jobSummary.ClientName,
            Email: jobSummary.Email ? jobSummary.Email : 'NA',
            contact: jobSummary.Contact,
            SiteContactName: jobSummary.SiteContactName,
            address: '13 Churchill Crescent, NSW, Australia',
            details: jobSummary.Details,
            sp_details: jobSummary.sp_details ? jobSummary.sp_details : 'None',
            abn: jobSummary.business_ABN,
            job_claim_no: jobSummary.job_claim_no,
            RefNo: jobSummary.RefNo,
            ClientContact: jobSummary.ClientContact,
            WP_ABN: jobSummary.WP_ABN,
            TPName: jobSummary.TreeProfessional,
            WPCompanyId: jobSummary.WPCompanyId,
            JobReqId: jobSummary.JobReqId,
            JobReqName: jobSummary.JobReqName,
            JobPriorityName: jobSummary.JobPriorityName,
            TPId: jobSummary.Contractor,
            TPEmail: jobSummary.TreeProfessionalEmail,
            TPContactName: jobSummary.TPContactName,
            TPMobile: jobSummary.TreeProfessionalMobile,
            SiteContactEmail: jobSummary.SiteContactEmail,
            SiteContactMobile: jobSummary.SiteContactMobile,
            client_rebate: jobSummary.Client_rebate ? jobSummary.Client_rebate : 0,
            AccountType: jobAllocationResults.EmailAccount1,
            WPType: jobSummary.WPType,
            job_type: jobSummary.job_type,
        };
        jobAllocationResults.invoice = {
            percent: '61'
        }
        jobAllocationResults.email = jobAllocationResults.info.TPEmail;
        jobAllocationResults.mobile = jobAllocationResults.info.TPMobile;
        jobAllocationResults.location = jobAllocationResults.info.location;
        jobAllocationResults.client = jobAllocationResults.info.contact;
        if ((jobAllocationResults.JobStatus == 'Invoice Processed') || (jobAllocationResults.JobStatus == 'Accounts Processed') || (jobAllocationResults.JobStatus == 'Job Completed')) {
            // if (!((userDetails.id === '1') || (userDetails.id === '62') || (userDetails.id === '68') || (userDetails.id === '608'))) {
            //     editJobAllowed = false;
            // }
        } else {
            editJobAllowed = true;
        }
        if (environment.hosting == 'UK') {
            jobAllocationResults.accounttype = false;
        } else {
            jobAllocationResults.accounttype = jobAllocationResults?.location?.includes('New Zealand');
        }
        if (jobAllocationResults.accounttype) {
            jobAllocationResults.EmailAccount = 'NZ';
        } else {
            jobAllocationResults.EmailAccount = 'AU';
        }
        if (jobAllocationResults.EmailAccount === 'AU') {
            jobAllocationResults.invReplyTo = 'accounts@envirofrontier.com.au';
            jobAllocationResults.QuotReplyTo = 'makesafe@envirofrontier.com.au';
            jobAllocationResults.EnvWebsite = 'www.envirofrontier.com.au';
            jobAllocationResults.EnvAddressLine1 = 'P.O. Box 617';
            jobAllocationResults.EnvAddressLine2 = 'Narrabeen';
            jobAllocationResults.EnvState = 'NSW';
            jobAllocationResults.EnvCountry = 'Australia';
            jobAllocationResults.EnvPhone = '1300 077 233';
            jobAllocationResults.EnvFax = '1300 077 446';
            jobAllocationResults.EnvABNName = 'ABN';
            jobAllocationResults.EnvZip = '2106';
        } else if (jobAllocationResults.EmailAccount === 'NZ') {
            jobAllocationResults.invReplyTo = 'accounts@envirofrontier.co.nz';
            jobAllocationResults.QuotReplyTo = 'makesafe@envirofrontier.co.nz';
            jobAllocationResults.EnvWebsite = 'www.envirofrontier.co.nz';
            jobAllocationResults.EnvAddressLine1 = '68 Russell Street';
            jobAllocationResults.EnvAddressLine2 = 'Westport';
            jobAllocationResults.EnvState = 'NZ3';
            jobAllocationResults.EnvCountry = 'New Zealand';
            jobAllocationResults.EnvPhone = '+64 800004827';
            jobAllocationResults.EnvFax = '+64 800004827';
            jobAllocationResults.EnvABNName = 'NZBN';
            jobAllocationResults.EnvZip = '7825';
        }
        const pdfInfo: any = {};
        jobAllocationResults.pdfInfo = pdfInfo;
        return jobAllocationResults;
    }

    contains(r, s) {
        return r.indexOf(s) > -1;
    }

    async generateReport(data, reportId, jobId, allocId, tempTime): Promise<any> {
        this.crudService.generateReport(data, reportId, jobId, allocId, tempTime, null);
        const apiRoot = this.crudService.getAPIRoot();
        const url = apiRoot + '/' + jobId + '/' + allocId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf';
        return url;
    }

    async setData(reportId, imgs, jobAllocationResults, costing): Promise<any[]> {
        let data: any = null;
        switch (reportId) {
            case 4:
                // get the value from list
                let pic_items = [];
                for (let i = 0; i < imgs.length; i++) {
                    const item = {
                        image: imgs[i].imgType,
                        comments: imgs[i].imgDes,
                        src: imgs[i].imgPath,
                        s3Url: imgs[i]?.s3Url
                    }
                    pic_items.push(item);
                }
                //put the value into data to generate pdf
                data = [{
                    info: {
                        id: '67993bdd-f7d9-48a6-93d9-8026b657041a',
                        jobid: 1,
                        reportid: reportId,
                        job_num: jobAllocationResults.job_claim_no,
                        address: jobAllocationResults.info.location,
                        desc: jobAllocationResults.info.details
                    },
                    items: pic_items
                }

                ];
                break;

            default:
                data = [{
                    info: {
                        id: '67993bdd-f7d9-48a6-93d9-8026b657041a',
                        jobid: 1,
                        reportid: reportId,
                    },
                    company: {
                        name: 'Awesome COMPANY - Deepak Kurmi',
                        cost_form_no: '51981',
                        country: 'Australia',
                        add1: '2-10 Mooramba road',
                        add2: 'dee why',
                        city: 'NSW',
                        zip: '2099',
                        fax: '02 98765432',
                        phone: '0298765432',
                        email: 'rajendra.kalaunee@neolinx.com.np',
                    },
                    report: {
                        date: jobAllocationResults.cost_date,
                        abn: '2153',
                        bsb: '789',
                        account: '123',
                    },
                    job: {
                        no: '00125102',
                        desc: 'Test Job',
                        due_date: '04-16-2012',
                        payment_term: '1 year',
                    },
                    customer: {
                        name: 'Enviro Frontier Project Management',
                        phone: '1300077233',
                        fax: '1300077446',
                        billing: '<p>PO Box 617</p><p>Narrabeen</p><p>NSW, 2101</p>',
                        job_site: '<p>2-10 Mooramba road</p><p>Dee Why</p><p>NSW, 2099</p>',
                    },
                    items: costing.mainItems,
                    total: {
                        taxrate: costing.taxrate,
                        sub_total: costing.subtotal,
                        tax: costing.tax,
                        all_total: costing.grandtotal,
                    },
                    notes: costing.cost_job_magenager_notes
                }];
        }
        return data;
    }



    async setSCAData(jobId, allocId, reportId, jobSummary): Promise<any[]> {
        let subContractorAgreement: any;
        const agreements: any = await this.crudService.getLoadedAgreement(jobId, allocId).toPromise();
        if (agreements.length > 0) {
            subContractorAgreement = agreements[0];
            subContractorAgreement.pay_claim_date = new Date(subContractorAgreement.pay_claim_date).toLocaleDateString();
            subContractorAgreement.applied_from = new Date(subContractorAgreement.applied_from).toLocaleDateString();
            subContractorAgreement.work_comp_date = new Date(subContractorAgreement.work_comp_date).toLocaleDateString();
            subContractorAgreement.applied_to = new Date(subContractorAgreement.applied_to).toLocaleDateString();
        }
        const data: any = [
            {
                newReportData: {},
                info: {
                    id: '67993bdd-f7d9-48a6-93d9-8026b657041a',
                    jobid: 1,
                    reportid: reportId,
                },
                contractor: {
                    name: subContractorAgreement?.bus_name,
                    abn: subContractorAgreement?.bus_abn,
                    address: subContractorAgreement?.address,
                },
                contracwith: {
                    name: jobSummary.info.client,
                    abn: jobSummary.info.WP_ABN,
                    contact: subContractorAgreement?.contract_number,
                },
                statementdate: {
                    from_date: subContractorAgreement?.applied_from,
                    from_month: '',
                    from_year: '',
                    to_date: subContractorAgreement?.applied_to,
                    to_month: '',
                    to_year: '',
                },
                claim_date: {
                    date: subContractorAgreement?.pay_claim_date,
                    month: '',
                    year: '',
                },
                person_info: {
                    name: subContractorAgreement?.full_name,
                    job_title: subContractorAgreement?.position,
                    insurance_date: {
                        insure_date: '30',
                        insure_month: '06',
                        insure_year: '2023',
                    },
                    signature_img: 'images/PeterDubiezSignature1.jpg',
                    signed_date: {
                        sign_date: subContractorAgreement?.pay_claim_date,
                        sign_month: '',
                        sign_year: '',
                    }
                }
            }
        ];
        return data;
    }


    async getIndvImageData(jobId, jobAllocationId, documentType): Promise<any[]> {
        documentType = documentType.toString();
        //item type : 1 = Quote, 2 = Invoice
        //console.log('getImgeData');
        let res: any;
        const apiRoot = this.crudService.getAPIRoot();
        res = await this.crudService.getUploadImgsByJobIdAllocId(jobId, jobAllocationId).toPromise();
        const imgs: any[] = [];
        res.forEach(imgData => {
            if (imgData.img_inc === '2') {
                if (!(imgData.img_type === '3')) {
                    if (documentType === '2') {
                        if (imgData.img_type === '1') {
                            const obj = {
                                imgPath: apiRoot + imgData.img_path,
                                imgName: imgData.img_name,
                                s3Url: imgData?.s3_url
                            }
                            imgs.push(obj);
                        }
                    }
                    if (documentType === '1') {
                        const obj = {
                            imgPath: apiRoot + imgData.img_path,
                            imgName: imgData.img_name,
                            s3Url: imgData?.s3_url
                        }
                        imgs.push(obj);
                    }
                }
            }
        });
        //console.log(imgs)
        return imgs;
    }

    async getImgeData(jobId, jobAllocationId, documentType): Promise<any[]> {
        documentType = documentType.toString();
        //item type : 1 = Quote, 2 = Invoice
        //console.log('getImgeData');
        let res: any;
        res = await this.crudService.getUploadImgsByJobIdAllocId(jobId, jobAllocationId).toPromise();
        //console.log(res);
        const imgs: any[] = [];
        res.forEach(imgData => {
            if (imgData.img_inc === '2') {
                if (!(imgData.img_type === '3')) {
                    if (documentType === '2') {
                        if (imgData.img_type === '1') {
                            const obj = {
                                imgPath: imgData.img_path,
                                imgName: imgData.img_name,
                                imgType: imgData.img_type === '1' ? 'Before Image' : 'After Image',
                                imgDes: imgData.img_description,
                                s3Url: imgData?.s3_url
                            }
                            imgs.push(obj);
                        }
                    }
                    if (documentType === '1') {
                        const obj = {
                            imgPath: imgData.img_path,
                            imgName: imgData.img_name,
                            imgType: imgData.img_type === '1' ? 'Before Image' : 'After Image',
                            imgDes: imgData.img_description,
                            s3Url: imgData?.s3_url
                        }
                        imgs.push(obj);
                    }
                }
            }
        });
        //console.log(imgs);
        return imgs
    }

    async getImgeData_dup(jobId, jobAllocationId, documentType): Promise<any[]> {
        documentType = documentType.toString();
        //item type : 1 = Quote, 2 = Invoice
        //console.log('getImgeData');
        let res: any;
        res = await this.crudService.getUploadImgsByJobIdAllocId_dup(jobId, jobAllocationId).toPromise();
        //console.log(res);
        const imgs: any[] = [];
        res.forEach(imgData => {
            if (imgData.img_inc === '2') {
                if (!(imgData.img_type === '3')) {
                    if (documentType === '2') {
                        if (imgData.img_type === '1') {
                            const obj = {
                                imgPath: imgData.img_path,
                                imgName: imgData.img_name,
                                imgType: imgData.img_type === '1' ? 'Before Image' : 'After Image',
                                imgDes: imgData.img_description
                            }
                            imgs.push(obj);
                        }
                    }
                    if (documentType === '1') {
                        const obj = {
                            imgPath: imgData.img_path,
                            imgName: imgData.img_name,
                            imgType: imgData.img_type === '1' ? 'Before Image' : 'After Image',
                            imgDes: imgData.img_description
                        }
                        imgs.push(obj);
                    }
                }
            }
        });
        //console.log(imgs);
        return imgs
    }

    async getInvoiceData(jobId, allocId, tempTime): Promise<any[]> {
        let data: any = {};
        data = await this.crudService.TPInvoiceReport(jobId, allocId, tempTime).toPromise();
        return data;
    }

    async getWPInvoiceData(jobId, allocId): Promise<any[]> {
        let data: any = {};
        const infoData = await this.crudService.getWPPdfBaseValues(allocId).toPromise();
        const itemsData = await this.crudService.getWPPdfCostValues(allocId).toPromise();
        infoData[0].reportid = '12_TMV3';
        data.info = infoData[0];
        data.items = itemsData;
        const treeInfo = await this.crudService.getWorkOrderInfo(jobId, allocId).toPromise();
        const treeData = this.transformTreeInfo(treeInfo);
        data.tree_info = treeData.tree_info;
        data.damages = treeData.damages;
        data = [data];
        data = JSON.stringify(data);
        return data;
    }

    transformTreeInfo(treeInfo) {
        //console.log(treeInfo);
        const treeData: any = {};
        let tree_info = [];
        let damages_info = [];
        if (treeInfo.length > 0) {
            const workOperationsInfo = treeInfo[0];
            tree_info.push({ label: 'No of Trees', desc: workOperationsInfo.no_tree });
            const diameters = [
                { id: '1', name: '0 to 400 mm' },
                { id: '2', name: '500 to 1000 mm' },
                { id: '3', name: 'Greater than 1000 mm' },
                { id: '4', name: 'N/A' },
            ];
            const heights = [
                { id: '1', name: '0-5 meter' },
                { id: '2', name: '6-10 meter' },
                { id: '3', name: '11-15 meter' },
                { id: '33', name: '16-20 meter' },
                { id: '4', name: 'Greater than 20 meter' },
                { id: '5', name: 'N/A' },
            ];
            const healths = [
                { id: '1', name: 'Good' },
                { id: '2', name: 'Average' },
                { id: '3', name: 'Poor' },
                { id: '4', name: 'N/A' },
            ];
            const treeAge = [
                { id: '1', name: 'Juvenile' },
                { id: '2', name: 'Semi-mature' },
                { id: '3', name: 'Mature' },
                { id: '4', name: 'N/A' },
            ]
            const locations = [
                { id: '1', name: 'Front of Property' },
                { id: '2', name: 'Side of Property' },
                { id: '3', name: 'Rear of Property' },
                { id: '4', name: 'Neighbours' },
                { id: '5', name: 'N/A' },
            ]
            const distances = [
                { id: '1', name: '0-5 meters' },
                { id: '2', name: '11-15 meters' },
                { id: '3', name: '15-20 meters' },
                { id: '4', name: 'Greater than 20 meters' },
                { id: '5', name: 'Neighbours' },
                { id: '6', name: 'N/A' },
            ]
            const access = [
                { id: '1', name: 'Good' },
                { id: '2', name: 'Average' },
                { id: '3', name: 'Poor' },
                { id: '4', name: 'N/A' },
            ]
            const workRequired = [
                { id: '1', name: 'Tree Removal' },
                { id: '2', name: 'Tree Prune' },
                { id: '3', name: 'Stump' },
                { id: '4', name: 'Temp tarp' },
                { id: '5', name: 'Temp fence' },
                { id: '6', name: 'Remove Debris' },
                { id: '7', name: 'N/A' },
            ]
            const cubicMeter = [
                { id: '1', name: '0-3 Cubic meter' },
                { id: '2', name: '3-6 Cubic meter' },
                { id: '3', name: '6-10 Cubic meter' },
                { id: '4', name: '10-15 Cubic meter' },
                { id: '5', name: 'Greater than 15 Cubic meter' },
                { id: '6', name: 'N/A' },
            ]
            const fence = [
                { id: '1', name: 'Wooden' },
                { id: '2', name: 'Colourbond' },
                { id: '3', name: 'Brush' },
                { id: '4', name: 'Chain Link' },
                { id: '5', name: 'Other' },
                { id: '6', name: 'N/A' },
            ]
            const roof = [
                { id: '1', name: 'Tiles' },
                { id: '2', name: 'Asbestos' },
                { id: '3', name: 'Tin' },
                { id: '4', name: 'Other' },
                { id: '5', name: 'N/A' },
            ]
            const damages = [
                { id: '1', name: 'Gutter' },
                { id: '2', name: 'Play Equipment' },
                { id: '3', name: 'Pool' },
                { id: '4', name: 'Car Port/Garage' },
                { id: '5', name: 'House' },
                { id: '6', name: 'Shed' },
                { id: '7', name: 'Clothes Line' },
                { id: '8', name: 'Other' },
                { id: '9', name: 'N/A' }
            ]
            if (workOperationsInfo.height != null) {
                let desc = '';
                for (const height of workOperationsInfo.height.split(',')) {
                    const heightDetails = heights.filter(val => height === val.id)[0];
                    if (typeof heightDetails !== 'undefined') {
                        if (desc !== null && desc !== '') {
                            desc = desc + ', ' + heightDetails.name;
                        } else {
                            desc = heightDetails.name;
                        }
                    }
                }
                tree_info.push({ label: 'Tree Height', desc: desc });
            }


            if (workOperationsInfo.trunk != null) {
                let desc = '';
                for (const trunk of workOperationsInfo.trunk.split(',')) {
                    const trunkDetails = diameters.filter(val => trunk === val.id)[0];
                    if (typeof trunkDetails !== 'undefined') {
                        if (desc !== null && desc !== '') {
                            desc = desc + ', ' + trunkDetails.name;
                        } else {
                            desc = trunkDetails.name;
                        }
                    }
                }
                tree_info.push({ label: 'Trunk Diameter', desc: desc });
            }

            if (workOperationsInfo.tree_location != null) {
                let desc = '';
                for (const location of workOperationsInfo.tree_location.split(',')) {
                    const locationDetails = locations.filter(val => location === val.id)[0];
                    if (typeof locationDetails !== 'undefined') {
                        if (desc !== null && desc !== '') {
                            desc = desc + ', ' + locationDetails.name;
                        } else {
                            desc = locationDetails.name;
                        }
                    }
                }
                tree_info.push({ label: 'Trunk Location', desc: desc });
            }

            if (workOperationsInfo.health != null) {
                let desc = '';
                for (const health of workOperationsInfo.health.split(',')) {
                    const healthDetails = healths.filter(val => health === val.id)[0];
                    if (typeof healthDetails !== 'undefined') {
                        if (desc !== null && desc !== '') {
                            desc = desc + ', ' + healthDetails.name;
                        } else {
                            desc = healthDetails.name;
                        }
                    }
                }
                tree_info.push({ label: 'Trunk Health', desc: desc });
            }

            if (workOperationsInfo.age != null) {
                let desc = '';
                for (const age of workOperationsInfo.age.split(',')) {
                    const ageDetails = treeAge.filter(val => age === val.id)[0];
                    if (typeof ageDetails !== 'undefined') {
                        if (desc !== null && desc !== '') {
                            desc = desc + ', ' + ageDetails.name;
                        } else {
                            desc = ageDetails.name;
                        }
                    }
                }
                tree_info.push({ label: 'Tree Age', desc: desc });
            }


            if (workOperationsInfo.distance != null) {
                let desc = '';
                for (const distance of workOperationsInfo.distance.split(',')) {
                    const distanceDetails = distances.filter(val => distance === val.id)[0];
                    if (typeof distanceDetails !== 'undefined') {
                        if (desc !== null && desc !== '') {
                            desc = desc + ', ' + distanceDetails.name;
                        } else {
                            desc = distanceDetails.name;
                        }
                    }
                }
                tree_info.push({ label: 'Tree Distance to Building', desc: desc });
            }

            if (workOperationsInfo.access != null) {
                let desc = '';
                for (const acc of workOperationsInfo.access.split(',')) {
                    const accessDetails = access.filter(val => acc === val.id)[0];
                    if (typeof accessDetails !== 'undefined') {
                        if (desc !== null && desc !== '') {
                            desc = desc + ', ' + accessDetails.name;
                        } else {
                            desc = accessDetails.name;
                        }
                    }
                }
                tree_info.push({ label: 'Tree Access', desc: desc });
            }

            if (workOperationsInfo.work_category != null) {
                let desc = '';
                for (const work of workOperationsInfo.work_category.split(',')) {
                    const workDetails = workRequired.filter(val => work === val.id)[0];
                    if (typeof workDetails !== 'undefined') {
                        if (desc !== null && desc !== '') {
                            desc = desc + ', ' + workDetails.name;
                        } else {
                            desc = workDetails.name;
                        }
                    }
                }
                tree_info.push({ label: 'Works', desc: desc });
            }

            if (workOperationsInfo.waste != null) {
                let desc = '';
                for (const waste of workOperationsInfo.waste.split(',')) {
                    const wasteDetails = cubicMeter.filter(val => waste === val.id)[0];
                    if (typeof wasteDetails !== 'undefined') {
                        if (desc !== null && desc !== '') {
                            desc = desc + ', ' + wasteDetails.name;
                        } else {
                            desc = wasteDetails.name;
                        }
                    }
                }
                tree_info.push({ label: 'Cubic Meters', desc: desc });
            }

            if (workOperationsInfo.fence != null) {
                let desc = '';
                for (const fnce of workOperationsInfo.fence.split(',')) {
                    let fenceDetails = fence.filter(val => fnce === val.id)[0];
                    if (!(typeof fenceDetails !== 'undefined' && typeof (fenceDetails) !== 'undefined')) {

                        if (fnce === '118') { fenceDetails = { id: '1', name: 'Wooden' }; }
                        if (fnce === '117') { fenceDetails = { id: '4', name: 'Chain Link' }; }
                        if (fnce === '121') { fenceDetails = { id: '5', name: 'Other' }; }
                        if (fnce === '122') { fenceDetails = { id: '6', name: 'N/A' }; }
                    }
                    if (typeof fenceDetails !== 'undefined') {
                        if (desc !== null && desc !== '') {
                            desc = desc + ', ' + fenceDetails.name;
                        } else {
                            desc = fenceDetails.name;
                        }
                    }
                }
                damages_info.push({ label: 'Fence', desc: desc });
            }


            if (workOperationsInfo.roof != null) {
                let desc = '';
                for (const rf of workOperationsInfo.roof.split(',')) {
                    const roofDetails = roof.filter(val => rf === val.id)[0];
                    if (typeof roofDetails !== 'undefined') {
                        if (desc !== null && desc !== '') {
                            desc = desc + ', ' + roofDetails.name;
                        } else {
                            desc = roofDetails.name;
                        }
                    }
                }
                damages_info.push({ label: 'Roof', desc: desc });
            }


            if (workOperationsInfo.other != null) {
                let desc = '';
                for (const othr of workOperationsInfo.other.split(',')) {
                    const otherDetails = damages.filter(val => othr === val.id)[0];
                    if (typeof otherDetails !== 'undefined') {
                        if (desc !== null && desc !== '') {
                            desc = desc + ', ' + otherDetails.name;
                        } else {
                            desc = otherDetails.name;
                        }
                    }
                }
                damages_info.push({ label: 'Other Damages', desc: desc });
            }
            treeData.tree_info = tree_info;
            treeData.damages = damages_info;
        }
        return treeData;
    }


    async getImageFileByPath(imagePath): Promise<any[]> {
        //console.log('getImgeData');
        let res: any;
        res = await this.crudService.getImageFile(imagePath).toPromise();
        //console.log(res);
        return res;
    }

    async getDocumentData(jobId, jobAllocationId): Promise<any[]> {
        const apiRoot = this.crudService.getAPIRoot();
        let res: any;
        const documentData: any[] = [];
        res = await this.crudService.getUploadDocumentsByJobIdAllocId(jobId, jobAllocationId).toPromise();
        const fileData = res.length > 0 ? res : [];
        res.forEach(file => {
            if (file.inc_quote === '2') {
                const docObj = {
                    fileName: file.file_name,
                    filePath: apiRoot + file.file_path,
                    file_description: file.file_description,
                    s3Url: file?.s3_url
                }
                documentData.push(docObj);
            }
        });
        return documentData;
    }

    generateQuoPdf(jobAllocationResults) {
        const tempTime = null;
        const quoreportId = 6;
        let display_exists;
        if ((jobAllocationResults.display_no === 'undefined') || (jobAllocationResults.display_no === null) || (jobAllocationResults.display_no === 'NULL')) {
            display_exists = false;
        } else {
            display_exists = true;
        }
        const jsonObject = {
            costHeadId: jobAllocationResults.id,
            quote_date: jobAllocationResults.quote_date,
            quote_no: jobAllocationResults.quote_no,
            jobId: jobAllocationResults.job_id,
            cost_refno: jobAllocationResults.cost_refno,
            jobAllocId: jobAllocationResults.job_alloc_id,
            cost_date: jobAllocationResults.date,
            subtotal: parseFloat(jobAllocationResults.sub_total).toFixed(2),
            taxrate: parseFloat(jobAllocationResults.tax_rate).toFixed(2),
            grandtotal: parseFloat(jobAllocationResults.tp_grand_total).toFixed(2),
            wpgrandtotal: parseFloat(jobAllocationResults.wp_grand_total).toFixed(2),
            wptaxtotal: parseFloat(jobAllocationResults.wp_tax_total).toFixed(2),
            wpsubtotal: parseFloat(jobAllocationResults.wp_sub_total).toFixed(2),
            tax: parseFloat(jobAllocationResults.tp_tax_total).toFixed(2),
            jobManager: jobAllocationResults.job_manager,
            jobContact: jobAllocationResults.job_contact,
            jobNotes: jobAllocationResults.job_notes,
            jobSubstantiation: jobAllocationResults.tp_job_substantiation,
            processId: jobAllocationResults.process_id,
            wpPmNotes: jobAllocationResults.wp_pm_notes,
            wpPmSubstantiation: jobAllocationResults.wp_pm_substantiation,
            tp_invoice_no: jobAllocationResults.tp_invoice_no,
            //             wp_invoice_no : jobAllocationResults.wp_invoice_no?jobAllocationResults.wp_invoice_no:null,
            //             wp_inv_dt: jobAllocationResults.wp_inv_dt?utilService.DateToLocalTimeStringOnlyDate(jobAllocationResults.wp_inv_dt):null,
            //             }
            //             if(display_exists){
            //                     return this.crudService.getCostFormDetailByJobIdAllocId2(jobId, jobAllocationId)
            //                         .subscribe((data:any)=> {
            //                            let mainItems = [];
            //                             //console.log('cost head detail data from db');
            //                             //console.log(data);
            //                             if (data.length > 0) {
            //                                 let cost_items=[];
            //                                 let selItem = null;
            //                                 data.forEach(main_item => {
            //                                    cost_items.forEach(cItem => {;
            //                                         if (cItem.id === main_item.item_id) {
            //                                             selItem = cItem;
            //                                         }
            //                                     });

            //                                     let mainItem = {
            //                                         id: main_item.id,
            //                                         headId: main_item.head_id,
            //                                         selectedItem: selItem,
            //                                         qty: parseInt(main_item.item_qty),
            //                                         price: parseFloat(main_item.item_price).toFixed(2),
            //                                         hours: parseFloat(main_item.item_hrs),
            //                                         desc: main_item.item_desc,
            //                                         cost_rate_select: main_item.item_rate,
            //                                         entry_point: main_item.entry_point,
            //                                         quote_inc: main_item.quote_inc,
            //                                         wp_rate: parseFloat(main_item.wp_rate).toFixed(2),
            //                                         wp_hrs: main_item.wp_hrs,
            //                                         wp_qty: main_item.wp_qty,
            //                                         wp_total: parseFloat(main_item.wp_total).toFixed(2),
            //                                         total: parseFloat(main_item.wp_total).toFixed(2)
            //                                     }
            //                                     mainItems.push(mainItem);
            //                                 });
            //                                  this.getWorkOptInfoData().subscribe((res:any) => {
            //                                     let data = [{
            //                                         info: {
            //                                             id: '67993bdd-f7d9-48a6-93d9-8026b657041a',
            //                                             jobid: jobId,
            //                                             reportid: quoreportId,
            //                                         },
            //                                         invoice: {
            //                                             tax_invoice: jobAllocationResults.JobNo,
            //                                             country: EnvCountry,
            //                                             add1: EnvAddressLine1,
            //                                             add2: EnvAddressLine2,
            //                                             city: EnvState,
            //                                             zip: EnvZip,
            //                                             fax: EnvFax,
            //                                             phone: EnvPhone,
            //                                             email: EnvEmail,
            //                                             website: EnvWebsite,
            //                                             name: pdfInfo.bankDetail.account_name,
            //                                             account: pdfInfo.bankDetail.account_number,
            //                                             bsb: pdfInfo.bankDetail.account_BSB,
            //                                             abn: pdfInfo.bankDetail.business_ABN,
            //                                             abnName: EnvABNName,
            //                                             date: utilService.DateToString(new Date()),
            //                                         },
            //                                         job: {
            //                                             no: pdfInfo.siteInfo.job_no,
            //                                             desc: 'Test Job',
            //                                             claim_no: pdfInfo.siteInfo.job_claim_no,
            //                                             provider_claim: pdfInfo.siteInfo.job_provider_ref,
            //                                             site_address: pdfInfo.siteInfo.address,
            //                                         },
            //                                         customer: {
            //                                             name: pdfInfo.enviroBankDetail.main_contact_name,
            //                                             company: pdfInfo.enviroBankDetail.business_name,
            //                                             phone: pdfInfo.enviroBankDetail.phone,
            //                                             fax: '1300077446',
            //                                             abn: pdfInfo.enviroBankDetail.business_ABN,
            //                                         },
            //                                         items:mainItems,
            //                                         total: {
            //                                             taxrate: taxrate,
            //                                             sub_total: subtotal,
            //                                             tax: tax,
            //                                             all_total: grandtotal,
            //                                             rebate: "0",
            //                                         },
            //                                         prodj_manager_substn: jobSubstantiation,


            //                                 tree_info: [
            //                                     { label: 'Tree Height', desc: ConvertTreeHeight(treeHeight)},
            //                                     { label: 'Trunk Diameter', desc: ConvertTrunkDiameter(trunkDiameters) },
            //                                     { label: 'Tree Location', desc: ConvertTreeLocation(treeLocation) },
            //                                     { label: 'Tree Distance to Building', desc: ConvertTreeDistance(treeDistance) },
            //                                     { label: 'Tree Health', desc: ConvertTreeHealth(treeHealth) },
            //                                     { label: 'Access', desc: ConvertAccess(access) },
            //                                     { label: 'Works', desc: ConvertWorksRequired(worksRequired)  },
            //                                     { label: 'Cubic Meters', desc: ConvertCubicMeters(cubicMeters)  },
            //                                     { label: 'No of Trees', desc:no_tree },
            //                                 ],
            //                                 damages: [
            //                                     { label: 'Fence', desc: ConvertFence(fence)  },
            //                                     { label: 'Roof', desc: ConvertRoof(roof)  },
            //                                     { label: 'Other Damages', desc: ConvertOtherDamages(otherDamages)  },
            //                                 ]
            //                                     }];
            //                                     let date = new Date();
            //                                     tempTime = date.getTime();
            //                                     let res = ReportService.generateRerportTP(data, quoreportId, jobId, allocId, tempTime);
            //                                     return res.then(function(data) {
            //                                         //close spinner
            //                                         //                                               usSpinnerService.stop('spinner-1');
            //                                         let url = backendUrl + '/' + jobId + '/' + allocId + '/' + 'report-template-' + quoreportId + '-' + tempTime + '.pdf';
            //                                         quoPdfUrl = url;
            //                                         return url;
            //                                     });
            //                                 });
            //                             }
            //                             return null;

            //                         });
            //                     }else{
            //                      this.crudService.getCostFormDetailByJobIdAllocId(jobId, jobAllocationId)
            //                                                     .subscribe((data:any)=> {
            //                                                         let mainItems = [];
            //                                                         //console.log('cost head detail data from db');
            //                                                         //console.log(data);
            //                                                         if (data.length > 0) {

            //                                                             let selItem = null;
            //                                                             let cost_items=[];
            //                                                             data.forEach(main_item =>{                                                    
            //                                                           cost_items.forEach(cItem => {

            //                                                                     if (cItem.id === main_item.item_id) {
            //                                                                         selItem = cItem;
            //                                                                     }
            //                                                                 });

            //                                                                 let mainItem = {
            //                                                                     id: main_item.id,
            //                                                                     headId: main_item.head_id,
            //                                                                     selectedItem: selItem,
            //                                                                     qty: parseInt(main_item.item_qty),
            //                                                                     price: parseFloat(main_item.item_price).toFixed(2),
            //                                                                     hours: parseFloat(main_item.item_hrs),
            //                                                                     desc: main_item.item_desc,
            //                                                                     cost_rate_select: main_item.item_rate,
            //                                                                     entry_point: main_item.entry_point,
            //                                                                     quote_inc: main_item.quote_inc,
            //                                                                     wp_rate: parseFloat(main_item.wp_rate).toFixed(2),
            //                                                                     wp_hrs: main_item.wp_hrs,
            //                                                                     wp_qty: main_item.wp_qty,
            //                                                                     wp_total: parseFloat(main_item.wp_total).toFixed(2),
            //                                                                     total: parseFloat(main_item.wp_total).toFixed(2)
            //                                                                 }
            //                                                                 mainItems.push(mainItem);
            //                                                             });
            //                                                              this.getWorkOptInfoData().then(res => {
            //                                                                 let data = [{
            //                                                                     info: {
            //                                                                         id: '67993bdd-f7d9-48a6-93d9-8026b657041a',
            //                                                                         jobid: jobId,
            //                                                                         reportid: quoreportId,
            //                                                                     },
            //                                                                     invoice: {
            //                                                                         tax_invoice: jobAllocationResults.JobNo,
            //                                                                         country: EnvCountry,
            //                                                                         add1: EnvAddressLine1,
            //                                                                         add2: EnvAddressLine2,
            //                                                                         city: EnvState,
            //                                                                         zip: EnvZip,
            //                                                                         fax: EnvFax,
            //                                                                         phone: EnvPhone,
            //                                                                         email: EnvEmail,
            //                                                                         website: EnvWebsite,
            //                                                                         name: pdfInfo.bankDetail.account_name,
            //                                                                         account: pdfInfo.bankDetail.account_number,
            //                                                                         bsb: pdfInfo.bankDetail.account_BSB,
            //                                                                         abn: pdfInfo.bankDetail.business_ABN,
            //                                                                         abnName: EnvABNName,
            //                                                                         date: utilService.DateToString(new Date()),
            //                                                                     },
            //                                                                     job: {
            //                                                                         no: pdfInfo.siteInfo.job_no,
            //                                                                         desc: 'Test Job',
            //                                                                         claim_no: pdfInfo.siteInfo.job_claim_no,
            //                                                                         provider_claim: pdfInfo.siteInfo.job_provider_ref,
            //                                                                         site_address: pdfInfo.siteInfo.address,
            //                                                                     },
            //                                                                     customer: {
            //                                                                         name: pdfInfo.enviroBankDetail.main_contact_name,
            //                                                                         company: pdfInfo.enviroBankDetail.business_name,
            //                                                                         phone: pdfInfo.enviroBankDetail.phone,
            //                                                                         fax: '1300077446',
            //                                                                         abn: pdfInfo.enviroBankDetail.business_ABN,
            //                                                                     },
            //                                                                     items:mainItems,
            //                                                                     total: {
            //                                                                         taxrate: taxrate,
            //                                                                         sub_total: subtotal,
            //                                                                         tax: tax,
            //                                                                         all_total: grandtotal,
            //                                                                         rebate: "0",
            //                                                                     },
            //                                                                    prodj_manager_substn: jobSubstantiation,


            //                                                             tree_info: [
            //                                                                 { label: 'Tree Height', desc: ConvertTreeHeight(treeHeight)},
            //                                                                 { label: 'Trunk Diameter', desc: ConvertTrunkDiameter(trunkDiameters) },
            //                                                                 { label: 'Tree Location', desc: ConvertTreeLocation(treeLocation) },
            //                                                                 { label: 'Tree Distance to Building', desc: ConvertTreeDistance(treeDistance) },
            //                                                                 { label: 'Tree Health', desc: ConvertTreeHealth(treeHealth) },
            //                                                                 { label: 'Access', desc: ConvertAccess(access) },
            //                                                                 { label: 'Works', desc: ConvertWorksRequired(worksRequired)  },
            //                                                                 { label: 'Cubic Meters', desc: ConvertCubicMeters(cubicMeters)  },
            //                                                                 { label: 'No of Trees', desc:no_tree },
            //                                                             ],
            //                                                             damages: [
            //                                                                 { label: 'Fence', desc: ConvertFence(fence)  },
            //                                                                 { label: 'Roof', desc: ConvertRoof(roof)  },
            //                                                                 { label: 'Other Damages', desc: ConvertOtherDamages(otherDamages)  },
            //                                                             ]
            //                                                                 }];
            //                                                                 let date = new Date();
            //                                                                 tempTime = date.getTime();
            //                                                                 let res = ReportService.generateRerportTP(data, quoreportId, jobId, allocId, tempTime);
            //                                                                 return res.then(function(data) {
            //                                                                     //close spinner
            //                                                                     //                                               usSpinnerService.stop('spinner-1');
            //                                                                     let url = backendUrl + '/' + jobId + '/' + allocId + '/' + 'report-template-' + quoreportId + '-' + tempTime + '.pdf';
            //                                                                     quoPdfUrl = url;
            //                                                                     return url;
            //                                                                 });
            //                                                             });
            //                                                        }
            //                                                        return null;

            //                                                     });

            //                     }
            //         }
            //         return null;
            //     });
            //   } 

        }

    }


    storeInSchedule(jobAllocationResults) {
        let message = '';
        let sms = '';
        let email = '';
        let contactUI = '';
        if (jobAllocationResults.info.TPEmail === '' && jobAllocationResults.info.TPMobile === '') {
            message = 'Quote approved. Notification not sent to the TP';
            sms = '';
            email = '';
            if (sms === '1' && email === '1') {
                contactUI = 'N/A';
            } else {
                contactUI = '';
            }
        } else {
            message = 'Quote approval notification has been sent to the TP (Email/SMS)';
            sms = '2';
            email = '2';
        }
        let store_data = {
            id: null,
            job_id: jobAllocationResults.jobId,
            job_alloc_id: jobAllocationResults.jobAllocationId,
            process_id: 1,
            visit_type: null,
            status: null,
            sched_date: null,
            PMOnly: '2',
            sched_note: message,
            start_time: null,
            end_time: null,
            created_by: jobAllocationResults.userDetails.id,
            created_at: new Date(),
            phoned: '1',
            phone_no: null,
            sms: sms,
            sms_no: jobAllocationResults.info.TPMobile,
            email: email,
            emailaddress: jobAllocationResults.info.TPEmail,
            callback: '1',
            contactUI: contactUI
        }
        this.crudService.Create(store_data, 'JobSchedule').subscribe((data: any) => {
        });
    }


    ConvertTreeHeight(d) {
        const t = ['0 - 5 meters', '6 - 10 meters', '11 - 15 meters', 'Greater than 20 meters', 'N/A', '16 - 20 meters'];
        let result = '';
        if (!d) {
            return '';
        }

        const test = d.split(',');
        if (test.length > 1) {
            test.forEach(element => {
                if (element === '1') {
                    result += t[0] + ',';
                }
                if (element === '2') {
                    result += t[1] + ',';
                }
                if (element === '3') {
                    result += t[2] + ',';
                }
                if (element === '4') {
                    result += t[3] + ',';
                }
                if (element === '5') {
                    result += t[4] + ',';
                }
                if (element === '33') {
                    result += t[5] + ',';
                }
            });
        } else {
            if (d === '1') {
                result += t[0] + ',';
            }
            if (d === '2') {
                result += t[1] + ',';
            }
            if (d === '3') {
                result += t[2] + ',';
            }
            if (d === '4') {
                result += t[3] + ',';
            }
            if (d === '5') {
                result += t[4] + ',';
            }
            if (d === '33') {
                result += t[5] + ',';
            }


        }

        return result.slice(0, -1);
    }
    ConvertTrunkDiameter(d) {
        const t = ['0 to 400 mm', '400 mm to 1000 mm', 'Greater than 1000mm', 'N/A'];
        let result = '';
        if (!d) {
            return '';
        }
        if (d.includes('1')) {
            result += t[0] + ',';
        }
        if (d.includes('2')) {
            result += t[1] + ',';
        }
        if (d.includes('3')) {
            result += t[2] + ',';
        }
        if (d.includes('4')) {
            result += t[3] + ',';
        }
        return result.slice(0, -1);
    }
    ConvertTreeHealth(d) {
        const t = ['Good', 'Average', 'Poor', 'N/A'];
        let result = '';
        if (!d) {
            return '';
        }
        if (d.includes('1')) {
            result += t[0] + ',';
        }
        if (d.includes('2')) {
            result += t[1] + ',';
        }
        if (d.includes('3')) {
            result += t[2] + ',';
        }
        if (d.includes('4')) {
            result += t[3] + ',';
        }
        return result.slice(0, -1);
    }
    ConvertWorksRequired(d) {
        const t = ['Tree Removal', 'Tree Prune', 'Stump', 'Temp tarp', 'Temp fence', 'Remove Debris', 'N/A'];
        let result = '';
        if (!d) {
            return '';
        }
        if (d.includes('1')) {
            result += t[0] + ',';
        }
        if (d.includes('2')) {
            result += t[1] + ',';
        }
        if (d.includes('3')) {
            result += t[2] + ',';
        }
        if (d.includes('4')) {
            result += t[3] + ',';
        }
        if (d.includes('5')) {
            result += t[4] + ',';
        }
        if (d.includes('6')) {
            result += t[5] + ',';
        }
        if (d.includes('7')) {
            result += t[6] + ',';
        }
        return result.slice(0, -1);
    }
    ConvertCubicMeters(d) {
        const t = ['0 to 3 cubic meters', '3 to 6 cubic meters', '6 to 10 cubic meters', '10 to 15 cubic meters', 'More than 15 cubic meters', 'N/A'];
        let result = '';
        if (!d) {
            return '';
        }
        if (d.includes('1')) {
            result += t[0] + ',';
        }
        if (d.includes('2')) {
            result += t[1] + ',';
        }
        if (d.includes('3')) {
            result += t[2] + ',';
        }
        if (d.includes('4')) {
            result += t[3] + ',';
        }
        if (d.includes('5')) {
            result += t[4] + ',';
        }
        if (d.includes('6')) {
            result += t[5] + ',';
        }

        return result.slice(0, -1);
    }
    ConvertTreeLocation(d) {
        const t = ['Front of Property', 'Side of Property', 'Rear of Property', 'Neighbours', 'N/A'];
        let result = '';
        if (!d) {
            return '';
        }
        if (d.includes('1')) {
            result += t[0] + ',';
        }
        if (d.includes('2')) {
            result += t[1] + ',';
        }
        if (d.includes('3')) {
            result += t[2] + ',';
        }
        if (d.includes('4')) {
            result += t[3] + ',';
        }
        if (d.includes('5')) {
            result += t[4] + ',';
        }

        return result.slice(0, -1);
    }
    ConvertTreeDistance(d) {
        const t = ['0-5 meters', '5-10 meters', '10-20 meters', 'Greater than 20 meters', 'Neighbours', 'N/A'];
        let result = '';
        if (!d) {
            return '';
        }
        if (d.includes('1')) {
            result += t[0] + ',';
        }
        if (d.includes('2')) {
            result += t[1] + ',';
        }
        if (d.includes('3')) {
            result += t[2] + ',';
        }
        if (d.includes('4')) {
            result += t[3] + ',';
        }
        if (d.includes('5')) {
            result += t[4] + ',';
        }
        if (d.includes('6')) {
            result += t[5] + ',';
        }

        return result.slice(0, -1);
    }
    ConvertAccess(d) {
        const t = ['Good', 'Average', 'Poor', 'N/A'];
        let result = '';
        if (!d) {
            return '';
        }
        if (d.includes('1')) {
            result += t[0] + ',';
        }
        if (d.includes('2')) {
            result += t[1] + ',';
        }
        if (d.includes('3')) {
            result += t[2] + ',';
        }
        if (d.includes('4')) {
            result += t[3] + ',';
        }

        return result.slice(0, -1);
    }
    ConvertFence(d) {
        const t = ['Wooden', 'Colourbond', 'Brush', 'Chain Link', 'Other', 'N/A'];
        let result = '';
        if (!d) {
            return '';
        }
        if (d.includes('1')) {
            result += t[0] + ',';
        }
        if (d.includes('2')) {
            result += t[1] + ',';
        }
        if (d.includes('3')) {
            result += t[2] + ',';
        }
        if (d.includes('4')) {
            result += t[3] + ',';
        }
        if (d.includes('5')) {
            result += t[4] + ',';
        }
        if (d.includes('6')) {
            result += t[5] + ',';
        }
        return result.slice(0, -1);
    }
    ConvertRoof(d) {
        const t = ['Tiles', 'Asbestos', 'Tin', 'Other', 'N/A'];
        let result = '';
        if (!d) {
            return '';
        }
        if (d.includes('1')) {
            result += t[0] + ',';
        }
        if (d.includes('2')) {
            result += t[1] + ',';
        }
        if (d.includes('3')) {
            result += t[2] + ',';
        }
        if (d.includes('4')) {
            result += t[3] + ',';
        }
        if (d.includes('5')) {
            result += t[4] + ',';
        }
        return result.slice(0, -1);
    }
    ConvertOtherDamages(d) {
        const t = ['Gutter', 'Play Equipment', 'Pool', 'Car Port/Garage', 'House', 'Shed', 'Clothes Line', 'Other', 'N/A'];
        let result = '';
        if (!d) {
            return '';
        }
        if (d.includes('1')) {
            result += t[0] + ',';
        }
        if (d.includes('2')) {
            result += t[1] + ',';
        }
        if (d.includes('3')) {
            result += t[2] + ',';
        }
        if (d.includes('4')) {
            result += t[3] + ',';
        }
        if (d.includes('5')) {
            result += t[4] + ',';
        }
        if (d.includes('6')) {
            result += t[5] + ',';
        }
        if (d.includes('7')) {
            result += t[6] + ',';
        }
        if (d.includes('8')) {
            result += t[7] + ',';
        }
        if (d.includes('9')) {
            result += t[8] + ',';
        }
        return result.slice(0, -1);
    }



    ConvertOtherDamagesSub(d) {
        const t = ['gutter', 'play equipment', 'pool', 'car port/garage', 'house', 'shed', 'clothes line', 'other', 'n/a'];
        let result = '';
        if (!d) {
            return '';
        }
        const test = d.split(',');
        let counter = 0;
        let extra = false;
        if (test.length > 1) {
            test.forEach(element => {
                if (element === '1') {
                    if (counter > 1 && !extra) {
                        result += ' and other manufactured structures' + ',';
                        extra = true;
                    } else if (!extra) {

                        if (counter < 2) {
                            result += t[0] + ',';
                        } else {
                            result += t[0];
                        }
                    }
                    counter = counter + 1;
                }
                if (element === '2') {
                    if (counter > 1 && !extra) {
                        result += ' and other manufactured structures' + ',';
                        extra = true;
                    } else if (!extra) {
                        if (counter < 2) {
                            result += t[1] + ',';
                        } else {
                            result += t[1];
                        }
                    }
                    counter = counter + 1;
                }
                if (element === '3') {
                    if (counter > 1 && !extra) {
                        result += ' and other manufactured structures' + ',';
                        extra = true;
                    } else if (!extra) {
                        if (counter < 2) {
                            result += t[2] + ',';
                        } else {
                            result += t[2];
                        }
                    }
                    counter = counter + 1;
                }
                if (element === '4') {
                    if (counter > 1 && !extra) {
                        result += ' and other manufactured structures' + ',';
                        extra = true;
                    } else if (!extra) {
                        if (counter < 2) {
                            result += t[3] + ',';
                        } else {
                            result += t[3];
                        }
                    }
                    counter = counter + 1;
                }
                if (element === '5') {
                    if (counter > 1 && !extra) {
                        result += ' and other manufactured structures' + ',';
                        extra = true;
                    } else if (!extra) {
                        if (counter < 2) {
                            result += t[4] + ',';
                        } else {
                            result += t[4];
                        }
                    }
                    counter = counter + 1;
                }
                if (element === '6') {
                    if (counter > 1 && !extra) {
                        result += ' and other manufactured structures' + ',';
                        extra = true;
                    } else if (!extra) {
                        if (counter < 2) {
                            result += t[5] + ',';
                        } else {
                            result += t[5];
                        }
                    }
                    counter = counter + 1;
                }
                if (element === '7') {
                    if (counter > 1 && !extra) {
                        result += ' and other manufactured structures' + ',';
                        extra = true;
                    } else if (!extra) {
                        if (counter < 2) {
                            result += t[6] + ',';
                        } else {
                            result += t[6];
                        }
                    }
                    counter = counter + 1;
                }
                /*if(element=='8'){
                   if(counter>1 && !extra){
                   result+=' and other manufactured structures'+',';
                   extra=true;
                   }else if(!extra){
                       if(counter <2 ){
                         result+=t[7]+',';
                       }else{
                         result+=t[7] ;
                       }
                   }
                   counter=counter+1;
                }*/
            });
        } else {
            if (d === '1') {
                result += t[0] + ',';
            }
            if (d === '2') {
                result += t[1] + ',';
            }
            if (d === '3') {
                result += t[2] + ',';
            }
            if (d === '4') {
                result += t[3] + ',';
            }
            if (d === '5') {
                result += t[4] + ',';
            }
            if (d === '6') {
                result += t[5] + ',';
            } if (d === '7') {
                result += t[6] + ',';
            }
            /* if(d=='8'){
                 result+=t[7]+',';
             }*/
        }
        return result.slice(0, -1);
    }

    ConvertOtherDamagesSubHouse(d, houseFlag) {
        const t = ['gutter', 'play equipment', 'pool', 'car port/garage', 'house', 'shed', 'clothes line', 'other', 'n/a'];
        let result = '';
        if (!d) {
            return '';
        }
        const test = d.split(',');
        if (houseFlag) {
            const index = test.indexOf('5');
            if (index > -1) {
                test.splice(index, 1);
            }
        }

        let counter = 0;
        let extra = false;
        if (test.length > 1) {
            test.forEach(element => {
                if (element === '1') {
                    if (counter > 1 && !extra) {
                        result += ' and other manufactured structures' + ',';
                        extra = true;
                    } else if (!extra) {

                        if (counter < 2) {
                            result += t[0] + ',';
                        } else {
                            result += t[0];
                        }
                    }
                    counter = counter + 1;
                }
                if (element === '2') {
                    if (counter > 1 && !extra) {
                        result += ' and other manufactured structures' + ',';
                        extra = true;
                    } else if (!extra) {
                        if (counter < 2) {
                            result += t[1] + ',';
                        } else {
                            result += t[1];
                        }
                    }
                    counter = counter + 1;
                }
                if (element === '3') {
                    if (counter > 1 && !extra) {
                        result += ' and other manufactured structures' + ',';
                        extra = true;
                    } else if (!extra) {
                        if (counter < 2) {
                            result += t[2] + ',';
                        } else {
                            result += t[2];
                        }
                    }
                    counter = counter + 1;
                }
                if (element === '4') {
                    if (counter > 1 && !extra) {
                        result += ' and other manufactured structures' + ',';
                        extra = true;
                    } else if (!extra) {
                        if (counter < 2) {
                            result += t[3] + ',';
                        } else {
                            result += t[3];
                        }
                    }
                    counter = counter + 1;
                }
                if (element === '5') {
                    if (counter > 1 && !extra && !houseFlag) {
                        result += ' and other manufactured structures' + ',';
                        extra = true;
                    } else if (!extra && !houseFlag) {
                        if (counter < 2) {
                            result += t[4] + ',';
                        } else {
                            result += t[4];
                        }
                    }
                    counter = counter + 1;
                }
                if (element === '6') {
                    if (counter > 1 && !extra) {
                        result += ' and other manufactured structures' + ',';
                        extra = true;
                    } else if (!extra) {
                        if (counter < 2) {
                            result += t[5] + ',';
                        } else {
                            result += t[5];
                        }
                    }
                    counter = counter + 1;
                }
                if (element === '7') {
                    if (counter > 1 && !extra) {
                        result += ' and other manufactured structures' + ',';
                        extra = true;
                    } else if (!extra) {
                        if (counter < 2) {
                            result += t[6] + ',';
                        } else {
                            result += t[6];
                        }
                    }
                    counter = counter + 1;
                }
                /*if(element=='8'){
                   if(counter>1 && !extra){
                   result+=' and other manufactured structures'+',';
                   extra=true;
                   }else if(!extra){
                       if(counter <2 ){
                         result+=t[7]+',';
                       }else{
                         result+=t[7] ;
                       }
                   }
                   counter=counter+1;
                }*/
            });
        } else {
            if (d === '1') {
                result += t[0] + ',';
            }
            if (d === '2') {
                result += t[1] + ',';
            }
            if (d === '3') {
                result += t[2] + ',';
            }
            if (d === '4') {
                result += t[3] + ',';
            }
            if (d === '5') {
                result += t[4] + ',';
            }
            if (d === '6') {
                result += t[5] + ',';
            } if (d === '7') {
                result += t[6] + ',';
            }
            /* if(d=='8'){
                 result+=t[7]+',';
             }*/
        }
        return result.slice(0, -1);
    }

    convertDatatableToPDF(data) {

    }

    async exportPdf(exportColumns, data, filename): Promise<any[]> {
        let stream: any;
        filename = filename ? filename : 'data';
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(x => {
                /* const doc: jsPDF = new jsPDF("p", "mm", "a4");
                doc.autoTable(exportColumns, data);
                stream = doc; */
                //doc.getsave(filename+'.pdf');
            })
        })
        return stream;
    }


    async exportExcel(exportColumns, data, filename): Promise<any[]> {
        let stream: any;
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            stream = excelBuffer;
            //this.saveAsExcelFile(excelBuffer, filename);
        });
        return stream;
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        import('file-saver').then(FileSaver => {
            const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const EXCEL_EXTENSION = '.xlsx';
            const data: Blob = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    addUploadDoc(job_id, allocId, user_id, fileName, filePath, desc, reportId, tempTime, WPCompanyId) {
        var file_location = null;
        if ((WPCompanyId === '679') || (WPCompanyId === '570') || (WPCompanyId === '580') || (WPCompanyId === '125')) {
            file_location = job_id + '/' + allocId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf';
        }
        else if ((WPCompanyId === '219')) {
            if (reportId === '6') {
                file_location = job_id + '/' + allocId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf';
            } else if (reportId === '12') {
                file_location = job_id + '/' + allocId + '/' + 'report-template-' + reportId + '_SE-' + tempTime + '.pdf';
            } else {
                file_location = job_id + '/' + allocId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf';
            }
        } else {
            file_location = job_id + '/' + allocId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf';
        }
        if (file_location !== '' && file_location !== null) {
            filePath = file_location;
        }
        //add it to doc list
        fileName = this.getNewDocName(fileName);
        const docReadyUpload = {
            id: null,
            job_id: job_id,
            job_alloc_id: allocId,
            company_id: 1,
            upload_type: 0,
            process_id: 1,
            inc_quote: 2,
            upload_at: new Date(),
            file_size: 33000,
            hide: 2,
            upload_by: user_id,
            file_name: fileName,
            file_description: desc,
            upload_type_detail: 0,
            file_path: filePath,
            status: 1,
            s3_url: null,
            sync_status: 0
        }
        this.crudService.Create(docReadyUpload, 'uploaddocuments').subscribe((data: any) => {
            if (data.success === 1) {
            }
        });
    }


    addUploadDocSHF(job_id, allocId, user_id, fileName, filePath, desc, reportId, tempTime, WPCompanyId) {
        var file_location = job_id + '/' + allocId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf';
        if (file_location !== '' && file_location !== null) {
            filePath = file_location;
        }
        //add it to doc list
        fileName = this.getNewDocName(fileName);
        const docReadyUpload = {
            id: null,
            job_id: job_id,
            job_alloc_id: allocId,
            company_id: 1,
            upload_type: 0,
            process_id: 1,
            inc_quote: 2,
            upload_at: new Date(),
            file_size: 33000,
            hide: 1,
            upload_by: user_id,
            file_name: fileName,
            file_description: desc,
            upload_type_detail: 0,
            file_path: filePath,
            status: 1,
            s3_url: null,
            sync_status: 0
        }
        this.crudService.Create(docReadyUpload, 'uploaddocuments').subscribe((data: any) => {
            if (data.success === 1) {
            }
        });
    }

    sendEmail(toIds, ccIds, bccIds, subject, message, attachments, emailAccount) {
        let templ: any;

        emailAccount = emailAccount ? emailAccount : 'AU';
        templ.template = [{ email: {} }];
        templ.template[0].email.to = toIds;
        if (ccIds) {
            templ.template[0].email.cc = ccIds;
        }
        if (bccIds) {
            templ.template[0].email.bcc = bccIds;
        }
        templ.template[0].email.subject = subject ? subject : null;
        templ.template[0].email.attachments = attachments;
        let temEcontent = message;
        if (message) {
            temEcontent = temEcontent.replace(/>[\n\t&nbsp;]+</g, '><');
            temEcontent = temEcontent.replace(/&nbsp;/g, '');
            temEcontent = temEcontent.replace(/&ndash;/g, '');
            temEcontent = temEcontent.replace(/&/g, String.fromCharCode(228));
            temEcontent = JSON.stringify(temEcontent);
        }
        temEcontent = temEcontent.replace(/"/g, '');
        templ.template[0].email.msg = temEcontent ? temEcontent : ' ';
        const params = {
            sms: null,
            email: templ.template[0].email,
            type: 'email',
            selectedTpl: null,
            sufix: null,
            attachments: attachments,
            jobData: null,
            replyTo: null,
            account: emailAccount
        }
        this.crudService.sendMessage(params).subscribe((res2: any[]) => {
        });
    }


    deleteDoc(jobAllocationId, fileName) {
        this.crudService.deleteUploadPdf(jobAllocationId, fileName).subscribe((data: any) => {
            if (data.success === 1) {
            }
        });
    }

    deleteDoc_dup(jobAllocationId, fileName) {
        debugger
        this.crudService.deleteUploadPdf_dup(jobAllocationId, fileName).subscribe((data: any) => {
            if (data.success === 1) {
            }
        });
    }

    async loadInvoice324ReportData(jobId, jobAllocId, imgs, jobAllocationResults, reportId): Promise<any> {
        let data: any;
        data = [
            {
                //img_src: jobId + '/' + jobAllocId + '/CSO_Sign.jpg',
                info: {
                    id: '67993bdd-f7d9-48a6-93d9-8026b657041a',
                    jobid: 1,
                    reportid: reportId
                },
                company: {
                    job_num: jobAllocationResults.JobNo,
                    contractor: 'TEST COMPANY - Enviro Tree Service',
                    customerDetails: jobAllocationResults.info.contact,

                },
            }
        ];

        //console.log('THis');
        let newReportData: any;
        const resultData: any = await this.crudService.getDataByField('ClientInvoiceInfo', 'job_alloc_id', jobAllocId).toPromise();
        const itemData: any = await this.crudService.getDataByField('WPInvoiceReportCostAreaForAppView', 'job_alloc_id', jobAllocId).toPromise();
        let sub_total = itemData.reduce(function (_this, val) {
            return _this + val.total
        }, 0);
        let invDate = '';
        let inspDate = '';
        let inspTime = '';
        let tax = sub_total / 10;
        let total = sub_total + tax;
        if (resultData[0].invoice_date) {
            invDate = new Date(resultData[0].invoice_date).toLocaleDateString();
        }
        resultData[0].invoice_date = invDate;
        if (resultData[0].inspection_date) {
            inspDate = new Date(resultData[0].inspection_date).toLocaleDateString();
        }
        resultData[0].inspection_date = inspDate;
        if (resultData[0].inspection_date) {
            inspTime = new Date(resultData[0].inspection_time).toLocaleTimeString();
        }
        resultData[0].inspection_time = inspTime;
        if (resultData.length > 0) {
            if (resultData[0].invoice_img) {
                const imageData: any = await this.crudService.getDataByField('UploadImages', 'id', resultData[0].invoice_img).toPromise();
                resultData[0].image = 'C://uploadImgs//' + imageData[0].img_path;
            } else {
                resultData[0].image = '';
            }
            if (resultData[0].safe_yn == '1') {
                resultData[0].safe_yn_desc = 'Yes';
            } else if (resultData[0].safe_yn == '0') {
                resultData[0].safe_yn_desc = 'No';
            } else {
                resultData[0].safe_yn_desc = '';
            }

            if (resultData[0].concerns_yn == '1') {
                resultData[0].concerns_yn_desc = 'Yes';
            } else if (resultData[0].concerns_yn == '0') {
                resultData[0].concerns_yn_desc = 'No';
            } else {
                resultData[0].concerns_yn_desc = '';
            }
        } else {

        }


        resultData[0].tax = tax.toFixed(2);
        resultData[0].sub_total = sub_total.toFixed(2);
        resultData[0].total = total.toFixed(2);

        itemData.forEach(element => {
            element.total = element.total.toFixed(2);
        });
        itemData.sort(function (b, a) { return b.display_no - a.display_no });
        if (resultData.length > 0) {
            newReportData = {
                TEMPLATE_ID: 'C://Source//Report_Templates//template_INV.docx', Head: resultData[0], Items: itemData
            }
        }
        let pic_items: any[] = [];
        imgs.forEach(img => {

            const item = {
                image: img.imgType,
                comments: new Date(),
                src: 'C://uploadImgs//' + img.imgPath
            }
            pic_items.push(item);
        });

        data[0].imgs = pic_items;
        data[0].newReportData = newReportData;

        return data;
    }

    async loadKPIReportData(jobId, jobAllocId, jobAllocationResults, reportId): Promise<any> {
        const items: any[] = [];
        let data: any;
        const details: any = await this.crudService.getCSOReportKPIDetails(jobAllocId).toPromise();
        details.forEach(measure => {
            const obj = {
                id: measure.RowNum,
                key: measure.kpi_measure_name,
                score: measure.score
            }
            items.push(obj);
        });

        data = [
            {
                img_src: jobId + '/' + jobAllocId + '/CSO_Sign.jpg',
                info: {
                    id: '67993bdd-f7d9-48a6-93d9-8026b657041a',
                    jobid: 1,
                    reportid: reportId
                },
                company: {
                    job_num: jobAllocationResults.JobNo,
                    contractor: 'TEST COMPANY - Enviro Tree Service',
                    customerDetails: jobAllocationResults.info.contact,

                },
                items: [
                    {
                        id: 1,
                        detail: 'Tree Partner Performance as reviewed ',
                        item: items
                    }
                ],
                overallFeedback: details[0].overallFeedback,
                totalStaff: details[0].totalStaff,
                totalHours: details[0].totalHours
            }
        ];

        let newReportData: any;
        const resultData: any = await this.crudService.getCSOData(jobAllocId).toPromise();

        if (resultData.length > 0) {
            let signExists = null;
            if (resultData[0].SignaturePath === null) {
                signExists = 'false';
            } else {
                signExists = 'true';
            }
            newReportData = {
                TEMPLATE_ID: resultData[0].TEMPLATE_ID, JobNo: resultData[0].JobNo, JobRefNo: resultData[0].JobRefNo,
                InspectionDate: resultData[0].InspectionDate, CompletionDate: resultData[0].CompletionDate, ClientName: resultData[0].ClientName,
                ContactNo: 'Test', CustomerDetails: resultData[0].CustomerDetails, Datetime: '18/12/2020 11AM', SiteAddress: resultData[0].SiteAddress,
                WorkOrder: resultData[0].WorkOrderDetails, Feedback: resultData[0].Feedback, ImageURL: 'C:/companyCompliances/' + resultData[0].WPCompanyId + '/company_logo.png',
                SignExists: signExists, SIGN: resultData[0].SignaturePath, SignDate: resultData[0].SignDate,
            }
            data[0].overallFeedback = resultData[0].Feedback,
                data[0].totalStaff = resultData[0].Staff,
                data[0].totalHours = resultData[0].Hours
        }
        newReportData.Feedback = resultData[0].Feedback ? resultData[0].Feedback : '';
        data[0].newReportData = newReportData;
        return data;
    }

    async generateJasperReport(data, reportId, jobId, allocId, tempTime): Promise<any> {
        this.crudService.generateJasperReport(data, reportId, jobId, allocId, tempTime, null);
        const apiRoot = this.crudService.getAPIRoot();
        const url = apiRoot + '/' + jobId + '/' + allocId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf';
        return url;
    }

    setINV_324_data(job_id, job_alloc_id) {
        let Json = [{
            PATH: 'W://Projects//templates',
            TEMPLATE: 'Blank_A4',
            invoiceData: {
                attendDate: '22/05/2022',
                attendTime: '10:45 am',
                supp_rep_name: 'Peter Dubiez',
                cust_rep_name: 'Anthony Salerno',
                address: '3 Lisheen Road, Cockatoo, 3781, VIC, Australia',
                instructions: 'Provide cost and photos for approval prior to proceeding. Inspect and quote large trees that have fallen and one tree is due to fall',
                incident_info: 'Large trees have fallen and one tree is due to fall due to storm activity',
                treeHeight: '11-15 meter',
                trunkDiameter: 'Greater than 1000 mm',
                trunkLocation: 'Rear of Property, Side of Property',
                trunkHealth: 'Good, Average',
                treeDistance: 'N/A',
                treeAccess: 'Poor',
                works: 'Remove Debris, Stump, Tree Removal',
                cubicMetres: 'Greater than 15 Cubic meter',
                fence: 'Yes',
                roof: 'No',
                other: 'No',
                worksDetails: 'Attend site, carefully dismantle numerous (4) large failed trees impacting onto and damaging boundary fence. Remove all cut material and debris from site including raised root plate. Leave site safe, clean and clear for further building services. NOTE: All works conducted within insured boundary fence line only. Limited access.',
                recommendations: 'Recommend removal of 2x large standing compromised trees. Enviro Trees nor the arborist take no responsibility for further damage, injury or worse should the compromised tree not be made safe.',
                safetyConcerns: 'NOTE SURE IF WE CAN EXTRACT THE SAFTY CONCERNS HIGHLIGHTED FROM THE RISK ASSESSMENT. IF NOT POSSIBLE, LEAVE THIS OUT.',
                total: '708.75',
                tax: '70.87',
                nett: '779.62',
                imagePath: 'C://uploadImgs//',
                costingData: [{
                    item: 'Climber/Rigger',
                    qty: '1',
                    price: '80.95',
                    cost: '404.25'
                },
                {
                    item: 'Supervisor',
                    qty: '1',
                    price: '60.90',
                    cost: '304.50'
                }
                ],
                beforeImages: [{
                    image_path: '18787//10936//afterpay.jpg',
                    date: '02-04-2022 10:45 AM',
                    comments: 'Tree Trunk Broken'
                },
                {
                    image_path: '18788//1//Agile-final.png.png',
                    date: '02-04-2022 10:50 AM',
                    comments: 'Tree Trunk Broken'
                }
                ],
                afterImages: [{
                    image_path: '18787//10936//afterpay.jpg',
                    date: '02-04-2022 10:45 AM',
                    comments: 'Tree Trunk Broken'
                },
                {
                    image_path: '18788//1//Agile-final.png.png',
                    date: '02-04-2022 10:50 AM',
                    comments: 'Tree Trunk Broken'
                }
                ]

            }
        }]
    }


    async setSHFData(jobId, jobAllocationId, reportId, jobAllocationDetails): Promise<any> {
        debugger
        const appContactTextMatrixList: any = await this.crudService.getAllData('appcontacttextmatrix').toPromise();
        let criteria_1_options = appContactTextMatrixList.filter(val => (val.workflow_step === 'Weather Hazards'));
        let control_1_options = appContactTextMatrixList.filter(val => (val.workflow_step === 'Weather Control'));

        let criteria_2_options = appContactTextMatrixList.filter(val => (val.workflow_step === 'Job Site Hazards'));
        let control_2_options = appContactTextMatrixList.filter(val => (val.workflow_step === 'Job Site Control'));

        let criteria_3_options = appContactTextMatrixList.filter(val => (val.workflow_step === 'Tree Hazards'));
        let control_3_options = appContactTextMatrixList.filter(val => (val.workflow_step === 'Tree Control'));

        let criteria_4_options = appContactTextMatrixList.filter(val => (val.workflow_step === 'Manual Tasks Hazards'));
        let control_4_options = appContactTextMatrixList.filter(val => (val.workflow_step === 'Manual Tasks Control'));

        /* let risk_1_options = appContactTextMatrixList.filter(val=> (val.workflow_step === 'Weather Risk'));
        let risk_2_options = appContactTextMatrixList.filter(val=> (val.workflow_step === 'Job Site Risk'));
        let risk_3_options = appContactTextMatrixList.filter(val=> (val.workflow_step === 'Tree Risk'));
        let risk_4_options = appContactTextMatrixList.filter(val=> (val.workflow_step === 'Manual Tasks Risk')); */

        const item = [];
        const k = 0;
        const scores = [];
        let data: any;
        let siteHazardInfo: any;
        let selectedCriteria_1: any[] = [];
        let selectedCriteria_2: any[] = [];
        let selectedCriteria_3: any[] = [];
        let selectedCriteria_4: any[] = [];
        let selectedControls_1: any[] = [];
        let selectedControls_2: any[] = [];
        let selectedControls_3: any[] = [];
        let selectedControls_4: any[] = [];
        let selectedRisk_1: any;
        let selectedRisk_2: any;
        let selectedRisk_3: any;
        let selectedRisk_4: any;
        let selectedCompUser: any;
        const selectedUsers: any[] = [];
        let statusUI: any;
        let selectedCriteria_1_desc: any;
        let selectedCriteria_2_desc: any;
        let selectedCriteria_3_desc: any;
        let selectedCriteria_4_desc: any;
        let selectedControls_1_desc: any;
        let selectedControls_2_desc: any;
        let selectedControls_3_desc: any;
        let selectedControls_4_desc: any;
        let reportData: any;


        let criteria_name_1 = 'Weather Hazard';
        let criteria_name_2 = 'Job Site Hazards';
        let criteria_name_3 = 'Tree Hazards';
        let criteria_name_4 = 'Manual Tasks Hazard';
        // criteria_name_5 = 'PPE (Personal Protective Equipment/Clothing)';





        let rating_1_UI: any;
        let rating_2_UI: any;
        let rating_3_UI: any;
        let rating_4_UI: any;

        let risk_1_options = [
            { id: '39', caption: 'Very Low', icon: 'red' },
            { id: '40', caption: 'Low', icon: 'lightRed' },
            { id: '41', caption: 'Medium', icon: 'yellow' },
            { id: '42', caption: 'High', icon: 'lightGreen' },
            { id: '43', caption: 'Very High', icon: 'green' },
        ];

        let risk_2_options = [
            { id: '54', caption: 'Very Low' },
            { id: '55', caption: 'Low' },
            { id: '56', caption: 'Medium' },
            { id: '57', caption: 'High' },
            { id: '58', caption: 'Very High' },
        ];

        let risk_3_options = [
            { id: '71', caption: 'Very Low' },
            { id: '72', caption: 'Low' },
            { id: '73', caption: 'Medium' },
            { id: '74', caption: 'High' },
            { id: '75', caption: 'Very High' },
        ];

        let risk_4_options = [
            { id: '86', caption: 'Very Low' },
            { id: '87', caption: 'Low' },
            { id: '88', caption: 'Medium' },
            { id: '89', caption: 'High' },
            { id: '90', caption: 'Very High' },
        ];

        const shfdata: any = await this.crudService.getAllSHFData(jobId, jobAllocationId).toPromise();
        console.log('service shfdata', shfdata[0])

        if (shfdata.length > 0) {
            siteHazardInfo = shfdata[0];

            statusUI = siteHazardInfo.status;
            //console.log('siteHazardInfo', siteHazardInfo);
            if (siteHazardInfo.criteria_1 != null) {
                for (const criteria of siteHazardInfo.criteria_1.split(',')) {
                    if (criteria !== '' && criteria !== null && criteria !== 'undefined') {
                        const criteriaDetails = criteria_1_options.filter(val => criteria === val.id)[0];
                        selectedCriteria_1.push(criteriaDetails);
                    }
                }
            }
            if (siteHazardInfo.criteria_2 != null) {
                for (const criteria of siteHazardInfo.criteria_2.split(',')) {
                    if (criteria !== '' && criteria !== null && criteria !== 'undefined') {
                        const criteriaDetails = criteria_2_options.filter(val => criteria === val.id)[0];
                        selectedCriteria_2.push(criteriaDetails);
                    }
                }
            }
            if (siteHazardInfo.criteria_3 != null) {
                for (const criteria of siteHazardInfo.criteria_3.split(',')) {
                    if (criteria !== '' && criteria !== null && criteria !== 'undefined') {
                        const criteriaDetails = criteria_3_options.filter(val => criteria === val.id)[0];
                        selectedCriteria_3.push(criteriaDetails);
                    }
                }
            }

            if (siteHazardInfo.criteria_4 != null) {
                for (const criteria of siteHazardInfo.criteria_4.split(',')) {
                    if (criteria !== '' && criteria !== null && criteria !== 'undefined') {
                        const criteriaDetails = criteria_4_options.filter(val => criteria === val.id)[0];
                        selectedCriteria_4.push(criteriaDetails);
                    }
                }
            }
            if (siteHazardInfo.control_1 != null) {
                for (const control of siteHazardInfo.control_1.split(',')) {
                    if (control !== '' && control !== null && control !== 'undefined') {
                        const controlDetails = control_1_options.filter(val => control === val.id)[0];
                        selectedControls_1.push(controlDetails);
                    }
                }
            }

            if (siteHazardInfo.control_2 != null) {
                for (const control of siteHazardInfo.control_2.split(',')) {
                    if (control !== '' && control !== null && control !== 'undefined') {
                        const controlDetails = control_2_options.filter(val => control === val.id)[0];
                        selectedControls_2.push(controlDetails);
                    }
                }
            }

            if (siteHazardInfo.control_3 != null) {
                for (const control of siteHazardInfo.control_3.split(',')) {
                    if (control !== '' && control !== null && control !== 'undefined') {
                        const controlDetails = control_3_options.filter(val => control === val.id)[0];
                        selectedControls_3.push(controlDetails);
                    }
                }
            }
            if (siteHazardInfo.control_4 != null) {
                for (const control of siteHazardInfo.control_4.split(',')) {
                    if (control !== '' && control !== null && control !== 'undefined') {
                        const controlDetails = control_4_options.filter(val => control === val.id)[0];
                        selectedControls_4.push(controlDetails);
                    }
                }
            }
            console.log('siteHazardInfo.risk_rating_1',siteHazardInfo.risk_rating_1)
            if (siteHazardInfo.risk_rating_1) {
                selectedRisk_1 = risk_1_options.filter(val => siteHazardInfo.risk_rating_1 === val.id)[0];
                console.log('risk_1_options',risk_1_options)
                console.log('selectedRisk_1',selectedRisk_1)
            }
            if (siteHazardInfo.risk_rating_2) {
                selectedRisk_2 = risk_2_options.filter(val => siteHazardInfo.risk_rating_2 === val.id)[0];
            }
            if (siteHazardInfo.risk_rating_3) {
                selectedRisk_3 = risk_3_options.filter(val => siteHazardInfo.risk_rating_3 === val.id)[0];
            }
            if (siteHazardInfo.risk_rating_4) {
                selectedRisk_4 = risk_4_options.filter(val => siteHazardInfo.risk_rating_4 === val.id)[0];
            }
            selectedCompUser = { id: siteHazardInfo.completed_by };
            siteHazardInfo.completion_time = new Date(siteHazardInfo.completion_time);

            for (const user of siteHazardInfo.other_staff_details.split(',')) {
                selectedUsers.push({ id: user });
            }

            let criteria_1_UI: any[];
            if (selectedCriteria_1.length > 0) {
                const users = selectedCriteria_1.map(function (item) {
                    return item.caption;
                });
                criteria_1_UI = users;
            } else {
                criteria_1_UI = null;
            }

            let criteria_2_UI: any[];
            if (selectedCriteria_2.length > 0) {
                const users = selectedCriteria_2.map(function (item) {
                    return item.caption;
                });
                criteria_2_UI = users;
            } else {
                criteria_2_UI = null;
            }

            let criteria_3_UI: any[];
            if (selectedCriteria_3.length > 0) {
                const users = selectedCriteria_3.map(function (item) {
                    return item.caption;
                });
                criteria_3_UI = users;
            } else {
                criteria_3_UI = null;
            }

            let criteria_4_UI: any[];
            if (selectedCriteria_4.length > 0) {
                const users = selectedCriteria_4.map(function (item) {
                    return item.caption;
                });
                criteria_4_UI = users;
            } else {
                criteria_4_UI = null;
            }

            let control_1_UI: any[];
            if (selectedControls_1.length > 0) {
                const users = selectedControls_1.map(function (item) {
                    return item.caption;
                });
                control_1_UI = users;
            } else {
                control_1_UI = null;
            }

            let control_2_UI: any[];
            if (selectedControls_2.length > 0) {
                const users = selectedControls_2.map(function (item) {
                    return item.caption;
                });
                control_2_UI = users;
            } else {
                control_2_UI = null;
            }

            let control_3_UI: any[];
            if (selectedControls_3.length > 0) {
                const users = selectedControls_3.map(function (item) {
                    return item.caption;
                });
                control_3_UI = users;
            } else {
                control_3_UI = null;
            }

            let control_4_UI: any[];
            if (selectedControls_4.length > 0) {
                const users = selectedControls_4.map(function (item) {
                    return item?.caption;
                });
                control_4_UI = users;
            } else {
                control_4_UI = null;
            }

            if (siteHazardInfo.risk_rating_1) {
                rating_1_UI = risk_1_options.filter(val => siteHazardInfo.risk_rating_1 === val.id)[0];
                item.push({ id: 1, hazard: criteria_1_UI, rating: rating_1_UI.caption, control: control_1_UI });
            } else {
                item.push({ id: 1, hazard: criteria_1_UI, rating: '', control: control_1_UI });
            }
            if (siteHazardInfo.risk_rating_2) {
                rating_2_UI = risk_2_options.filter(val => siteHazardInfo.risk_rating_2 === val.id)[0];
                item.push({ id: 1, hazard: criteria_2_UI, rating: rating_2_UI.caption, control: control_2_UI });
            } else {
                item.push({ id: 1, hazard: criteria_2_UI, rating: '', control: control_2_UI });
            }
            if (siteHazardInfo.risk_rating_3) {
                rating_3_UI = risk_3_options.filter(val => siteHazardInfo.risk_rating_3 === val.id)[0];
                item.push({ id: 1, hazard: criteria_3_UI, rating: rating_3_UI.caption, control: control_3_UI });
            } else {
                item.push({ id: 1, hazard: criteria_3_UI, rating: '', control: control_3_UI });
            }
            if (siteHazardInfo.risk_rating_4) {
                rating_4_UI = risk_4_options.filter(val => siteHazardInfo.risk_rating_4 === val.id)[0];
                item.push({ id: 1, hazard: criteria_4_UI, rating: rating_4_UI.caption, control: control_4_UI });
            } else {
                item.push({ id: 1, hazard: criteria_4_UI, rating: '', control: control_4_UI });
            }

            let prework_yn = null;
            let accidents_yn = null;
            let preworkValue = null;
            let accidentValue = null;
            if (prework_yn === '1') {
                preworkValue = 'Yes';
            } else {
                preworkValue = 'No';
            }
            if (accidents_yn === '1') {
                accidentValue = 'No';
            } else {
                accidentValue = 'Yes';
            }

            data = [
                {
                    info: {
                        id: '67993bdd-f7d9-48a6-93d9-8026b657041a',
                        jobid: 1,
                        reportid: reportId,
                        accident: accidentValue,
                        prework: preworkValue

                    },
                    company: {
                        job_num: jobAllocationDetails.JobNo,
                        contractor: 'TEST COMPANY - Enviro Tree Service'
                    },
                    items: [
                        {
                            id: 1,
                            detail: 'Site Hazard Conditions Evaluation',
                            detail2: 'Site Safety Conditions Evaluation',
                            item: item
                        }
                    ],
                }
            ];



            if (selectedCriteria_1 != null) {
                let desc = '';
                for (const Criteria_1 of selectedCriteria_1) {
                    if (desc !== null && desc !== '') { desc = desc + ', ' + Criteria_1.caption; }
                    else { desc = Criteria_1.caption; }
                } selectedCriteria_1_desc = desc;
            }
            if (selectedCriteria_2 != null) {
                let desc = '';
                for (const Criteria_2 of selectedCriteria_2) {
                    if (desc !== null && desc !== '') { desc = desc + ', ' + Criteria_2.caption; }
                    else { desc = Criteria_2.caption; }
                } selectedCriteria_2_desc = desc;
            }
            if (selectedCriteria_3 != null) {
                let desc = '';
                for (const Criteria_3 of selectedCriteria_3) {
                    if (desc !== null && desc !== '') { desc = desc + ', ' + Criteria_3.caption; }
                    else { desc = Criteria_3.caption; }
                } selectedCriteria_3_desc = desc;
            } if (selectedCriteria_4 != null) {
                let desc = '';
                for (const Criteria_4 of selectedCriteria_4) {
                    if (desc !== null && desc !== '') { desc = desc + ', ' + Criteria_4.caption; }
                    else { desc = Criteria_4.caption; }
                }
                selectedCriteria_4_desc = desc;
            }

            if (selectedControls_1 != null) {
                let desc = '';
                for (const Control_1 of selectedControls_1) {
                    if (desc !== null && desc !== '') { desc = desc + ', ' + Control_1.caption; }
                    else { desc = Control_1.caption; }
                } selectedControls_1_desc = desc;
            }
            if (selectedControls_2 != null) {
                let desc = '';
                for (const Control_2 of selectedControls_2) {
                    if (desc !== null && desc !== '') { desc = desc + ', ' + Control_2.caption; }
                    else { desc = Control_2.caption; }
                } selectedControls_2_desc = desc;
            }
            if (selectedControls_3 != null) {
                let desc = '';
                for (const Control_3 of selectedControls_3) {
                    if (desc !== null && desc !== '') { desc = desc + ', ' + Control_3.caption; }
                    else { desc = Control_3.caption; }
                } selectedControls_3_desc = desc;
            }
            if (selectedControls_4 != null) {
                let desc = '';
                for (const Control_4 of selectedControls_4) {
                    if (desc !== null && desc !== '') { desc = desc + ', ' + Control_4?.caption; }
                    else { desc = Control_4?.caption; }
                } selectedControls_4_desc = desc;
            }
            const resultData: any = await this.crudService.getSHFReportData(jobAllocationId).toPromise();
            console.log('service resultData', resultData)

            if (resultData.length > 0) {

                let selectedRisk1;
                let  selectedRisk2;
                let  selectedRisk3;
                let  selectedRisk4;


                if(selectedRisk_1?.caption != undefined)
                {

                  selectedRisk1 = selectedRisk_1?.caption;
                }
                else{

                    selectedRisk1= "Very Low";

                }

                if(selectedRisk_2?.caption != undefined)
                {

                  selectedRisk2 = selectedRisk_2?.caption;
                }
                else{

                    selectedRisk2= "Very Low";

                }

                if(selectedRisk_3?.caption != undefined)
                {

                  selectedRisk3 = selectedRisk_3?.caption;
                }
                else{

                    selectedRisk3= "Very Low";

                }

                if(selectedRisk_4?.caption != undefined)
                {

                    selectedRisk4 = selectedRisk_4?.caption;
                }
                else{

                    selectedRisk4= "Very Low";

                }
                reportData = {
                    TEMPLATE_ID: resultData[0].TEMPLATE_ID, JobNo: resultData[0].JobNo, JobRefNo: resultData[0].JobRefNo,
                    InspectionDate: resultData[0].InspectionDate, CompletionDate: resultData[0].CompletionDate, ClientName: resultData[0].ClientName,
                    ContactNo: 'Test', CustomerDetails: resultData[0].CustomerDetails, Datetime: '18/12/2020 11AM', SiteAddress: resultData[0].SiteAddress,
                    WorkOrder: resultData[0].WorkOrderDetails, Feedback: resultData[0].EnviroAuditDetails,
                    ImageURL: 'C:/companyCompliances/' + resultData[0].WPCompanyId + '/company_logo.png',
                    SignExists: null, SIGN: resultData[0].SignaturePath, SWMSCompiledBy: resultData[0].SWMSCompiledBy, TPName: resultData[0].TPName,
                    criteria_1: selectedCriteria_1_desc, criteria_2: selectedCriteria_2_desc, criteria_3: selectedCriteria_3_desc, criteria_4: selectedCriteria_4_desc,
                    risk_1: selectedRisk1, risk_2: selectedRisk2, risk_3: selectedRisk3, risk_4: selectedRisk4,
                    control_1: selectedControls_1_desc, control_2: selectedControls_2_desc, control_3: selectedControls_3_desc, control_4: selectedControls_4_desc
                };
            };
            let res: any;
            const imageList: any[] = [];
            res = await this.crudService.getSignatureBySiteHazardId(siteHazardInfo.id).toPromise();
            let selectedUserssign: any[] = []
            debugger;
            for (const user of siteHazardInfo.other_staff_details.split(',')) {
                selectedUserssign.push({ id: user });
            }

            // let filteredUsers: any[]=[];
            // selectedUserssign.forEach(user => {
            //     const filtereddata = res.filter(val => user.id == val.user_id);
            //     filteredUsers.push(filtereddata);
            // })

            const filteredUsers = res.filter((item: any) => {
                return selectedUserssign.some((user: any) => {
                    return item.user_id === user.id;
                });
            });
            filteredUsers.forEach(sign => {
                imageList.push({
                    image: sign.StaffName,
                    comments: 'All qualifications and skill certificates required to perform the work are upto date',
                    remarks: 'remarks' + sign.id,
                    SignURL: 'C://uploadImgs//' + sign.signature
                });
            });
            console.log('imageListtt', imageList)
            console.log('service reportDataaa', reportData)
            data[0].newReportData = reportData;
            data[0].imageList = imageList;
            return data;
        }

    }

    async setSHFDataOld(jobId, jobAllocationId, reportId, jobAllocationDetails): Promise<any> {

        const item = [];
        const k = 0;
        const scores = [];
        let data: any;
        let siteHazardInfo: any;
        let selectedCriteria_1: any[] = [];
        let selectedCriteria_2: any[] = [];
        let selectedCriteria_3: any[] = [];
        let selectedCriteria_4: any[] = [];
        let selectedControls_1: any[] = [];
        let selectedControls_2: any[] = [];
        let selectedControls_3: any[] = [];
        let selectedControls_4: any[] = [];
        let selectedRisk_1: any;
        let selectedRisk_2: any;
        let selectedRisk_3: any;
        let selectedRisk_4: any;
        let selectedCompUser: any;
        const selectedUsers: any[] = [];
        let statusUI: any;
        let selectedCriteria_1_desc: any;
        let selectedCriteria_2_desc: any;
        let selectedCriteria_3_desc: any;
        let selectedCriteria_4_desc: any;
        let selectedControls_1_desc: any;
        let selectedControls_2_desc: any;
        let selectedControls_3_desc: any;
        let selectedControls_4_desc: any;
        let reportData: any;


        let criteria_name_1 = 'Weather Hazard';
        let criteria_name_2 = 'Job Site Hazards';
        let criteria_name_3 = 'Tree Hazards';
        let criteria_name_4 = 'Manual Tasks Hazard';
        // criteria_name_5 = 'PPE (Personal Protective Equipment/Clothing)';
        let criteria_1_options = [
            { id: '34', value: 'Rain' },
            { id: '35', value: 'Overcast' },
            { id: '36', value: 'Lightning Storm' },
            { id: '37', value: 'High Wind' },
            { id: '38', value: 'Very Hot' }];

        let control_1_options = [
            { id: '44', value: 'PPE' },
            { id: '45', value: 'WHS' },
            { id: '46', value: 'Hydration' },
            { id: '47', value: 'Sun Cream' },
        ];

        let risk_1_options = [
            { id: '39', value: 'Very Low', icon: 'red' },
            { id: '40', value: 'Low', icon: 'lightRed' },
            { id: '41', value: 'Medium', icon: 'yellow' },
            { id: '42', value: 'High', icon: 'lightGreen' },
            { id: '43', value: 'Very High', icon: 'green' },
        ];

        let risk_2_options = [
            { id: '54', value: 'Very Low' },
            { id: '55', value: 'Low' },
            { id: '56', value: 'Medium' },
            { id: '57', value: 'High' },
            { id: '58', value: 'Very High' },
        ];

        let risk_3_options = [
            { id: '71', value: 'Very Low' },
            { id: '72', value: 'Low' },
            { id: '73', value: 'Medium' },
            { id: '74', value: 'High' },
            { id: '75', value: 'Very High' },
        ];

        let risk_4_options = [
            { id: '86', value: 'Very Low' },
            { id: '87', value: 'Low' },
            { id: '88', value: 'Medium' },
            { id: '89', value: 'High' },
            { id: '90', value: 'Very High' },
        ];

        let criteria_3_options = [
            { id: '65', value: 'Deadwood' },
            { id: '66', value: 'Hollow Trunk' },
            { id: '67', value: 'Hangers' },
            { id: '68', value: 'Storm Damage' },
            { id: '69', value: 'Cavities' },
            { id: '70', value: 'Unbalanced Crown' },
        ];
        let criteria_4_options = [
            { id: '80', value: 'Trees/Slips' },
            { id: '81', value: 'Lifting' },
            { id: '82', value: 'Falls From Heights' },
            { id: '83', value: 'Crush Injuries' },
            { id: '84', value: 'Fatigue' },
        ]
        let control_2_options = [
            { id: '59', value: 'PPE' },
            { id: '60', value: 'Signs/Cones' },
            { id: '61', value: 'Site Awareness' },
            { id: '64', value: 'Isolated Hazards' },
        ];
        let criteria_2_options = [
            { id: '48', value: 'Slippery Surfaces' },
            { id: '49', value: 'Falling Debris' },
            { id: '50', value: 'Traffic' },
            { id: '51', value: 'Drains/Pipes' },
            { id: '52', value: 'Loose Surfaces' },
            { id: '53', value: 'Electricity' }
        ];

        let control_3_options = [
            { id: '76', value: 'Rigging' },
            { id: '77', value: 'Lowering Device' },
            { id: '78', value: 'Climbing Equipment' },
            { id: '79', value: 'Plant Use' }
        ]

        let control_4_options = [
            { id: '91', value: 'PPE' },
            { id: '92', value: 'Mechanical Aids' },
            { id: '93', value: 'Pre-Climbs Checks' },
            { id: '95', value: 'Handling Technique' },
            { id: '96', value: 'Aerial Rescue Kit' },
        ]




        let rating_1_UI: any;
        let rating_2_UI: any;
        let rating_3_UI: any;
        let rating_4_UI: any;
        const shfdata: any = await this.crudService.getAllSHFData(jobId, jobAllocationId).toPromise();
        if (shfdata.length > 0) {
            siteHazardInfo = shfdata[0];

            statusUI = siteHazardInfo.status;
            //console.log('siteHazardInfo', siteHazardInfo);
            if (siteHazardInfo.criteria_1 != null) {
                for (const criteria of siteHazardInfo.criteria_1.split(',')) {
                    if (criteria !== '' && criteria !== null && criteria !== 'undefined') {
                        const criteriaDetails = criteria_1_options.filter(val => criteria === val.id)[0];
                        selectedCriteria_1.push(criteriaDetails);
                    }
                }
            }
            if (siteHazardInfo.criteria_2 != null) {
                for (const criteria of siteHazardInfo.criteria_2.split(',')) {
                    if (criteria !== '' && criteria !== null && criteria !== 'undefined') {
                        const criteriaDetails = criteria_2_options.filter(val => criteria === val.id)[0];
                        selectedCriteria_2.push(criteriaDetails);
                    }
                }
            }
            if (siteHazardInfo.criteria_3 != null) {
                for (const criteria of siteHazardInfo.criteria_3.split(',')) {
                    if (criteria !== '' && criteria !== null && criteria !== 'undefined') {
                        const criteriaDetails = criteria_3_options.filter(val => criteria === val.id)[0];
                        selectedCriteria_3.push(criteriaDetails);
                    }
                }
            }

            if (siteHazardInfo.criteria_4 != null) {
                for (const criteria of siteHazardInfo.criteria_4.split(',')) {
                    if (criteria !== '' && criteria !== null && criteria !== 'undefined') {
                        const criteriaDetails = criteria_4_options.filter(val => criteria === val.id)[0];
                        selectedCriteria_4.push(criteriaDetails);
                    }
                }
            }
            if (siteHazardInfo.control_1 != null) {
                for (const control of siteHazardInfo.control_1.split(',')) {
                    if (control !== '' && control !== null && control !== 'undefined') {
                        const controlDetails = control_1_options.filter(val => control === val.id)[0];
                        selectedControls_1.push(controlDetails);
                    }
                }
            }

            if (siteHazardInfo.control_2 != null) {
                for (const control of siteHazardInfo.control_2.split(',')) {
                    if (control !== '' && control !== null && control !== 'undefined') {
                        const controlDetails = control_2_options.filter(val => control === val.id)[0];
                        selectedControls_2.push(controlDetails);
                    }
                }
            }

            if (siteHazardInfo.control_3 != null) {
                for (const control of siteHazardInfo.control_3.split(',')) {
                    if (control !== '' && control !== null && control !== 'undefined') {
                        const controlDetails = control_3_options.filter(val => control === val.id)[0];
                        selectedControls_3.push(controlDetails);
                    }
                }
            }
            if (siteHazardInfo.control_4 != null) {
                for (const control of siteHazardInfo.control_4.split(',')) {
                    if (control !== '' && control !== null && control !== 'undefined') {
                        const controlDetails = control_4_options.filter(val => control === val.id)[0];
                        selectedControls_4.push(controlDetails);
                    }
                }
            }
            if (siteHazardInfo.risk_rating_1) {
                selectedRisk_1 = risk_1_options.filter(val => siteHazardInfo.risk_rating_1 === val.id)[0];
            }
            if (siteHazardInfo.risk_rating_2) {
                selectedRisk_2 = risk_2_options.filter(val => siteHazardInfo.risk_rating_2 === val.id)[0];
            }
            if (siteHazardInfo.risk_rating_3) {
                selectedRisk_3 = risk_3_options.filter(val => siteHazardInfo.risk_rating_3 === val.id)[0];
            }
            if (siteHazardInfo.risk_rating_4) {
                selectedRisk_4 = risk_4_options.filter(val => siteHazardInfo.risk_rating_4 === val.id)[0];
            }
            selectedCompUser = { id: siteHazardInfo.completed_by };
            siteHazardInfo.completion_time = new Date(siteHazardInfo.completion_time);

            for (const user of siteHazardInfo.other_staff_details.split(',')) {
                selectedUsers.push({ id: user });
            }

            let criteria_1_UI: any[];
            if (selectedCriteria_1.length > 0) {
                const users = selectedCriteria_1.map(function (item) {
                    return item.value;
                });
                criteria_1_UI = users;
            } else {
                criteria_1_UI = null;
            }

            let criteria_2_UI: any[];
            if (selectedCriteria_2.length > 0) {
                const users = selectedCriteria_2.map(function (item) {
                    return item.value;
                });
                criteria_2_UI = users;
            } else {
                criteria_2_UI = null;
            }

            let criteria_3_UI: any[];
            if (selectedCriteria_3.length > 0) {
                const users = selectedCriteria_3.map(function (item) {
                    return item.value;
                });
                criteria_3_UI = users;
            } else {
                criteria_3_UI = null;
            }

            let criteria_4_UI: any[];
            if (selectedCriteria_4.length > 0) {
                const users = selectedCriteria_4.map(function (item) {
                    return item.value;
                });
                criteria_4_UI = users;
            } else {
                criteria_4_UI = null;
            }

            let control_1_UI: any[];
            if (selectedControls_1.length > 0) {
                const users = selectedControls_1.map(function (item) {
                    return item.value;
                });
                control_1_UI = users;
            } else {
                control_1_UI = null;
            }

            let control_2_UI: any[];
            if (selectedControls_2.length > 0) {
                const users = selectedControls_2.map(function (item) {
                    return item.value;
                });
                control_2_UI = users;
            } else {
                control_2_UI = null;
            }

            let control_3_UI: any[];
            if (selectedControls_3.length > 0) {
                const users = selectedControls_3.map(function (item) {
                    return item.value;
                });
                control_3_UI = users;
            } else {
                control_3_UI = null;
            }

            let control_4_UI: any[];
            if (selectedControls_4.length > 0) {
                const users = selectedControls_4.map(function (item) {
                    return item.value;
                });
                control_4_UI = users;
            } else {
                control_4_UI = null;
            }

            if (siteHazardInfo.risk_rating_1) {
                rating_1_UI = risk_1_options.filter(val => siteHazardInfo.risk_rating_1 === val.id)[0];
                item.push({ id: 1, hazard: criteria_1_UI, rating: rating_1_UI.value, control: control_1_UI });
            } else {
                item.push({ id: 1, hazard: criteria_1_UI, rating: '', control: control_1_UI });
            }
            if (siteHazardInfo.risk_rating_2) {
                rating_2_UI = risk_2_options.filter(val => siteHazardInfo.risk_rating_2 === val.id)[0];
                item.push({ id: 1, hazard: criteria_2_UI, rating: rating_2_UI.value, control: control_2_UI });
            } else {
                item.push({ id: 1, hazard: criteria_2_UI, rating: '', control: control_2_UI });
            }
            if (siteHazardInfo.risk_rating_3) {
                rating_3_UI = risk_3_options.filter(val => siteHazardInfo.risk_rating_3 === val.id)[0];
                item.push({ id: 1, hazard: criteria_3_UI, rating: rating_3_UI.value, control: control_3_UI });
            } else {
                item.push({ id: 1, hazard: criteria_3_UI, rating: '', control: control_3_UI });
            }
            if (siteHazardInfo.risk_rating_4) {
                rating_4_UI = risk_4_options.filter(val => siteHazardInfo.risk_rating_4 === val.id)[0];
                item.push({ id: 1, hazard: criteria_4_UI, rating: rating_4_UI.value, control: control_4_UI });
            } else {
                item.push({ id: 1, hazard: criteria_4_UI, rating: '', control: control_4_UI });
            }

            let prework_yn = null;
            let accidents_yn = null;
            let preworkValue = null;
            let accidentValue = null;
            if (prework_yn === '1') {
                preworkValue = 'Yes';
            } else {
                preworkValue = 'No';
            }
            if (accidents_yn === '1') {
                accidentValue = 'No';
            } else {
                accidentValue = 'Yes';
            }

            data = [
                {
                    info: {
                        id: '67993bdd-f7d9-48a6-93d9-8026b657041a',
                        jobid: 1,
                        reportid: reportId,
                        accident: accidentValue,
                        prework: preworkValue

                    },
                    company: {
                        job_num: jobAllocationDetails.JobNo,
                        contractor: 'TEST COMPANY - Enviro Tree Service'
                    },
                    items: [
                        {
                            id: 1,
                            detail: 'Site Hazard Conditions Evaluation',
                            detail2: 'Site Safety Conditions Evaluation',
                            item: item
                        }
                    ],
                }
            ];



            if (selectedCriteria_1 != null) {
                let desc = '';
                for (const Criteria_1 of selectedCriteria_1) {
                    if (desc !== null && desc !== '') { desc = desc + ', ' + Criteria_1.value; }
                    else { desc = Criteria_1.value; }
                } selectedCriteria_1_desc = desc;
            }
            if (selectedCriteria_2 != null) {
                let desc = '';
                for (const Criteria_2 of selectedCriteria_2) {
                    if (desc !== null && desc !== '') { desc = desc + ', ' + Criteria_2.value; }
                    else { desc = Criteria_2.value; }
                } selectedCriteria_2_desc = desc;
            }
            if (selectedCriteria_3 != null) {
                let desc = '';
                for (const Criteria_3 of selectedCriteria_3) {
                    if (desc !== null && desc !== '') { desc = desc + ', ' + Criteria_3.value; }
                    else { desc = Criteria_3.value; }
                } selectedCriteria_3_desc = desc;
            } if (selectedCriteria_4 != null) {
                let desc = '';
                for (const Criteria_4 of selectedCriteria_4) {
                    if (desc !== null && desc !== '') { desc = desc + ', ' + Criteria_4.value; }
                    else { desc = Criteria_4.value; }
                }
                selectedCriteria_4_desc = desc;
            }

            if (selectedControls_1 != null) {
                let desc = '';
                for (const Control_1 of selectedControls_1) {
                    if (desc !== null && desc !== '') { desc = desc + ', ' + Control_1.value; }
                    else { desc = Control_1.value; }
                } selectedControls_1_desc = desc;
            }
            if (selectedControls_2 != null) {
                let desc = '';
                for (const Control_2 of selectedControls_2) {
                    if (desc !== null && desc !== '') { desc = desc + ', ' + Control_2.value; }
                    else { desc = Control_2.value; }
                } selectedControls_2_desc = desc;
            }
            if (selectedControls_3 != null) {
                let desc = '';
                for (const Control_3 of selectedControls_3) {
                    if (desc !== null && desc !== '') { desc = desc + ', ' + Control_3.value; }
                    else { desc = Control_3.value; }
                } selectedControls_3_desc = desc;
            }
            if (selectedControls_4 != null) {
                let desc = '';
                for (const Control_4 of selectedControls_4) {
                    if (desc !== null && desc !== '') { desc = desc + ', ' + Control_4.value; }
                    else { desc = Control_4.value; }
                } selectedControls_4_desc = desc;
            }
            const resultData: any = await this.crudService.getSHFReportData(jobAllocationId).toPromise();
            if (resultData.length > 0) {
                reportData = {
                    TEMPLATE_ID: resultData[0].TEMPLATE_ID, JobNo: resultData[0].JobNo, JobRefNo: resultData[0].JobRefNo,
                    InspectionDate: resultData[0].InspectionDate, CompletionDate: resultData[0].CompletionDate, ClientName: resultData[0].ClientName,
                    ContactNo: 'Test', CustomerDetails: resultData[0].CustomerDetails, Datetime: '18/12/2020 11AM', SiteAddress: resultData[0].SiteAddress,
                    WorkOrder: resultData[0].WorkOrderDetails, Feedback: resultData[0].EnviroAuditDetails,
                    ImageURL: 'C:/companyCompliances/' + resultData[0].WPCompanyId + '/company_logo.png',
                    SignExists: null, SIGN: resultData[0].SignaturePath, SWMSCompiledBy: resultData[0].SWMSCompiledBy, TPName: resultData[0].TPName,
                    criteria_1: selectedCriteria_1_desc, criteria_2: selectedCriteria_2_desc, criteria_3: selectedCriteria_3_desc, criteria_4: selectedCriteria_4_desc,
                    risk_1: selectedRisk_1.value, risk_2: selectedRisk_2.value, risk_3: selectedRisk_3.value, risk_4: selectedRisk_4.value,
                    control_1: selectedControls_1_desc, control_2: selectedControls_2_desc, control_3: selectedControls_3_desc, control_4: selectedControls_4_desc
                };
            };
            let res: any;
            const imageList: any[] = [];
            res = await this.crudService.getSignatureBySiteHazardId(siteHazardInfo.id).toPromise();
            res.forEach(sign => {
                imageList.push({
                    image: sign.StaffName,
                    comments: 'All qualifications and skill certificates required to perform the work are upto date',
                    remarks: 'remarks' + sign.id,
                    SignURL: 'C://uploadImgs//' + sign.signature
                });
            });

            data[0].newReportData = reportData;
            data[0].imageList = imageList;
            return data;
        }
    }

    async generateWpInvoicePdf(jobId, jobAllocationId, jobAllocationResults): Promise<any[]> {
        //console.log('WPInvoice');
        let pdfData: any;
        //let reportId = 12;
        let display_exists = false;
        let mainItems = [];
        let reportid = '12';
        let quote_date: any;
        let quote_no: any;
        let Id: any;
        let cost_refno: any;
        let jobAllocId: any;
        let cost_date: any;
        let subtotal: number;
        let taxrate: any;
        let grandtotal: number;
        let tax: any;
        let wpgrandtotal: any;
        let wptaxtotal: any;
        let wpsubtotal: any;
        let jobManager: any;
        let jobContact: any;
        let jobNotes: any;
        let jobSubstantiation: any;
        let processId: any;
        let wpPmNotes: any;
        let wpPmSubstantiation: any;
        let tp_invoice_no: any;
        let wp_invoice_no: any;
        let wp_inv_dt: any;
        let rebate_total: number;

        const data: any = await this.crudService.getCostFormHeadByJobIdAllocId(jobId, jobAllocationId).toPromise();
        //console.log('generateWpInvoicePdf' + data[0].wp_inv_dt);
        const costFormHead = data[0];
        if (data.length > 0) {
            if ((data[0].display_no === 'undefined') || (data[0].display_no === null) || (data[0].display_no === 'NULL')) {
                display_exists = false;
            } else {
                display_exists = true;
            }
            const costHeadId = data[0].id;
            quote_date = data[0].quote_date;
            quote_no = data[0].quote_no;
            Id = data[0].job_id;
            cost_refno = data[0].cost_refno;
            jobAllocId = data[0].job_alloc_id;
            cost_date = data[0].date;
            subtotal = data[0].sub_total.toFixed(2);
            taxrate = data[0].tax_rate;
            grandtotal = data[0].tp_grand_total.toFixed(2);
            tax = parseFloat(data[0].tp_tax_total).toFixed(2);
            wpgrandtotal = parseFloat(data[0].wp_grand_total).toFixed(2);
            wptaxtotal = parseFloat(data[0].wp_tax_total).toFixed(2);
            wpsubtotal = parseFloat(data[0].wp_sub_total).toFixed(2);
            jobManager = data[0].job_manager;
            jobContact = data[0].job_contact;
            jobNotes = data[0].job_notes;
            jobSubstantiation = data[0].tp_job_substantiation;
            processId = data[0].process_id;
            wpPmNotes = data[0].wp_pm_notes;
            wpPmSubstantiation = data[0].wp_pm_substantiation;
            tp_invoice_no = data[0].tp_invoice_no;
            wp_invoice_no = data[0].wp_invoice_no ? data[0].wp_invoice_no : null;
         //   wp_inv_dt = data[0].wp_inv_dt ? new Date(data[0].wp_inv_dt).toISOString(): null;
          
         //  wp_inv_dt = data[0].wp_inv_dt ? moment(new Date(data[0].wp_inv_dt)).format('DD/MM/YYYY hh:mm a') : null;
            debugger // moment(new Date(data[0].wp_inv_dt)).format('DD/MM/YYYY hh:mm a') : null;
             if (data[0].WP_inv_dt2 != null) {
                    wp_inv_dt = data[0].WP_inv_dt2;
                } else {
                    wp_inv_dt = null;
                }
            rebate_total = data[0].rebate_total ? data[0].rebate_total : 0;

            if (jobAllocationResults.info.WPCompanyId === '1268') {
                const data2: any = await this.crudService.getCostFormDetailByJobIdAllocIdForAllWpQuote(jobId, jobAllocationId).toPromise();
                if (data2.length > 0) {
                    const selItem = null;
                    data2.forEach(main_item => {
                        let wp_desc = null;
                        if (main_item.item_rate === '10049') {
                            if ((main_item.wp_desc === null) || (main_item.wp_desc === 'undefined')) {
                                wp_desc = 'Normal Hours ';
                            } else {
                                wp_desc = 'Normal Hours - ' + main_item.wp_desc;
                            }
                        } else if (main_item.item_rate === '10090') {
                            if ((main_item.wp_desc === null) || (main_item.wp_desc === 'undefined')) {
                                wp_desc = 'Emergency After Hours';
                            } else {
                                wp_desc = 'Emergency After Hours - ' + main_item.wp_desc;
                            }
                        } else {
                            wp_desc = main_item.wp_desc;
                        }
                        if (main_item.quote_inc === '2') {
                            //replace item_rate with wp_rate
                            const mainItem = {
                                id: main_item.id,
                                headId: main_item.head_id,
                                selectedItem: selItem,
                                qty: parseInt(main_item.wp_qty),
                                price: parseFloat(main_item.wp_rate).toFixed(2),
                                hours: parseFloat(main_item.wp_hrs),
                                item_name: wp_desc,
                                cost_rate_select: main_item.item_rate,
                                entry_point: main_item.entry_point,
                                quote_inc: main_item.quote_inc,
                                total: parseFloat(main_item.wp_total).toFixed(2),
                                desc: main_item.item_name
                            }
                            mainItems.push(mainItem);
                        }
                    });
                }
            } else if (display_exists) {
                const data2: any = await this.crudService.getCostFormDetailByJobIdAllocIdForAllWpQuote(jobId, jobAllocationId).toPromise();
                mainItems = [];
                if (data2.length > 0) {
                    const selItem = null;
                    data2.forEach(main_item => {
                        let wp_desc = null;
                        if (main_item.item_rate === '10049') {
                            if ((main_item.wp_desc === null) || (main_item.wp_desc === 'undefined')) {
                                wp_desc = 'Normal Hours ';
                            } else {
                                wp_desc = 'Normal Hours - ' + main_item.wp_desc;
                            }
                        } else if (main_item.item_rate === '10090') {
                            if ((main_item.wp_desc === null) || (main_item.wp_desc === 'undefined')) {
                                wp_desc = 'Emergency After Hours';
                            } else {
                                wp_desc = 'Emergency After Hours - ' + main_item.wp_desc;
                            }
                        } else {
                            wp_desc = main_item.wp_desc;
                        }
                        if (main_item.quote_inc === '2') {
                            //replace item_rate with wp_rate
                            const mainItem = {
                                id: main_item.id,
                                headId: main_item.head_id,
                                selectedItem: selItem,
                                qty: parseInt(main_item.wp_qty),
                                price: parseFloat(main_item.wp_rate).toFixed(2),
                                hours: parseFloat(main_item.wp_hrs),
                                desc: wp_desc,
                                cost_rate_select: main_item.item_rate,
                                entry_point: main_item.entry_point,
                                quote_inc: main_item.quote_inc,
                                total: parseFloat(main_item.wp_total).toFixed(2),
                                item_name: main_item.item_name
                            }
                            mainItems.push(mainItem);
                        }
                    });
                }
            } else {
                const data2: any = await this.crudService.getCostFormDetailByJobIdAllocIdForAllWpQuote2(jobId, jobAllocationId).toPromise();
                mainItems = [];
                if (data2.length > 0) {
                    const selItem = null;
                    data2.forEach(main_item => {
                        let wp_desc = null;
                        if (main_item.item_rate === 10049) {
                            if ((main_item.wp_desc === null) || (main_item.wp_desc === 'undefined')) {
                                wp_desc = 'Normal Hours ';
                            } else {
                                wp_desc = 'Normal Hours - ' + main_item.wp_desc;
                            }
                        } else if (main_item.item_rate === 10090) {
                            if ((main_item.wp_desc === null) || (main_item.wp_desc === 'undefined')) {
                                wp_desc = 'Emergency After Hours';
                            } else {
                                wp_desc = 'Emergency After Hours - ' + main_item.wp_desc;
                            }
                        } else {
                            wp_desc = main_item.wp_desc;
                        }
                        if (main_item.quote_inc === '2') {
                            //replace item_rate with wp_rate
                            const mainItem = {
                                id: main_item.id,
                                headId: main_item.head_id,
                                selectedItem: selItem,
                                qty: parseInt(main_item.wp_qty),
                                price: parseFloat(main_item.wp_rate).toFixed(2),
                                hours: parseFloat(main_item.wp_hrs),
                                desc: wp_desc,
                                cost_rate_select: main_item.item_rate,
                                entry_point: main_item.entry_point,
                                quote_inc: main_item.quote_inc,
                                total: parseFloat(main_item.wp_total).toFixed(2),
                                item_name: main_item.item_name
                            }
                            mainItems.push(mainItem);
                        }
                    });
                }
            }
            if ((jobAllocationResults.info.WPCompanyId === '679') || (jobAllocationResults.info.WPCompanyId === '570') ||
                (jobAllocationResults.info.WPCompanyId === '580') || (jobAllocationResults.info.WPCompanyId === '125')) {
                reportid = '12_FM';
            }

            if ((jobAllocationResults.info.WPCompanyId === '219')) {
                reportid = '12_SE';
            }
            let total: any;
            if (jobAllocationResults.info.client_rebate > 0) {
                const rebate: number = parseFloat(jobAllocationResults.info.client_rebate);
                const rebateTotal: any = rebate_total ? rebate_total.toFixed(2) : 0;
                // subtotal = wpsubtotal;
                // const tax: number = (subtotal - rebateTotal) * (taxrate / 100);
                // grandtotal = subtotal - parseFloat(rebateTotal) + tax;
                //grandtotal = grandtotal.toFixed(2);

                const tempRebate: any = rebate_total;
                let subt: any = parseFloat(wpsubtotal) + parseFloat(tempRebate);

                total = {
                    // taxrate: parseFloat(taxrate).toFixed(2),
                    // sub_total: parseFloat(subt).toFixed(2),
                    // tax: parseFloat(wptaxtotal).toFixed(2),
                    // all_total: parseFloat(wpgrandtotal).toFixed(2),
                    // rebate: parseFloat(rebateTotal).toFixed(2),

                    taxrate : isNaN(parseFloat(taxrate)) ? "0.00" : parseFloat(taxrate).toFixed(2),
                    sub_total: isNaN(parseFloat(subt)) ? "0.00" : parseFloat(subt).toFixed(2),
                    tax: isNaN(parseFloat(wptaxtotal)) ? "0.00" : parseFloat(wptaxtotal).toFixed(2),
                    all_total: isNaN(parseFloat(wpgrandtotal)) ? "0.00" : parseFloat(wpgrandtotal).toFixed(2),
                    rebate: isNaN(parseFloat(rebateTotal)) ? "0.00" : parseFloat(rebateTotal).toFixed(2),
                };
            } else {
                total = {
                    // taxrate: taxrate,
                    // sub_total: wpsubtotal,
                    // tax: wptaxtotal,
                    // all_total: wpgrandtotal,

                    taxrate : isNaN(parseFloat(taxrate)) ? "0.00" : parseFloat(taxrate).toFixed(2),
                    sub_total: isNaN(parseFloat(wpsubtotal)) ? "0.00" : parseFloat(wpsubtotal).toFixed(2),
                    tax: isNaN(parseFloat(wptaxtotal)) ? "0.00" : parseFloat(wptaxtotal).toFixed(2),
                    all_total: isNaN(parseFloat(wpgrandtotal)) ? "0.00" : parseFloat(wpgrandtotal).toFixed(2),
                    rebate: '0',
                }
            }


            /*    let totalData = this.calculateRebate(jobAllocationResults); */
            pdfData = {
                info: {
                    id: '67993bdd-f7d9-48a6-93d9-8026b657041a',
                    jobid: jobId,
                    reportid: reportid,
                },
                invoice: {
                    tax_invoice: wp_invoice_no,
                    country: jobAllocationResults.EnvCountry,
                    add1: jobAllocationResults.EnvAddressLine1,
                    add2: jobAllocationResults.EnvAddressLine2,
                    city: jobAllocationResults.EnvState,
                    zip: jobAllocationResults.EnvZip,
                    fax: jobAllocationResults.EnvFax,
                    phone: jobAllocationResults.EnvPhone,
                    email: jobAllocationResults.invReplyTo,
                    website: jobAllocationResults.EnvWebsite,
                    name: jobAllocationResults.pdfInfo.enviroBankDetail.account_name,
                    account: jobAllocationResults.pdfInfo.enviroBankDetail.account_number,
                    bsb: jobAllocationResults.pdfInfo.enviroBankDetail.account_BSB,
                    abn: jobAllocationResults.pdfInfo.enviroBankDetail.business_ABN,
                    abnName: jobAllocationResults.EnvABNName,
                    date: wp_inv_dt,
                },
                job: {
                    no: jobAllocationResults.pdfInfo.siteInfo.job_no,
                    desc: 'Test Job',
                    claim_no: jobAllocationResults.pdfInfo.siteInfo.job_claim_no,
                    provider_claim: jobAllocationResults.pdfInfo.siteInfo.job_provider_ref,
                    site_address: jobAllocationResults.pdfInfo.siteInfo.address,
                },
                customer: {
                    name: jobAllocationResults.pdfInfo.siteInfo.job_contact_name,
                    company: jobAllocationResults.pdfInfo.siteInfo.business_name,
                    phone: jobAllocationResults.pdfInfo.siteInfo.phone,
                    fax: '1300077446',
                    abn: jobAllocationResults.pdfInfo.siteInfo.business_ABN,
                    inv_line_1: jobAllocationResults.inv_line_1, inv_line_2: jobAllocationResults.inv_line_2,
                    inv_line_3: jobAllocationResults.inv_line_3, inv_line_4: jobAllocationResults.inv_line_4
                },
                total: total,
                prodj_manager_substn: wpPmSubstantiation
            };
            const treeInfo = await this.crudService.getWorkOrderInfo(jobId, jobAllocationId).toPromise();
            const treeData = this.transformTreeInfo(treeInfo);
            pdfData.tree_info = treeData.tree_info;
            pdfData.damages = treeData.damages;


            pdfData.items = [];
            pdfData = [pdfData];
            pdfData = pdfData;
            console.log("pdfData", pdfData);
            return pdfData;
        }
    }


    async generateWpQuotePdf(jobId, jobAllocationId, jobAllocationResults): Promise<any[]> {
        let pdfData: any;
        const tempTime = new Date().getTime();
        let display_exists = false;
        let mainItems = [];
        let reportid = '6';
        let quote_date: any;
        let quote_no: any;
        let Id: any;
        let cost_refno: any;
        let jobAllocId: any;
        let cost_date: any;
        let subtotal: number;
        let taxrate: any;
        let grandtotal: number;
        let tax: any;
        let wpgrandtotal: any;
        let wptaxtotal: any;
        let wpsubtotal: any;
        let jobManager: any;
        let jobContact: any;
        let jobNotes: any;
        let jobSubstantiation: any;
        let processId: any;
        let wpPmNotes: any;
        let wpPmSubstantiation: any;
        let tp_invoice_no: any;
        let wp_invoice_no: any;
        let wp_inv_dt: any;
        let rebate_total: number;

        const data: any = await this.crudService.getCostFormHeadByJobIdAllocId(jobId, jobAllocationId).toPromise();
        const costFormHead = data[0];
        if (data.length > 0) {
            if ((data[0].display_no === 'undefined') || (data[0].display_no === null) || (data[0].display_no === 'NULL')) {
                display_exists = false;
            } else {
                display_exists = true;
            }
            debugger
            const costHeadId = data[0].id;
            quote_date = data[0].quote_date;
            quote_no = data[0].quote_no;
            jobId = data[0].job_id;
            cost_refno = data[0].cost_refno;
            jobAllocId = data[0].job_alloc_id;
            cost_date = data[0].date;
            subtotal = data[0].sub_total.toFixed(2);
            taxrate = data[0].tax_rate;
            wpgrandtotal = parseFloat(data[0].wp_grand_total).toFixed(2);
            wptaxtotal = parseFloat(data[0].wp_tax_total).toFixed(2);
            wpsubtotal = parseFloat(data[0].wp_sub_total).toFixed(2);
            jobManager = data[0].job_manager;
            jobContact = data[0].job_contact;
            // jobNotes = decodeURIComponent(data[0].job_notes);
            // jobSubstantiation = decodeURIComponent(data[0].tp_job_substantiation);
            jobNotes = data[0].job_notes;
            jobSubstantiation = data[0].tp_job_substantiation;
            processId = data[0].process_id;
            // wpPmNotes = decodeURIComponent(data[0].wp_pm_notes);
            // wpPmSubstantiation = decodeURIComponent(data[0].wp_pm_substantiation);
            wpPmNotes = data[0].wp_pm_notes;
            wpPmSubstantiation = data[0].wp_pm_substantiation;
            tp_invoice_no = data[0].tp_invoice_no;
            wp_invoice_no = data[0].wp_invoice_no ? data[0].wp_invoice_no : null;
            debugger
            wp_inv_dt = data[0].wp_inv_dt ? new Date(data[0].wp_inv_dt).toDateString : null;
            rebate_total = data[0].rebate_total ? data[0].rebate_total : 0;


            if (jobAllocationResults.info.WPCompanyId === 1268) {
                const data2: any = await this.crudService.getCostFormDetailByJobIdAllocIdForAllWpQuote(jobId, jobAllocationId).toPromise();
                if (data2.length > 0) {
                    const selItem = null;
                    data2.forEach(main_item => {
                        let wp_desc = null;
                        if (main_item.item_rate === 10049) {
                            if ((main_item.wp_desc === null) || (main_item.wp_desc === 'undefined')) {
                                wp_desc = 'Normal Hours ';
                            } else {
                                wp_desc = 'Normal Hours - ' + main_item.wp_desc;
                            }
                        } else if (main_item.item_rate === 10090) {
                            if ((main_item.wp_desc === null) || (main_item.wp_desc === 'undefined')) {
                                wp_desc = 'Emergency After Hours';
                            } else {
                                wp_desc = 'Emergency After Hours - ' + main_item.wp_desc;
                            }
                        } else {
                            wp_desc = main_item.wp_desc;
                        }
                        if (main_item.quote_inc === '2') {
                            //replace item_rate with wp_rate

                            const mainItem = {
                                id: main_item.id,
                                headId: main_item.head_id,
                                selectedItem: selItem,
                                qty: parseInt(main_item.wp_qty),
                                price: parseFloat(main_item.wp_rate).toFixed(2),
                                hours: parseFloat(main_item.wp_hrs),
                                item_name: wp_desc,
                                cost_rate_select: main_item.item_rate,
                                entry_point: main_item.entry_point,
                                quote_inc: main_item.quote_inc,
                                total: parseFloat(main_item.wp_total).toFixed(2),
                                desc: main_item.item_name
                            }
                            mainItems.push(mainItem);
                        }
                    });
                }
            } else if (display_exists) {
                const data2: any = await this.crudService.getCostFormDetailByJobIdAllocIdForAllWpQuote(jobId, jobAllocationId).toPromise();
                mainItems = [];
                if (data2.length > 0) {
                    const selItem = null;
                    data2.forEach(main_item => {
                        let wp_desc = null;
                        if (main_item.item_rate === 10049) {
                            if ((main_item.wp_desc === null) || (main_item.wp_desc === 'undefined')) {
                                wp_desc = 'Normal Hours ';
                            } else {
                                wp_desc = 'Normal Hours - ' + main_item.wp_desc;
                            }
                        } else if (main_item.item_rate === 10090) {
                            if ((main_item.wp_desc === null) || (main_item.wp_desc === 'undefined')) {
                                wp_desc = 'Emergency After Hours';
                            } else {
                                wp_desc = 'Emergency After Hours - ' + main_item.wp_desc;
                            }
                        } else {
                            wp_desc = main_item.wp_desc;
                        }
                        if (main_item.quote_inc === '2') {
                            //replace item_rate with wp_rate
                            const mainItem = {
                                id: main_item.id,
                                headId: main_item.head_id,
                                selectedItem: selItem,
                                qty: parseInt(main_item.wp_qty),
                                price: parseFloat(main_item.wp_rate).toFixed(2),
                                hours: parseFloat(main_item.wp_hrs),
                                desc: wp_desc,
                                cost_rate_select: main_item.item_rate,
                                entry_point: main_item.entry_point,
                                quote_inc: main_item.quote_inc,
                                wp_rate: main_item.wp_rate,
                                wp_hrs: main_item.wp_hrs,
                                wp_qty: main_item.wp_qty,
                                wp_total: main_item.wp_total,
                                total: parseFloat(main_item.wp_total).toFixed(2),
                                item_name: main_item.item_name
                            }
                            mainItems.push(mainItem);
                        }
                    });
                }
            } else {
                const data2: any = await this.crudService.getCostFormDetailByJobIdAllocIdForAllWpQuote2(jobId, jobAllocationId).toPromise();
                mainItems = [];
                if (data2.length > 0) {
                    const selItem = null;
                    data2.forEach(main_item => {
                        let wp_desc = null;
                        if (main_item.item_rate === 10049) {
                            if ((main_item.wp_desc === null) || (main_item.wp_desc === 'undefined')) {
                                wp_desc = 'Normal Hours ';
                            } else {
                                wp_desc = 'Normal Hours - ' + main_item.wp_desc;
                            }
                        } else if (main_item.item_rate === 10090) {
                            if ((main_item.wp_desc === null) || (main_item.wp_desc === 'undefined')) {
                                wp_desc = 'Emergency After Hours';
                            } else {
                                wp_desc = 'Emergency After Hours - ' + main_item.wp_desc;
                            }
                        } else {
                            wp_desc = main_item.wp_desc;
                        }
                        if (main_item.quote_inc === '2') {
                            //replace item_rate with wp_rate
                            const mainItem = {
                                id: main_item.id,
                                headId: main_item.head_id,
                                selectedItem: selItem,
                                qty: parseInt(main_item.wp_qty),
                                price: parseFloat(main_item.wp_rate).toFixed(2),
                                hours: parseFloat(main_item.wp_hrs),
                                desc: wp_desc,
                                cost_rate_select: main_item.item_rate,
                                entry_point: main_item.entry_point,
                                quote_inc: main_item.quote_inc,
                                wp_rate: main_item.wp_rate,
                                wp_hrs: main_item.wp_hrs,
                                wp_qty: main_item.wp_qty,
                                wp_total: main_item.wp_total,
                                total: parseFloat(main_item.wp_total).toFixed(2),
                                item_name: main_item.item_name
                            }
                            mainItems.push(mainItem);
                        }
                    });
                }
            }

            if ((jobAllocationResults.info.WPCompanyId === '679') || (jobAllocationResults.info.WPCompanyId === '570') ||
                (jobAllocationResults.info.WPCompanyId === '580') || (jobAllocationResults.info.WPCompanyId === '125')) {
                reportid = '6_FM';
            }

            let total: any;

            if (jobAllocationResults.info.client_rebate > 0) {
                const rebate: number = parseFloat(jobAllocationResults.info.client_rebate);
                const rebateTotal: any = rebate_total ? rebate_total.toFixed(2) : 0;
                // subtotal = wpsubtotal;
                // const tax: number = (subtotal - rebateTotal) * (taxrate / 100);
                // grandtotal = subtotal - parseFloat(rebateTotal) + tax;
                // //grandtotal = grandtotal.toFixed(2);
                const tempRebate: any = rebate_total;
                let subt: any = parseFloat(wpsubtotal) + parseFloat(tempRebate);

                total = {
                    // taxrate: parseFloat(taxrate).toFixed(2),
                    // sub_total: parseFloat(subt).toFixed(2),
                    // tax: parseFloat(wptaxtotal).toFixed(2),
                    // all_total: parseFloat(wpgrandtotal).toFixed(2),
                    // rebate: parseFloat(rebateTotal).toFixed(2),


                    taxrate : isNaN(parseFloat(taxrate)) ? "0.00" : parseFloat(taxrate).toFixed(2),
                    sub_total: isNaN(parseFloat(subt)) ? "0.00" : parseFloat(subt).toFixed(2),
                    tax: isNaN(parseFloat(wptaxtotal)) ? "0.00" : parseFloat(wptaxtotal).toFixed(2),
                    all_total: isNaN(parseFloat(wpgrandtotal)) ? "0.00" : parseFloat(wpgrandtotal).toFixed(2),
                    rebate: isNaN(parseFloat(rebateTotal)) ? "0.00" : parseFloat(rebateTotal).toFixed(2),

                };
            } else {
                total = {
                    // taxrate: taxrate,
                    // sub_total: wpsubtotal,
                    // tax: wptaxtotal,
                    // all_total: wpgrandtotal,

                    taxrate : isNaN(parseFloat(taxrate)) ? "0.00" : parseFloat(taxrate).toFixed(2),
                    sub_total: isNaN(parseFloat(wpsubtotal)) ? "0.00" : parseFloat(wpsubtotal).toFixed(2),
                    tax: isNaN(parseFloat(wptaxtotal)) ? "0.00" : parseFloat(wptaxtotal).toFixed(2),
                    all_total: isNaN(parseFloat(wpgrandtotal)) ? "0.00" : parseFloat(wpgrandtotal).toFixed(2),
                    rebate: '0',
                }
            }

            // total = {
            //     taxrate: taxrate,
            //     sub_total: wpsubtotal,
            //     tax: wptaxtotal,
            //     all_total: wpgrandtotal,
            //     rebate: '0',
            // }


            /*    let totalData = this.calculateRebate(jobAllocationResults); */
            pdfData = {
                info: {
                    id: '67993bdd-f7d9-48a6-93d9-8026b657041a',
                    jobid: jobId,
                    reportid: reportid,
                    jobId: jobId, allocId: jobAllocationId, tempTime: tempTime
                },
                invoice: {
                    tax_invoice: jobAllocationResults.JobNo,
                    country: jobAllocationResults.EnvCountry,
                    add1: jobAllocationResults.EnvAddressLine1,
                    add2: jobAllocationResults.EnvAddressLine2,
                    city: jobAllocationResults.EnvState,
                    zip: jobAllocationResults.EnvZip,
                    fax: jobAllocationResults.EnvFax,
                    phone: jobAllocationResults.EnvPhone,
                    email: jobAllocationResults.QuotReplyTo,
                    website: jobAllocationResults.EnvWebsite,
                    name: jobAllocationResults.pdfInfo.enviroBankDetail.account_name,
                    account: jobAllocationResults.pdfInfo.enviroBankDetail.account_number,
                    bsb: jobAllocationResults.pdfInfo.enviroBankDetail.account_BSB,
                    abn: jobAllocationResults.pdfInfo.enviroBankDetail.business_ABN,
                    abnName: jobAllocationResults.EnvABNName,
                    date:  moment(new Date()).format('DD/MM/YYYY') //   date:  moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 
                },
                job: {
                    no: jobAllocationResults.pdfInfo.siteInfo.job_no,
                    desc: jobAllocationResults.info.details,
                    claim_no: jobAllocationResults.pdfInfo.siteInfo.job_claim_no,
                    provider_claim: jobAllocationResults.pdfInfo.siteInfo.job_provider_ref,
                    site_address: jobAllocationResults.pdfInfo.siteInfo.address,
                },
                customer: {
                    name: jobAllocationResults.pdfInfo.siteInfo.job_contact_name,
                    company: jobAllocationResults.pdfInfo.siteInfo.business_name,
                    phone: jobAllocationResults.pdfInfo.siteInfo.phone,
                    fax: '1300077446',
                    abn: jobAllocationResults.pdfInfo.siteInfo.business_ABN,
                    inv_line_1: jobAllocationResults.inv_line_1, inv_line_2: jobAllocationResults.inv_line_2,
                    inv_line_3: jobAllocationResults.inv_line_3, inv_line_4: jobAllocationResults.inv_line_4
                },
                total: total,
                prodj_manager_substn: wpPmSubstantiation
            };

            const treeInfo = await this.crudService.getWorkOrderInfo(jobId, jobAllocationId).toPromise();
            const treeData = this.transformTreeInfo(treeInfo);
            pdfData.tree_info = treeData.tree_info;
            pdfData.damages = treeData.damages;


            pdfData.items = [];
            pdfData = [pdfData];
            pdfData = pdfData;
            console.log('pdfData', pdfData);
            return pdfData;
        }
    }

    async updatewfAutoException(exception) {
        const userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
        let res: any;
        if (exception.id) {
            exception.updated_at = new Date();
            exception.updated_by = userDetails.id;
            res = await this.crudService.Update(exception, 'workflowautomationexceptions').toPromise();
        } else {
            exception.updated_at = null;
            exception.updated_by = null;
            exception.created_at = new Date();
            exception.created_by = userDetails.id;
            res = await this.crudService.Create(exception, 'workflowautomationexceptions').toPromise();
            this.updateSchedule(exception.job_id, exception.job_alloc_id, userDetails);
        }
        return res;
    }

    async updateJobWFStatus(jobWFStatus) {
        const userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
        let res: any;
        console.log(jobWFStatus);
        if (jobWFStatus.id) {
            jobWFStatus.updated_at = new Date();
            jobWFStatus.updated_by = userDetails.id;
            res = await this.crudService.Update(jobWFStatus, 'JobWFStatus').toPromise();
        } else {
            jobWFStatus.updated_at = null;
            jobWFStatus.updated_by = null;
            jobWFStatus.created_at = new Date();
            jobWFStatus.created_by = userDetails.id;
            res = await this.crudService.Create(jobWFStatus, 'JobWFStatus').toPromise();
        }
        return res;
    }

    updateSchedule(jobId, jobAllocationId, userDetails) {
        var message = 'Workflow Automation Exception Enabled - ' + new Date().toLocaleString();
        var sms = '1';
        var email = '1';

        var store_data = {
            id: null,
            job_id: jobId,
            job_alloc_id: jobAllocationId,
            process_id: 1,
            visit_type: null,
            status: null,
            sched_date: null,
            PMOnly: '2',
            sched_note: message,
            start_time: null,
            end_time: null,
            created_by: userDetails.id,
            created_at: new Date(),
            phoned: '1',
            phone_no: null,
            sms: null,
            sms_no: null,
            email: null,
            emailaddress: null,
            callback: '1',
            contactUI: null
        }
        this.crudService.Create(store_data, 'JobSchedule').subscribe(res => {
            //console.log('add to schedule ' + res);
            return res;
        });
    }

    updateJobSchedule(jobId, jobAllocationId, message, userDetails) {
        var sms = '1';
        var email = '1';
        var store_data = {
            id: null,
            job_id: jobId,
            job_alloc_id: jobAllocationId,
            process_id: 1,
            visit_type: null,
            status: null,
            sched_date: null,
            PMOnly: '2',
            sched_note: message,
            start_time: null,
            end_time: null,
            created_by: userDetails.id,
            created_at: new Date(),
            phoned: '1',
            phone_no: null,
            sms: null,
            sms_no: null,
            email: null,
            emailaddress: null,
            callback: '1',
            contactUI: null
        }
        this.crudService.Create(store_data, 'JobSchedule').subscribe(res => {
            return res;
        });
    }

    async recordJobActions(jobId, jobAllocId, status, sub_status, action, follow_dt, remarks, escalation, escAllocation) {
        const userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
        let job_action_history = {
            id: null,
            job_id: jobId,
            Job_alloc_id: jobAllocId,
            status,
            sub_status,
            action,
            created_at: new Date().toISOString().split('T')[0],
            created_by: userDetails.id,
            remarks,
            escalation,
            updated_at: null,
            updated_by: null,
            escalation_allocation: escAllocation,
            follow_dt: new Date(follow_dt).toISOString().split('T')[0],
        };
        const res: any = await this.crudService.Create(job_action_history, 'JobActionHistory').toPromise();
        return res;
    }

    createAppReminders(jobId, jobAllocationId, wfStep, taskID, reminderDate, message, userDetails) {
        var data = {
            id: null,
            job_id: jobId,
            alloc_id: jobAllocationId,
            wf_step: wfStep,
            task_id: taskID,
            message: message,
            target_date: reminderDate,
            category_type: '1',
            category_value: '1',
            reminder_target: '1',//1- EF, 2- TP, 3- Client
            status: 'Open',//Open, No Update, Flagged, Closed
            created_by: userDetails.id,
            created_at: new Date(),
        }
        this.crudService.Create(data, 'AppReminders').subscribe(res => {
            return res;
        });
    }

    updateAppReminderStatus(AppRemider) {
        AppRemider.status = 'Closed';//Open, No Update, Flagged, Closed
        this.crudService.Update(AppRemider, 'AppReminders').subscribe(res => {
            return res;
        });
    }

    updateNotifications(user_id, subject, message, allocationId) {
        const AppNotifications: any = {
            status: '1',
            user_id,
            message,
            subject,
            read_status: '0',
            ref_type: 'WORKFLOW',
            read_on: null,
            ref_id: allocationId
        }
        AppNotifications.title = 'Task Notification';
        this.wfService.updateRecord('AppNotifications', AppNotifications);
    }

    getNewDocName(documentName): Promise<any> {
        let fileName = documentName.substr(0, (documentName.lastIndexOf('.')))
        let ext = documentName.substr(documentName.lastIndexOf('.'));
        let datetime = new Date().toISOString();
        datetime = datetime.replace(':', '').substr(0, 13);
        fileName = fileName + '_' + datetime + ext;
        return fileName;
    }

    async fetchJobTaskInfo(jobList, userdetails): Promise<any> {
        let watchedItems: any = await this.crudService.getDataByField('WatchedItems', 'user_id', userdetails.id).toPromise();
        watchedItems = watchedItems.filter(val => (val.ref_type == 'Job' || val.ref_type == 'Allocation'))
        let priorityList: any = await this.crudService.getAllData('JobPriority').toPromise();
        //   let taskList: any = await this.crudService.getDataByField('SSPSearchView', 'wf_code', '7').toPromise();
        for (let allocation of jobList) {
            if (allocation.Priority != null) {
                let priorityInfo = priorityList.find(val => (val.id == allocation.Priority));
                allocation.priority_name = priorityInfo?.job_priority_name;
                allocation.priority_desc = priorityInfo?.job_priority_desc;
            } else {
                allocation.priority_name = '-';
                allocation.priority_desc = 'No Priority';
            }
            let watchInfo: any = null;
            if (allocation.AllocationId != null) {
                watchInfo = watchedItems.find(val => (val.ref_id == allocation.AllocationId));
            } else {
                watchInfo = watchedItems.find(val => (val.ref_id == allocation.JobId));
                allocation.watchInfo = watchInfo;
            }
            allocation.watchInfo = watchInfo;
            if (allocation.watchInfo?.status == '1') {
                allocation.watched = true;
            } else {
                allocation.watched = false;
            }
            //let jobAllocTasks = taskList.filter(val => (val.job_alloc_id == allocation.AllocationId && val.status != '2'));
            let jobAllocTasks = [];
            if (jobAllocTasks.length > 0) {
                allocation.tasksList = jobAllocTasks;
                allocation.task_count = jobAllocTasks.length;
                allocation.next_followup = jobAllocTasks[0].next_followup;
                let allocUsers = [];
                let reasons = [];
                for (let alloc of jobAllocTasks) {
                    if (alloc.AllocatedUsers != 'UnAllocated' && alloc.AllocatedUsers != '' && alloc.AllocatedUsers != null) {
                        allocUsers.push(alloc.AllocatedUsers);
                    }
                    if (alloc.step != 'UnAllocated' && alloc.step != '' && alloc.step != null) {
                        reasons.push(alloc.step)
                    }
                }
                if (reasons.length > 0) {
                    allocation.reasons = reasons.toString();
                } else {
                    allocation.reasons = 'N/A';
                }
                if (allocUsers.length > 0) {
                    allocation.allocated_users = allocUsers.toString();
                } else {
                    allocation.allocated_users = 'N/A';
                }
                allocation.ageing = jobAllocTasks[0].ageing;
                if (0 > jobAllocTasks[0].ageing) {
                    allocation.ageing_status = 'Overdue';
                } else if (1 >= jobAllocTasks[0].ageing && jobAllocTasks[0].ageing >= 0) {
                    allocation.ageing_status = 'Due Today';
                } else if (jobAllocTasks[0].ageing > 1 && 7 >= jobAllocTasks[0].ageing) {
                    allocation.ageing_status = 'Due in 7 Days';
                } else {
                    allocation.ageing_status = 'Due Later';
                }
                allocation.req_by = jobAllocTasks[0].reqby_date;
                allocation.importance = jobAllocTasks[0].priority;
            } else {
                allocation.task_count = 0;
                allocation.next_followup = 'N/A';
                allocation.allocated_users = 'N/A';
                allocation.ageing = 1000;
                allocation.ageing_status = 'On Track';
                allocation.tasksList = null;
                allocation.req_by = null;
                allocation.reasons = 'N/A';
                allocation.importance = '-';
            }
            if (allocation.job_act_dt != null && allocation.job_act_dt !== '') {
                let dateArr = allocation.job_act_dt.split('/');
                let dDay = new Date(dateArr[2], dateArr[1] - 1, dateArr[0], 0, 0, 0);
                allocation.dDay = dDay;
            } else {
                allocation.dDay = null;
            }
            /* if(allocation.job_act_dt!=null){
              allocation.job_act_dt = new Date(allocation.job_act_dt);
            } */
            if (allocation.dDay != null && allocation.dDay !== '') {
                let timeDifference = allocation.dDay.getTime() - new Date().getTime();
                let minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
                let hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
                let secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
                let daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
                let timeLeft = null;
                if (daysToDday > 1) {
                    timeLeft = daysToDday + ' Days';
                } else if (daysToDday < -1) {
                    timeLeft = 'Overdue by ' + daysToDday.toString().replace('-', '') + ' Days';
                } else if (daysToDday < 0 && daysToDday > -2) {
                    timeLeft = 'Overdue by ' + hoursToDday.toString().replace('-', '') + ':' + minutesToDday.toString().replace('-', '') + ':' + secondsToDday.toString().replace('-', '');
                } else {
                    timeLeft = hoursToDday + ':' + minutesToDday + ':' + secondsToDday;
                }
                allocation.timeLeft = timeLeft;
                allocation.daysToDday = daysToDday;
            } else {
                allocation.timeLeft = '';
                allocation.daysToDday = null;
            }
        }

        // console.log('jobList service',jobList)
        return jobList;
    }

    getTimeDifference(jobList): Promise<any> {
        for (let allocation of jobList) {
            if (allocation.dDay != null && allocation.dDay !== '') {
                let timeDifference = allocation.dDay.getTime() - new Date().getTime();
                let minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
                let hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
                let secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
                let daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
                let timeLeft = null;
                if (daysToDday > 1) {
                    timeLeft = daysToDday + ' Days';
                } else if (daysToDday < -1) {
                    timeLeft = 'Overdue by ' + daysToDday.toString().replace('-', '') + ' Days';
                } else if (daysToDday < 0 && daysToDday > -2) {
                    timeLeft = 'Overdue by ' + hoursToDday.toString().replace('-', '') + ':' + minutesToDday.toString().replace('-', '') + ':' + secondsToDday.toString().replace('-', '');
                } else {
                    timeLeft = hoursToDday + ':' + minutesToDday + ':' + secondsToDday;
                }
                allocation.timeLeft = timeLeft;
                allocation.daysToDday = daysToDday;
            } else {
                allocation.timeLeft = '';
                allocation.daysToDday = null;
            }
        }

        // console.log('getTimeDifference service',jobList)
        return jobList;
    }
}