import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as markerjs2 from "markerjs2";
import * as cropro from "cropro";
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { element } from 'protractor';
import { CRUDService } from 'src/app/utilities/crud.service';
import { ImageDocument, Type } from './job-images';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageMarkingComponent } from '../../modules/image-marking/image-marking.component'
import { FileUploader } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';
import { NgxImageCompressService } from 'ngx-image-compress';
import heic2any from "heic2any";
// import {
//   DataUrl,
//   DOC_ORIENTATION,
//   NgxImageCompressService,
//   UploadResponse,
// } from 'ngx-image-compress';

@Component({
  selector: 'app-job-images',
  templateUrl: './job-images.component.html',
  styleUrls: ['./job-images.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService, NgxImageCompressService]
})
export class JobImagesComponent implements OnInit {
  @Input() jobId: any
  @Input() jobAllocationId: any;
  @Input() jobSummary: any;
  @Input() selectedJobAllocId: any;
  @Output() refereshFlag: EventEmitter<any> = new EventEmitter();
  showHeader = false;
  image: any;
  addNewImage: any = false;
  imageFile: any;
  msg: any;
  url: any;
  sourceImage: any;
  targetRoot: any;
  maState: any;
  currentUser: any;
  imageTab: boolean = false;
  uploadedFiles: any[] = [];
  imageDocuments: any[];
  submitted: any;
  isEditImage: any = false;
  uploader: FileUploader;
  path: any;
  companyId: any;
  beforeImgSize: any;
  afterImgSize: any;
  uploadLabel: any;
  file: any;
  units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  loggedInUser: any;
  isDocumentUploaded: any = false;
  dateFormat: any;
  loading: Boolean = true;
    types = [
    { type: 'Before', id: '1' },
    { type: 'Hazard', id: '3' },
    { type: 'After', id: '2' }
  ];
  imageForm = new FormGroup({
    type: new FormControl('', Validators.required),
    img_description: new FormControl('', Validators.required),
    image_upload: new FormControl('')
  })
  // imageString : string ;
  // imgResultBeforeCompress: DataUrl = '';
  // imgResultAfterCompress: DataUrl = '';
  // imgResultAfterResize: DataUrl = '';
  // imgResultUpload: DataUrl = '';
  // imgResultAfterResizeMax: DataUrl = '';
  // imgResultMultiple: UploadResponse[] = [];
  AllocationList: any[];
  allocationdisable: Boolean = false;
  uploadAllocationList: any[];
  imageDocument = {
      company_id: null,
      created_at: null,
      created_by: null,
      deleted: null,
      id: null,
      img_description: null,
      img_inc: '2',
      img_name: null,
      img_path: null,
      img_type: null,
      included: null,
      job_alloc_id: null,
      process_id: null,
      job_id: null,
      last_modified_by: null,
      last_updated_at: null,
      owner: null,
      source: null,
      status: '1',
      upload_by: null,
      upload_time: null,
      upload_type: null,
      upload_type_detail: null,
      user_id: null,
      username: null,
      vs: null,
      image_url: null,
      isIncluded: true,
      selectedType: null,
      upload_time_str: null,
      upload_time_str2: null
  }
  constructor(
    private CrudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private router: Router,
    private dialogService: DialogService,
    private imageCompress: NgxImageCompressService


  ) {
    this.companyId = sessionStorage.getItem('company_id');
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.loggedInUser = JSON.parse(sessionStorage.getItem('loggedInUser'));
  }
  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;
  imageString: string;
  env:any;
  compressFile() {

    this.imageCompress.uploadFile().then(({ image, orientation }) => {

      this.imgResultBeforeCompress = image;
      console.warn('Size in bytes was:', this.imageCompress.byteCount(image));

      this.imageCompress.compressFile(image, orientation, 50, 50).then(
        result => {
          this.imgResultAfterCompress = result;
          console.warn('Size in bytes is now:', this.imageCompress.byteCount(result));
        }
      );

    });

  }
  // compressFile() {
  //   return this.imageCompress 
  //     .uploadFile()
  //     .then(({ image, orientation }: UploadResponse) => {
  //       this.imgResultBeforeCompress = image;
  //       console.warn('Size in bytes was:', this.imageCompress.byteCount(image));

  //       this.imageCompress
  //         .compressFile(image, orientation, 50, 50)
  //         .then((result: DataUrl) => {
  //           this.imgResultAfterCompress = result;
  //           console.warn(
  //             'Size in bytes is now:',
  //             this.imageCompress.byteCount(result)
  //           );
  //         });
  //     });
  // }



  async ngOnInit() {
    this.dateFormat = environment.dateTimeFormat;
    this.env = environment;
    if (typeof this.jobId === 'undefined') {
      if (typeof this.config.data == 'undefined' || typeof (this.config.data.jobId) == 'undefined') {
        if (this.jobId == null || this.jobId === '') {
          this.jobId = parseInt(this.route.snapshot.paramMap.get('jobId'));
          if (this.jobAllocationId === null || this.jobAllocationId === '') {
            this.jobAllocationId = '1';
          } else {
            this.jobAllocationId = parseInt(this.route.snapshot.paramMap.get('jobAllocationId'));
            this.jobSummary = this.CrudService.getDataByField('JobInfo', 'id', this.jobId);
            this.loading=false
          }
        }
        this.showHeader = false;
      } else {
        this.jobId = this.config.data.jobId;
        this.jobAllocationId = this.config.data.jobAllocationId;
        this.jobSummary = this.config.data.jobSummary;
        this.showHeader = true;
        this.loading=false
      }
    }

    if (this.jobAllocationId == '1') {
      await this.fetchAllAllocation();
    } else {
      this.getUploadImages();
    }

    /* this.imageDocument.company_id = this.companyId;
    this.imageDocument.job_alloc_id = this.jobAllocationId;
    this.imageDocument.job_id = this.jobId;
    this.imageDocument.process_id = this.companyId; */

    this.image = localStorage.getItem('image');
    //   this.openImageTab();
    //   this.showMarkerArea()
    this.uploader = new FileUploader({
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        return new Promise((resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date()
          });
        });
      }
    });
  }

  showMarkerArea(img, index) {
    const markerArea = new markerjs2.MarkerArea(img);
    markerArea.settings.displayMode = 'popup';
    markerArea.addRenderEventListener(async (imgURL) => {
      await this.updateImage(imgURL, this.imageDocuments[index]);
    });
    markerArea.show();
  }

  showMarkerArea2(imageDtls, index) {
    let imageSrc: HTMLImageElement;
    this.imageDocument = imageDtls;

    imageSrc = (document.getElementById(imageDtls.id) as HTMLImageElement)
    const markerArea = new markerjs2.MarkerArea(imageSrc);
    markerArea.settings.displayMode = 'popup';
    markerArea.addRenderEventListener(async (imgURL) => {
      /* imageDtls.src = imgURL
      imageSrc.src = imgURL; */
      await this.updateImage(imgURL, this.imageDocuments[index]);
    });
    markerArea.show();
  }

  // imageCompress(imageDtls, index) {
  //   let imageSrc: HTMLImageElement;
  //   this.imageDocument = imageDtls;
  //   //console.log(imageDtls);
  //   let file: string;
  //   file=environment.base_url+imageDtls.img_path;
  //   //console.log(file);
  // }

  showCropArea(imageDtls, index) {
    let imageSrc: HTMLImageElement;
    imageSrc = (document.getElementById(imageDtls.id) as HTMLImageElement)
    const cropArea = new cropro.CropArea(imageSrc);
    cropArea.displayMode = 'popup';
    cropArea.addRenderEventListener(async (imgURL) => {
      /* imageDtls.src = imgURL
      imageSrc.src = imgURL; */
      await this.updateImage(imgURL, this.imageDocuments[index]);
    });
    cropArea.show();
  }

  async backupImage(imagePath) {
    const backupResponse = await this.CrudService.backupImage(imagePath).toPromise();
  }

  async updateImage(imgURL, imageInfo) {
    this.loading = true;
    await this.backupImage(imageInfo.img_path);
    let fileName = imageInfo.img_name.substr(0, (imageInfo.img_name.lastIndexOf('.')))
    let ext = imageInfo.img_name.substr(imageInfo.img_name.lastIndexOf('.'));
    let datetime = new Date().toISOString();
    datetime = datetime.replace(':', '').substr(0, 13);
    fileName = fileName + '_' + datetime + ext;
    let res = this.CrudService.uploadImage(imgURL, this.jobId, this.jobAllocationId, fileName);
    let response = JSON.parse(res);
    if (response.status === 1) {
      this.loading = false;
      let imageDocument = {
        company_id: this.currentUser.company,
        created_at: new Date(),
        created_by: this.currentUser.userId,
        deleted: null,
        id: null,
        img_description: null,
        img_inc: imageInfo.img_inc,
        img_name: fileName,
        img_path: response.filepath,
        img_type: imageInfo.img_type,
        included: imageInfo.included,
        job_alloc_id: this.jobAllocationId,
        job_id: this.jobId,
        last_modified_by: null,
        last_updated_at: null,
        owner: null,
        process_id: this.currentUser.company,
        source: null,
        status: '1',
        upload_by: this.currentUser.userId,
        upload_time: new Date(),
        upload_type: null,
        upload_type_detail: null,
        user_id: this.currentUser.userId,
        username: this.currentUser.username,
        vs: null,
        image_url: imgURL,
        isIncluded: imageInfo.isIncluded,
        selectedType: imageInfo.selectedType,
        upload_time_str: new Date().toLocaleString(),
        upload_time_str2: new Date(),
        s3_url: response?.s3Url,
        sync_status: response?.s3Url ? 1 : 0
      }
      this.imageDocuments = [imageDocument, ...this.imageDocuments];
      this.loading=false

      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'File Uploaded Successfully', life: 1000 });
    } else {
      this.loading = false;
      this.messageService.add({ severity: 'error', summary: 'error', detail: response.message, life: 2500 });
    }
  }
  hideDialog() {
    this.imageTab = false;
  }
  openImageTab() {
    this.imageTab = true;
  }

  // uploadFile(fileInfo) {
  //   let file = fileInfo.file;
  //   let imageDocument = {
  //     company_id: this.currentUser.company,
  //     created_at: new Date(),
  //     created_by: null,
  //     deleted: null,
  //     id: null,
  //     img_description: null,
  //     img_inc: '2',
  //     img_name: null,
  //     img_path: null,
  //     img_type: fileInfo.id,
  //     included: null,
  //     job_alloc_id: this.jobAllocationId,
  //     job_id: this.jobId,
  //     last_modified_by: null,
  //     last_updated_at: null,
  //     owner: null,
  //     process_id: this.currentUser.company,
  //     source: null,
  //     status: "1",
  //     upload_by: this.currentUser.userId,
  //     upload_time: null,
  //     upload_type: null,
  //     upload_type_detail: null,
  //     user_id: this.currentUser.userId,
  //     username: this.currentUser.username,
  //     vs: null,
  //     image_url: null,
  //     isIncluded: true,
  //     selectedType: null,
  //     upload_time_str: null
  //   }
  //   let imgPath = null;
  //   let reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = (_event) => {
  //     if (reader.DONE) {
  //       imgPath = reader.result;
  //       let res = this.CrudService.uploadImage(imgPath, this.jobId, this.jobAllocationId, file.name);
  //       let response = JSON.parse(res)
  //       if (response.status == '1') {
  //         imageDocument.img_name = file.name;
  //         imageDocument.upload_time_str = new Date().toLocaleString();
  //         imageDocument.upload_time = new Date();
  //         imageDocument.img_path = response.filepath;
  //         imageDocument.img_inc = '2';
  //         imageDocument.isIncluded = true;
  //         imageDocument.selectedType = { type: fileInfo.type, id: fileInfo.id };
  //         this.CrudService.Create(imageDocument, 'UploadImages').subscribe((data: any) => {
  //           //console.log('uploadimage', data);
  //           if (data.success === 1) {
  //             imageDocument.id = data.id
  //             this.CrudService.getImageFile(imageDocument.img_path).subscribe(blob => {
  //               let objectURL = URL.createObjectURL(blob);
  //               let image_url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  //               imageDocument.image_url = image_url;
  //             });
  //             this.imageDocuments = [...this.imageDocuments, imageDocument];
  //             this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'File Uploaded Successfully', life: 1000 });
  //           } else {
  //             this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File Not Uploaded', life: 1000 });
  //           }
  //         });
  //         this.addNewImage = false;
  //       } else {
  //         this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File Not Uploaded', life: 1000 });
  //       }
  //     }
  //   }
  // }

  niceBytes(x) {
    let l = 0, n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + this.units[l]);
  }

  onUpload(event) {
    this.loading = true;
    for (let file of event) {
      let document = {
        Inv: null,
        company_id: this.currentUser.company,
        created_at: new Date(),
        created_by: null,
        file_description: null,
        file_name: null,
        file_path: null,
        file_size: null,
        hide: '2',
        id: null,
        inc_quote: '1',
        job_alloc_id: this.jobAllocationId,
        job_id: this.jobId,
        last_modified_by: null,
        last_updated_at: null,
        owner: null,
        process_id: this.currentUser.company,
        status: '1',
        upload_at: new Date(),
        upload_by: this.currentUser.userId,
        upload_type: null,
        upload_type_detail: null,
        username: this.loggedInUser.first_name + ' ' + this.loggedInUser.last_name,
        upload_time: null,
        upload_time_str: null,
        upload_time_str2: null,
        sizeInKB: null,
        isHidden: true,
        isIncluded: false,
        s3_url: null,
        sync_status: 0
      }
      this.loading = true;
      document.file_name = file.name
      document.file_size = file.size.toString();
      document.upload_time = new Date(document.upload_at).toLocaleString();
      document.upload_time_str = new Date(document.upload_at).toLocaleString();
      document.upload_time_str2 = new Date(document.upload_at);
      document.sizeInKB = this.niceBytes(document.file_size);
      //console.log(file)
      if (file.size <= environment.imageDocSize) {
        //this.document.sizeInMB = this.niceBytes(this.document.sizeInKB);
        let res =   this.CrudService.uploadDocumentImgs(file, this.jobId, this.jobAllocationId)
        let response = JSON.parse(res);
        //console.log(response);
        if (response.success === 1) {
          this.isDocumentUploaded = true;
          //console.log('image uploaded');
          document.file_path = this.jobId + '/' + this.jobAllocationId + '/' + file.name;
          document.file_name = file.name;
          document.s3_url = response?.s3Url;
          document.sync_status = response?.s3Url ? 1 : 0;
          this.saveNewDocumentRecord(document);
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: response.message, life: 2500 });
          this.isDocumentUploaded = false
          this.loading = false;
        }
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File must be < 10mb', life: 1000 });
        this.isDocumentUploaded = false
      }
    }
    this.loading = false;
    this.refereshFlag.emit("Document Added");
  }

  saveNewDocumentRecord(document) {
    this.submitted = true;
    this.loading = true;
    if (this.isDocumentUploaded === true) {
      this.CrudService.Create(document, 'UploadDocuments').subscribe((data: any) => {
        document.id = data.id
        if (data.success == 1) {
      //    this.addNewDocument = false;
          this.loading = false;
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'File Uploaded Successfully', life: 1000 });
         // this.documents = [document, ...this.documents];
        }
      })
    }
  }

  uploadFileImgUk(fileInfo) {
    this.loading = true;
    let file = fileInfo.file;
    let imageDocument = {
      company_id: this.currentUser.company,
      created_at: new Date(),
      created_by: null,
      deleted: null,
      id: null,
      img_description: null,
      img_inc: '2',
      img_name: null,
      img_path: null,
      img_type: fileInfo.id,
      included: null,
      job_alloc_id: this.jobAllocationId,
      job_id: this.jobId,
      last_modified_by: null,
      last_updated_at: null,
      owner: null,
      process_id: this.currentUser.company,
      source: null,
      status: "1",
      upload_by: this.currentUser.userId,
      upload_time: null,
      upload_type: null,
      upload_type_detail: null,
      user_id: this.currentUser.userId,
      username: this.currentUser.username,
      vs: null,
      image_url: null,
      isIncluded: true,
      selectedType: null,
      upload_time_str: null,
      upload_time_str2: null,
      s3_url: null,
      sync_status: 0
    }
    let imgPath = null;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      if (reader.DONE) {
        imgPath = reader.result;
        let res = this.CrudService.uploadDocumentImgs(file, this.jobId, this.jobAllocationId);
        let response = JSON.parse(res);
        console.log('uploadimage', response);
        debugger
        if (response.success === 1) {
          
          imageDocument.img_name = file.name;
          imageDocument.upload_time_str = new Date().toLocaleString();
          imageDocument.upload_time_str2 = new Date();
          imageDocument.upload_time = new Date();
          imageDocument.img_path = this.jobId + '/' + this.jobAllocationId + '/' + file.name;;
          imageDocument.img_inc = '2';
          imageDocument.isIncluded = true;
          imageDocument.selectedType = { type: fileInfo.type, id: fileInfo.id };
          imageDocument.s3_url = response?.s3Url,
          imageDocument.sync_status = response?.s3Url ? 1 : 0
          this.CrudService.Create(imageDocument, 'UploadImages').subscribe((data: any) => {
            console.log('uploadimage', data);
            if (data.success === 1) {
              imageDocument.id = data.id
              this.CrudService.getImageFile(imageDocument.img_path).subscribe(blob => {
                let objectURL = URL.createObjectURL(blob);
                let image_url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
                imageDocument.image_url = image_url;
              });
              this.imageDocuments = [...this.imageDocuments, imageDocument];
              this.loading = false;
              setTimeout( () => this.loading = false, 800 );
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'File Uploaded Successfully', life: 1000 });
            } else {
              this.loading = false;
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File Not Uploaded', life: 1000 });
            }
          });
          this.addNewImage = false;
        } else {
          this.loading = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: response.message, life: 1000 });
        }
      }
    }
  }

  uploadFile(fileInfo) {
    this.loading = true;
    let file = fileInfo.file;
    let imageDocument = {
      company_id: this.currentUser.company,
      created_at: new Date(),
      created_by: null,
      deleted: null,
      id: null,
      img_description: null,
      img_inc: '2',
      img_name: null,
      img_path: null,
      img_type: fileInfo.id,
      included: null,
      job_alloc_id: this.jobAllocationId,
      job_id: this.jobId,
      last_modified_by: null,
      last_updated_at: null,
      owner: null,
      process_id: this.currentUser.company,
      source: null,
      status: "1",
      upload_by: this.currentUser.userId,
      upload_time: null,
      upload_type: null,
      upload_type_detail: null,
      user_id: this.currentUser.userId,
      username: this.currentUser.username,
      vs: null,
      image_url: null,
      isIncluded: true,
      selectedType: null,
      upload_time_str: null,
      upload_time_str2: null,
      s3_url: null,
      sync_status: 0
    }
    let imgPath = null;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      if (reader.DONE) {
        imgPath = reader.result;
        let res = this.CrudService.uploadImage(imgPath, this.jobId, this.jobAllocationId, file.name);
        let response = JSON.parse(res)
        if (response.status == '1') {
          imageDocument.img_name = file.name;
          imageDocument.upload_time_str = new Date().toLocaleString();
          imageDocument.upload_time_str2 = new Date();
          imageDocument.upload_time = new Date();
          imageDocument.img_path = response.filepath;
          imageDocument.img_inc = '2';
          imageDocument.isIncluded = true;
          imageDocument.selectedType = { type: fileInfo.type, id: fileInfo.id };
          imageDocument.s3_url = response?.s3Url,
          imageDocument.sync_status = response?.s3Url ? 1 : 0
          this.CrudService.Create(imageDocument, 'UploadImages').subscribe((data: any) => {
            //console.log('uploadimage', data);
            if (data.success === 1) {
              imageDocument.id = data.id
              this.CrudService.getImageFile(imageDocument.img_path).subscribe(blob => {
                let objectURL = URL.createObjectURL(blob);
                let image_url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
                imageDocument.image_url = image_url;
              });
              this.imageDocuments = [...this.imageDocuments, imageDocument];
              this.loading = false;
              setTimeout( () => this.loading = false, 800 );
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'File Uploaded Successfully', life: 1000 });
            } else {
              this.loading = false;
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File Not Uploaded', life: 1000 });
            }
          });
          this.addNewImage = false;
        } else {
          this.loading = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: response.message, life: 1000 });
        }
      }
    }
  }

  addCompressedImage(fileInfo) {
    let file = fileInfo.file;
    let imageDocument = {
      company_id: this.currentUser.company,
      created_at: new Date(),
      created_by: null,
      deleted: null,
      id: null,
      img_description: null,
      img_inc: '2',
      img_name: file.name,
      img_path: null,
      img_type: fileInfo.id,
      included: null,
      job_alloc_id: this.jobAllocationId,
      job_id: this.jobId,
      last_modified_by: null,
      last_updated_at: null,
      owner: null,
      process_id: this.currentUser.company,
      source: null,
      status: '1',
      upload_by: this.currentUser.userId,
      upload_time: null,
      upload_type: null,
      upload_type_detail: null,
      user_id: this.currentUser.userId,
      username: this.currentUser.username,
      vs: null,
      image_url: null,
      isIncluded: true,
      selectedType: null,
      upload_time_str: null,
      upload_time_str2: null,
      sync_status: 0,
      s3_url: null
    }
    let imgPath = fileInfo.imageData;
    let res = this.CrudService.uploadImage(imgPath, this.jobId, this.jobAllocationId, file.name);
    let response = JSON.parse(res)
    if (response.status === 1) {
      imageDocument.img_name = file.name;
      imageDocument.upload_time_str = new Date().toLocaleString();
      imageDocument.upload_time_str2 = new Date();
      imageDocument.upload_time = new Date();
      imageDocument.img_path = response.filepath;
      imageDocument.sync_status = response.s3Url ? 1 : 0;
      imageDocument.s3_url = response.s3Url || null;
      imageDocument.img_inc = '2';
      imageDocument.isIncluded = true;
      imageDocument.selectedType = { type: fileInfo.type, id: fileInfo.id };
      this.CrudService.Create(imageDocument, 'UploadImages').subscribe((data: any) => {
        //console.log('uploadimage', data);
        if (data.success === 1) {
          imageDocument.id = data.id
          this.CrudService.getImageFile(imageDocument.img_path).subscribe(blob => {
            let objectURL = URL.createObjectURL(blob);
            let image_url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
            imageDocument.image_url = image_url;
          });
          this.imageDocuments = [...this.imageDocuments, imageDocument];
          this.loading = false;
          setTimeout( () => this.loading = false, 800 );
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'File Uploaded Successfully', life: 1000 });
        } else {
          this.loading = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File Not Uploaded', life: 1000 });
        }
      });
      this.addNewImage = false;
    } else {
      this.loading = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File Not Uploaded', life: 1000 });
    }
  }

  async addNewImageFileNew(fileInfo) {
    let file = fileInfo.file;
    let imageDocument = {
      company_id: this.currentUser.company,
      created_at: new Date(),
      created_by: null,
      deleted: null,
      id: null,
      img_description: null,
      img_inc: '2',
      img_name: null,
      img_path: null,
      img_type: fileInfo.id,
      included: null,
      job_alloc_id: this.jobAllocationId,
      job_id: this.jobId,
      last_modified_by: null,
      last_updated_at: null,
      owner: null,
      process_id: this.currentUser.company,
      source: null,
      status: "1",
      upload_by: this.currentUser.userId,
      upload_time: null,
      upload_type: null,
      upload_type_detail: null,
      user_id: this.currentUser.userId,
      username: this.currentUser.username,
      vs: null,
      image_url: null,
      isIncluded: true,
      selectedType: null,
      upload_time_str: null,
      upload_time_str2: null,
      s3_url: null,
      sync_status: 0
    }
    //let response = await this.compressUploadImageFile(file);
    let response = await this.uploadImageFile(file);
    if (response.success === true) {
      imageDocument.img_name = file.name;
      imageDocument.upload_time_str = new Date().toLocaleString();
      imageDocument.upload_time_str2 = new Date();
      imageDocument.upload_time = new Date();
      imageDocument.img_path = response.filepath;
      imageDocument.img_inc = '2';
      imageDocument.isIncluded = true;
      imageDocument.selectedType = { type: fileInfo.type, id: fileInfo.id };
      imageDocument.s3_url = response?.s3Url,
      imageDocument.sync_status = response?.s3Url ? 1 : 0
      this.CrudService.getImageFile(imageDocument.img_path).subscribe(blob => {
        let objectURL = URL.createObjectURL(blob);
        let image_url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        imageDocument.image_url = image_url;
      });
      this.imageDocuments = [...this.imageDocuments, imageDocument];
      this.addNewImage = false;
    } else {
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: response.message, life: 1000 });
    }
  }

  async uploadImageFile(file: any): Promise<any> {
    let res: any;
    if (file.size <= environment.imageDocSize) {
      let filepath: any = this.jobId + '/' + this.jobAllocationId;
      let filename: any = file.name;
      res = await this.CrudService.uploadImageV2(file, filepath, filename);
      res = JSON.parse(res);
      if (res.success === true) {
        res.filepath = filepath + '/' + filename;
      }
    }
    return res;
  }

  async compressUploadImageFile(file: any): Promise<any> {
    let res: any;
    if (file.size <= environment.imageDocSize) {
      let filepath: any = this.jobId + '/' + this.jobAllocationId;
      let filename: any = file.name;
      res = await this.CrudService.compressUploadImageV2(file, filepath, filename);
      res = JSON.parse(res);
      if (res.success === true) {
        res.filepath = filepath + '/' + filename;
      }
    }
    return res;
  }

  /*
    uploadBeforeImages(event) {
      for (let file of event) {
        let duplicates = this.imageDocuments.filter(val => val.img_name === file.name);
        if (duplicates.length > 0) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Duplicate Image Found : ' + file.name, life: 1000 });
        } else {
          let fileInfo = { order: this.uploadedFiles.length, type: 'Before', id: '1', file: file };
          //this.uploadedFiles.push(fileInfo);
          this.addNewImageFile(fileInfo);
        }
      }
      //this.uploadLabel = this.uploadedFiles.length + ' Images';
    }
   
   
    uploadAfterImages(event) {
      //this.selectedType = { type: 'After', id: '2' };
      for (let file of event) {
        let duplicates = this.imageDocuments.filter(val => val.img_name === file.name);
        if (duplicates.length > 0) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Duplicate Image Found : ' + file.name, life: 1000 });
        } else {
          let fileInfo = { order: this.uploadedFiles.length, type: 'After', id: '2', file: file };
          //this.uploadedFiles.push(fileInfo);
          //this.uploadFile(file);
          this.addNewImageFile(fileInfo);
        }
      }
      //this.uploadLabel = this.uploadedFiles.length + ' Images';
    }
  */

  uploadBeforeImages(filess) {
    this.loading = true;
    for (let event of filess) {
    let f: File;
    //RECEIVE IMAGE
    if (event) {
      f = event;
      if (event.length > 1) {
        event.splice(0, event.length - 1);
        f = event;
      }
    } else if (event.target && event.target.files && event.target.files) {
      f = event.target.files;
    }
    if (!f) {
      //Handle error and exit
    }
    let blob: Blob = f;
    let file: File = f;

    let convProm: Promise<any>;

    //CONVERT HEIC TO JPG

    convProm = heic2any({ blob, toType: "image/jpeg", quality: 0 }).then((jpgBlob: Blob) => {

      //Change the name of the file according to the new format
      let newName = f.name.replace(/\.[^/.]+$/, ".jpg");

      //Convert blob back to file
      file = this.blobToFile(jpgBlob, newName);

    }).catch(err => {
      //Handle error
    });

    convProm.then(() => {
      let reader = new FileReader();
      if (file) {
        let duplicates = this.imageDocuments.filter(val => val.img_name === file.name);
        if (duplicates.length > 0) {
          this.loading = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Duplicate Image Found : ' + file.name, life: 1000 });
        } else {

          reader.readAsDataURL(file);
          reader.onload = (_event) => {
            if (reader.DONE) {
              let imgPath = null;
              imgPath = reader.result;
              if (this.env.hosting=='AU') {
                let cp = (200000 * 100) / (file.size / 10);
                this.imageCompress.compressFile(imgPath, -2, 25, 50).then(
                  result => {
                    let fileInfo = { order: this.uploadedFiles.length, type: 'Before', id: '1', file: file, imageData: result };
                    this.loading = false;
                    this.uploadedFiles.push(fileInfo);
                  
                    this.addCompressedImage(fileInfo);
                  }
                );
              } else {
                let fileInfo = { order: this.uploadedFiles.length, type: 'Before', id: '1', file: file, imageData: imgPath };
                this.loading = false;
                this.uploadedFiles.push(fileInfo);
             
                this.uploadFileImgUk(fileInfo);
              }
            }
          };
        }

      }
    });
  }

    this.uploadLabel = this.uploadedFiles.length + ' Images';
    this.refereshFlag.emit("Images Added");
  }
  
    private blobToFile = (theBlob: Blob, fileName: string): File => {
    let b: any = theBlob;

    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModified = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>theBlob;
  }


  /*uploadAfterImages(event) {
    for (let file of event) {
      let duplicates = this.imageDocuments.filter(val => val.img_name === file.name);
      if (duplicates.length > 0) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Duplicate Image Found : ' + file.name, life: 1000 });
      } else {
        let fileInfo = { order: this.uploadedFiles.length, type: 'After', id: '2', file: file };
        this.uploadedFiles.push(fileInfo);
      }
    }
    this.uploadLabel = this.uploadedFiles.length + ' Images';
  }*/


  uploadAfterImages(filess) {
    this.loading = true;

    for (let event of filess) {
          let f: File;
          //RECEIVE IMAGE
          if (event) {
            f = event;
            if (event.length > 1) {
              event.splice(0, event.length - 1);
              f = event;
            }
          } else if (event.target && event.target.files && event.target.files) {
            f = event.target.files;
          }
          if (!f) {
            //Handle error and exit
          }
          let blob: Blob = f;
          let file: File = f;

          let convProm: Promise<any>;

          //CONVERT HEIC TO JPG
        
          convProm = heic2any({ blob, toType: "image/jpeg", quality: 0 }).then((jpgBlob: Blob) => {

            //Change the name of the file according to the new format
            let newName = f.name.replace(/\.[^/.]+$/, ".jpg");

            //Convert blob back to file
            file = this.blobToFile(jpgBlob, newName);

          }).catch(err => {
            //Handle error
          });
        
          convProm.then(() => {
            let reader = new FileReader();
            if (file) {
          let duplicates = this.imageDocuments.filter(val => val.img_name === file.name);
            if (duplicates.length > 0) {
              this.loading = false;
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Duplicate Image Found : ' + file.name, life: 1000 });
            } else {
          
              reader.readAsDataURL(file);
              reader.onload = (_event) => {
                if (reader.DONE) {
                  let imgPath = null;
                  imgPath = reader.result;
                  //reader.onload = (_event, imgPath:void,imgResultBeforeCompress:void,imgResultAfterCompress:void) => {
                    if (this.env.hosting=='AU') {
                    let cp = (200000 * 100) / (file.size / 10);
                    this.imageCompress.compressFile(imgPath, -2, 25, 50).then(
                      result => {
                        let fileInfo = { order: this.uploadedFiles.length, type: 'After', id: '2', file: file, imageData: result };
                        this.loading = false;
                        this.uploadedFiles.push(fileInfo);
                        this.addCompressedImage(fileInfo);
                      }
                    );
                  }
                  else {
                    let fileInfo = { order: this.uploadedFiles.length, type: 'After', id: '2', file: file, imageData: imgPath };
                    this.loading = false;
                    this.uploadedFiles.push(fileInfo);
                    this.uploadFileImgUk(fileInfo);
                  }
                }
              };
            }
        

          }
        });
      }

    this.loading = false;
    this.uploadLabel = this.uploadedFiles.length + ' Images';
    this.refereshFlag.emit("Images Added");
  }

  removeImage(fileInfo) {
    this.uploadedFiles = this.uploadedFiles.filter(val => val.order !== fileInfo.order);
  }
  getUploadImages() {
    this.imageDocuments = [];
    this.CrudService.getUploadImgsByJobIdAllocId(this.jobId, this.jobAllocationId).subscribe(async (data: any[]) => {
      if (data) {
        this.loading = false
        data.forEach(imagedocument => {
          // //console.log(imagedocument.upload_time.split("T")[0] + imagedocument.upload_time.split("T")[1].split(".")[0]);
          if (imagedocument.img_inc === '2') {
            imagedocument.isIncluded = true;
          } else {
            imagedocument.isIncluded = false;
          }
          imagedocument.upload_time = imagedocument.upload_time.split("T")[0] + ' ' + imagedocument.upload_time.split("T")[1].split(".")[0];
          imagedocument.upload_time_str = new Date(imagedocument.upload_time).toLocaleString();
          imagedocument.upload_time_str2 = new Date(imagedocument.upload_time);
          if (imagedocument.img_path != null && imagedocument.img_path !== '') {
            this.CrudService.getImageFile(imagedocument.img_path).subscribe(blob => {
              imagedocument.blob = blob;
              let objectURL = URL.createObjectURL(blob);
              imagedocument.objUrl = objectURL;
              let image_url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
              imagedocument.image_url = image_url;
            });
          }
          this.types.forEach(type => {
            if (type.id === imagedocument.img_type) {
              imagedocument.selectedType = type;
            }
          });
          this.imageDocuments.push(imagedocument);
        });
        if (data.length) {
          let res: any = await this.CrudService.getAllAllocatedJobByJobId(data[0].job_alloc_id).toPromise();
          this.uploadAllocationList = res;
          if (this.uploadAllocationList.length === 0) {
            this.allocationdisable = false;
          }
          else {
            this.allocationdisable = true;
          }
        }
      }
    })
  }

  onChangeImageType(selecetdType) {
    this.imageDocument.img_type = selecetdType.id
  }

  saveNewImageRecord() {
    this.submitted = true;
    this.loading = true;
    if (this.imageForm.status === 'VALID') {
      this.imageDocuments.push(this.imageDocument);
      this.CrudService.Create(this.imageDocument, 'UploadImages').subscribe((data: any) => {
        if (data.success == 1) {
          this.loading = false;
          this.imageDocument.id = data.id;
          this.addNewImage = false;
        }
      })
    }
  }
  deleteImage(image, index) {
    this.loading = true;
    if (image.id !== null) {
      this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected Image?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.CrudService.Delete(image.id, 'UploadImages').subscribe((data: any) => {
            if (data.success == 1) {
              this.loading = false;
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Image Deleted', life: 1000 });
              this.imageDocuments = this.imageDocuments.filter((val: any) => val.id !== image.id)
            }
          })
        }
      })
    } else {
      this.imageDocuments.splice(index, 1);
    }
    this.refereshFlag.emit("Images Deleted");
  }

  saveRecord() {
    this.loading = true;
    this.imageDocuments.forEach(document => {
      this.CrudService.Update(document, 'UploadImages').subscribe((data: any) => {
        this.loading = false;
        this.imageTab = false
      })
    });
  }
  onChangeTpe(id, type) {
    this.imageDocuments.forEach(element => {
      if (id === element.id) {
        element.img_type = type.id;
      }
    });
  }
  DateToLocalTimeString(d) {
    let date = new Date(d);
    d = date.toLocaleDateString('en-GB');
    let t = date.toLocaleTimeString();
    return d + ' ' + t;
  }

  editImage(image) {
    this.image = { ...image };
    const ref = this.dialogService.open(ImageMarkingComponent, {
      data: {
        image: this.image
      },
      header: 'Image Marking',
      width: '100%',
      height: '100%'
    });
  }

  rotateImage(imageDocument) {
    let reader = new FileReader();
    reader.readAsDataURL(imageDocument.blob);
    reader.onloadend = function (e) {
      let imageSize = e.total;
      let image = new Image();
      imageDocument.src = e.target.result;
      image.onload = function () {
        let width = image.width;
        let height = image.height;
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext('2d');

        let newImage = new Image();
        canvas.height = width;
        canvas.width = height;
        ctx.rotate(Math.PI / 2);
        ctx.translate(0, -height);

        ctx.drawImage(image, 0, 0)
        let imageDate = canvas.toDataURL('Image/jpeg', 1)
        newImage.src = imageDate;
      }
    }
  }

  async save(imageDetails) {
    this.loading = true;
    if (imageDetails.selectedType) {
      imageDetails.img_type = imageDetails.selectedType.id;
    }
    if (imageDetails.isIncluded) {
      imageDetails.img_inc = '2';
    } else {
      imageDetails.img_inc = '1';
    }
    imageDetails.img_type = imageDetails.selectedType.id;
    await this.CrudService.Update(imageDetails, 'UploadImages').subscribe((data: any) => {
      if (data.success == '1') {
        this.loading = false;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Image Information Updated', life: 1000 });

      }
    })

  }

  saveAllImages() {
    this.uploadedFiles.forEach(fileInfo => {
      this.uploadFile(fileInfo);
    });
    this.uploadedFiles = [];
  }

  saveAll() {
    this.loading = true;
    //setTimeout(() => this.loading = false, 800);
    let status = true;
    this.imageDocuments.forEach(async imageDetails => {
      if (imageDetails.selectedType) {
        imageDetails.img_type = imageDetails.selectedType.id;
      }
      if (imageDetails.isIncluded) {
        imageDetails.img_inc = '2';
      } else {
        imageDetails.img_inc = '1';
      }
      imageDetails.img_type = imageDetails.selectedType.id;
      if (imageDetails.id) {
        await this.CrudService.Update(imageDetails, 'UploadImages').subscribe((data: any) => {
          this.loading = false;
          if (data.success == '1') {
            this.loading = true;
          } else {
            status = false;
          }
        })
      } else {
        await this.CrudService.Create(imageDetails, 'UploadImages').subscribe((data: any) => {
          this.loading = false;
          if (data.success === 1) {
            imageDetails.id = data.id;
          } else {
            status = false;
            this.messageService.add({ severity: 'warning', summary: 'Error', detail: 'File Not Uploaded', life: 1000 });
          }
        });
      }
    });
    if (status) {
      this.loading = false;
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Image Information Updated', life: 1000 });
    } else {
      this.loading = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Image Information  Not Updated', life: 1000 });
    }
  }

  compressSelectedImage(index) {
    debugger
    let imgPath = null;
    let reader = new FileReader();
    reader.readAsDataURL(this.imageDocuments[index].blob);
    reader.onload = async (_event) => {
      if (reader.DONE) {
        imgPath = reader.result;
        let size = this.imageCompress.byteCount(imgPath);
        //reader.onload = (_event, imgPath:void,imgResultBeforeCompress:void,imgResultAfterCompress:void) => {
        if (size > 200000) {
          await this.backupImage(this.imageDocuments[index].img_path);
          this.imageCompress.compressFile(imgPath, -2, 25, 50).then(
            result => {
              let res = this.CrudService.uploadImage(result, this.jobId, this.jobAllocationId, this.imageDocuments[index].img_name);
              this.loading = false;
            }
          );
        }
      }
    };
  }

  allocationList: any;
  showAllocationList = false;
  selectedJobAllocation: any;

  async fetchAllAllocation() {
    this.allocationList = await this.CrudService.getAllAllocatedJobByJobId(this.jobId).toPromise();
    this.loading = false;
    if(this.config.data.jobAllocationId){
      this.selectedJobAllocation  = this.allocationList.find(val =>val.id == this.config.data.jobAllocationId)
    }
    else if (this.allocationList.length == 1) {
      this.selectedJobAllocation = this.allocationList[0]
    }
    if (this.allocationList.length > 0) {
      this.showAllocationList = true;
    } else {
      this.showAllocationList = false;
    }
    this.allocationList.push({ id: '1', business_name: 'Common' })
    if (this.jobAllocationId == '1') {
      if(this.selectedJobAllocId!='' && this.selectedJobAllocId!=null){
        this.jobAllocationId = this.selectedJobAllocId;
      }else{
        this.jobAllocationId = this.allocationList[0].id;
      }
    }
    if ( this.allocationList.length == 1 || (this.allocationList.length > 2 && !this.config.data.jobAllocationId ) ) {
      // this.selectedJobAllocation = this.allocationList.find(val => (val.id == '1'));
      this.selectedJobAllocation =this.allocationList[0]
    }
    if(this.config.data.jobAllocationId == '1'){
      this.selectedJobAllocation = this.allocationList.find(val => (val.id == '1'));
    }
    // this.selectedJobAllocation = this.allocationList.find(val => (val.id == this.jobAllocationId));
    this.changeAllocation();
  }

  changeAllocation() {
    this.jobAllocationId = this.selectedJobAllocation.id;
    this.config.data.jobAllocationId = this.selectedJobAllocation.id;
    this.getUploadImages();
  }

}
