import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { JOBService } from 'src/app/utilities/job.service';
import { delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-job-kpi',
  templateUrl: './job-kpi.component.html',
  styleUrls: ['./job-kpi.component.scss']
})
export class JobKpiComponent implements OnInit {

  measures: any[] = [];
  @Input() jobId: any
  @Input() jobAllocationId: any;
  @Input() jobAllocationResults: any;
  @Input() jobSummary: any;
  @Output() refereshFlag: EventEmitter<any> = new EventEmitter();
  companyId: any;
  userDetails: any;
  loading: Boolean = false;
  isted: any = false;
  newReportData: any;
  costedHours: any;
  costedStaff: any;
  SHFStaff: any;
  SHFHours: any;
  SHFMatrix: any = '1';
  SHFTotal: any;
  jobKpiValueSet: any[] = [];
  jobKpiList: any[] = [];
  CostStaff: any;
  CSOFlag: any;
  costedTotal: any;
  CSODetails: any;
  overallFeedback: any = null;
  totalStaff: any = null;
  totalHours: any = null;
  jobKpis: any[] = [];
  isEdited: any = false;
  promiseList: any[] = [];
  KPITypeList: any[] = [];
  csoImageURL: any;
  kpiMeasure: any;
  showHeader: Boolean = false;
  environment=environment
  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public JobService: JOBService
  ) {
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
  }


  async ngOnInit() {
    this.loading = true;
    if (typeof this.jobId === 'undefined') {
      if (typeof this.config.data == 'undefined' || typeof (this.config.data.jobId) == 'undefined') {
        if (this.jobId == null || this.jobId === '') {
          this.jobId = parseInt(this.route.snapshot.paramMap.get('jobId'));
          if (this.jobAllocationId === null || this.jobAllocationId === '') {
            this.jobAllocationId = '1';
          } else {
            this.jobAllocationId = parseInt(this.route.snapshot.paramMap.get('jobAllocationId'));
          }
        }
        let smryData: any = await this.crudService.getJobSummary(this.jobId, this.jobAllocationId).toPromise();
        this.jobAllocationResults = await this.JobService.getJobInfo(smryData[0]);
        this.showHeader = false;
      } else {
        this.jobId = this.config.data.jobId;
        this.jobAllocationId = this.config.data.jobAllocationId;
        this.jobAllocationResults= this.config.data.jobSummary;
        this.jobSummary = this.config.data.jobSummary;
        this.showHeader = true;
      }
    }
    this.setnewReportData('1');
    this.fetchKPIData();
    console.log("jobAllocationResults",this.jobAllocationResults)
  }

  fetchKPIData() {

    this.crudService.getKpiByJobIdAllocId(this.jobId, this.jobAllocationId).subscribe((data: any[]) => {
      //console.log('get kpi by job allocation ID', data);
      if (data.length > 0) {
        this.measures = data;
        this.crudService.compareTimeOnSite(this.jobAllocationId).subscribe((res: any[]) => {
          //console.log(res);
          if (res.length > 0) {
            this.costedHours = res[0].costedHours;
            this.costedStaff = res[0].costedStaff;
            const costedTotal = this.costedHours;
            this.SHFHours = (res[0].SHFHours / 60).toFixed(2);
            this.SHFStaff = res[0].SHFStaff;
            this.SHFTotal = ((res[0].SHFHours / 60) * this.SHFStaff).toFixed(2);
            if (((costedTotal / this.SHFTotal) - 1) > .25) {
              this.SHFMatrix = '3';
            } else if (((costedTotal / this.SHFTotal) - 1) > 0) {
              this.SHFMatrix = '2';
            } else if (((costedTotal / this.SHFTotal) - 1) < 0) {
              this.SHFMatrix = '1';
            }

          }

        });
        this.isEdited = true;
        this.CostStaff = data[0].CostStaff;
        this.SHFStaff = data[0].SHFStaff;

        this.overallFeedback = this.measures[0].overall_feedback;
        this.totalStaff = this.measures[0].total_staff;
        this.totalHours = this.measures[0].total_hours;

        this.crudService.getJobKpiValueSet().subscribe((kpiValueSet: any[]) => {//Fields Valueset
          //console.log(kpiValueSet);
          this.jobKpiValueSet = kpiValueSet;
          this.measures.forEach(measure => {
            measure.comments = measure.comments ? measure.comments : null;
            measure.jobkpi_id = measure.id;
            //              measure.overallFeedback = this.overallFeedback;
            //              measure.totalStaff = this.totalStaff;
            //              measure.totalHours = this.totalHours;
            let valuesetDetails = kpiValueSet.filter(val => measure.valueset_id === val.valueSetId);
            measure.valuesetDetails = valuesetDetails;
            //measure.kpimeasures_id = measure.id;
          });
          this.crudService.getJobKpiTypes().subscribe((kpiTypes: any[]) => {//KPI Types
            kpiTypes.forEach(kpiType => {
              let measures = this.measures.filter(val => val.value_category === kpiType.id);
              measures.sort(function (a, b) { return b.id - a.id });
              kpiType.measures = measures;
              this.KPITypeList.push(kpiType);
              this.loading = false;
            });
          });
        });
      } else {
        this.isEdited = false;
        //this.crudService.getKpiByJobIdAllocId(this.jobId, this.jobAllocationId).subscribe((data: any[]) => {
        this.crudService.getAllKPIs().subscribe((kpiMesaures: any) => { //Measures
          this.measures = kpiMesaures;
          this.crudService.getJobKpiValueSet().subscribe((kpiValueSet: any[]) => {//Fields Valueset
            //console.log(kpiValueSet);
            this.jobKpiValueSet = kpiValueSet;
            this.measures.forEach(measure => {
              measure.jobkpi_id = null;
              measure.kpimeasures_id = measure.id;
              let valuesetDetails = kpiValueSet.filter(val => measure.valueset_id === val.valueSetId);
              measure.valuesetDetails = valuesetDetails;
            });
            this.crudService.getJobKpiTypes().subscribe((kpiTypes: any[]) => {//KPI Types
              kpiTypes.forEach(kpiType => {
                let measures = this.measures.filter(val => val.value_category === kpiType.id);
                measures.sort(function (a, b) { return b.display_order - a.display_order });
                kpiType.measures = measures;
                this.KPITypeList.push(kpiType);
                this.loading = false;
              });
            });
          });
        });
      }
    });
  }

  editSelectedItem(jobKpi) {
    this.measures.forEach(measure => {
      if (jobKpi.id) {
        let mesUpdateObj = {
          id: measure.jobkpi_id,
          comments: measure.comments,
          kpimeasures_id: measure.id,
          valueset_detail_id: measure.valueDetail ? measure.valueDetail : null,
          valueset_id: measure.valueset_id,
          process_id: this.companyId,
          last_updated_at: /*new Date(),*/measure.last_updated_at ? measure.last_updated_at : null,
          created_at: null,
          overall_feedback: this.overallFeedback,
          total_staff: this.totalStaff,
          total_hours: this.totalHours,
          value_type: 'R',
          job_id: this.jobId,
          job_alloc_id: this.jobAllocationId
        }
      }
    });
  }

  async saveRecord(mesUpdateObj) {
    let response: any;
    if (mesUpdateObj.id) {
      response = await this.crudService.Update(mesUpdateObj, 'JobKPI').toPromise();
    } else {
      response = await this.crudService.Create(mesUpdateObj, 'JobKPI').toPromise();
    }
    return response;
  }

  async saveDetails(): Promise<Boolean> {
    let success: Boolean = true;
    this.KPITypeList.forEach(kpiType => {
      kpiType.measures.forEach(async measure => {

        if (measure.jobkpi_id && this.isEdited) {
          let mesUpdateObj = {
            id: measure.jobkpi_id ? measure.jobkpi_id : null,
            job_id: this.jobId,
            job_alloc_id: this.jobAllocationId,
            kpimeasures_id: measure.kpimeasures_id,
            valueset_id: measure.valueset_id,
            valueset_detail_id: measure.valueset_detail_id ? measure.valueset_detail_id : null,
            comments: measure.comments ? measure.comments : null,
            overall_feedback: this.overallFeedback,
            total_staff: this.totalStaff,
            total_hours: this.totalHours,
            created_at: /*new Date(),*/measure.created_at ? (measure.created_at) : new Date(),
            last_updated_at: new Date(),
            value_type: measure.value_type ? measure.value_type : 'M',
            process_id: this.companyId,
          }
          if (mesUpdateObj.created_at != null) {
            mesUpdateObj.last_updated_at = (measure.last_updated_at);
            mesUpdateObj.created_at = mesUpdateObj.created_at;
          } 

          let data = await this.saveRecord(mesUpdateObj);
          //console.log(data);
          if (data.success === 1) {
            this.loading = false;
            //this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'KPI Details Updated', life: 1000 });
            if (measure.id == 14 && mesUpdateObj.valueset_detail_id == 10118) {
              let params = {
                email: {
                  to: 'operations@envirofrontier.com.au',
                  cc: 'admin@envirofrontier.com.au',
                  bcc: null,
                  msg: 'This is a mail to notify that the Tree Partner did not put up safety signs for Job No : ' + this.jobAllocationResults.JobNo + '  The details are as below . \n  ' +
                    'Risk Address : ' + this.jobAllocationResults.info.location + '. \n' + ' Tree Partner : ' + this.jobAllocationResults.info.professional + '. \n',
                  subject: 'TP failed to put up safety signs, TM Job Number - ' + this.jobAllocationResults.JobNo + ', Site Address - ' + this.jobAllocationResults.info.location,
                  sms: null, type: 'email', selectedTpl: null, sufix: null
                }
              }
              this.crudService.sendMessage(params).subscribe((res2: any[]) => {
                //console.log(res2);
              });
            }
          } else {
            return false;
          }
        } else {
          //if the user add a new measure so ,it will create a new data in the table
          let mesUpdateObj = {
            id: measure.jobkpi_id ? measure.jobkpi_id : null,
            job_id: this.jobId,
            job_alloc_id: this.jobAllocationId,
            kpimeasures_id: measure.id,
            valueset_id: measure.valueset_id,
            valueset_detail_id: measure.valueset_detail_id ? measure.valueset_detail_id : null,
            comments: measure.comments ? measure.comments : null,
            overall_feedback: this.overallFeedback,
            total_staff: this.totalStaff,
            total_hours: this.totalHours,
            created_at: /*new Date(),*/measure.created_at ? (measure.created_at) : new Date(),
            last_updated_at: new Date(),
            value_type: measure.value_type ? measure.value_type : 'M',
            process_id: this.companyId,
          }
          let data: any = this.crudService.Create(mesUpdateObj, 'JobKPI').pipe(delay(1000)).toPromise();
          //console.log(data);
          if (data.success === 1) {
            this.loading = false;
            mesUpdateObj.id = data.id;
            measure.jobkpi_id = data.id
            //this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'KPI Details Updated', life: 1000 });
            if (measure.id == 14 && mesUpdateObj.valueset_detail_id == 10118) {
              let params = {
                email: {
                  to: 'operations@envirofrontier.com.au',
                  cc: 'admin@envirofrontier.com.au',
                  bcc: null,
                  msg: 'This is a mail to notify that the Tree Partner did not put up safety signs for Job No : ' + this.jobAllocationResults.JobNo + '  The details are as below . \n  ' +
                    'Risk Address : ' + this.jobAllocationResults.info.location + '. \n' + ' Tree Partner : ' + this.jobAllocationResults.info.professional + '. \n',
                  subject: 'TP failed to put up safety signs, TM Job Number - ' + this.jobAllocationResults.JobNo + ', Site Address - ' + this.jobAllocationResults.info.location,
                  sms: null, type: 'email', selectedTpl: null, sufix: null
                }
              }
              this.crudService.sendMessage(params).subscribe((res2: any[]) => {
                //console.log(res2);
              });
            }
          } else {
            return false;
          }
        }
      });
    });
    return success;
  }

  async save() {
    this.KPITypeList.forEach(kpiType => {
      kpiType.measures.forEach(async measure => {
        if(measure.valueset_detail_id && measure.comments){
          measure.flag=true;
        }
        else{
          measure.flag=false;
        }
      })});
      this.KPITypeList.forEach(kpiType => {
        let data = kpiType.measures.filter(entry => entry.flag === false);
        if(data.length == 0){
          kpiType.flag=true;
        }else{
          kpiType.flag=false;
        }

      });
      let data = this.KPITypeList.filter(entry => entry.flag === false);
      if(environment.hosting =='UK' && !this.totalStaff ){
        this.totalStaff = 'NA'
      }
      if(environment.hosting =='UK' && !this.totalHours ){
        this.totalHours = 'NA'
      }
    if(this.overallFeedback && this.totalStaff && this.totalHours){
      this.loading = true;
    let totalStaffFromCosting = null;
    let totalHrsFromCosting = null;
    let variableHours = null;
    this.crudService.getCostDetailStaffInfo().subscribe((Details) => {
      totalStaffFromCosting = Details[0].TotalStaff;
      totalHrsFromCosting = Details[0].Hours;
      variableHours = parseFloat(this.totalHours)/*+ parseFloat(this.totalHours*0.2)*/
      if (parseFloat(this.totalStaff) > 0 && parseFloat(this.totalHours) > 0) {
        if (this.totalStaff < totalStaffFromCosting || variableHours < totalHrsFromCosting) {
          let params = {
            email: {
              to: 'venkat@agileitexperts.com', // 'venkat@agileitexperts.com','operations@envirofrontier.com.au',
              cc: null, //'admin@envirofrontier.com.au',
              bcc: null,
              //   'email':{'to':"krishnanshridhar@gmail.com", 'cc':null,'bcc':"shri@envirofrontier.com.au",
              msg: 'This is a mail to notify that there is a variation in KPI hours Vs Costed Hours for Job No : ' + this.jobAllocationResults.JobNo + '  The details are as below . \n  ' +
                'Risk Address : ' + this.jobAllocationResults.info.location + '. \n' + ' Tree Partner : ' + this.jobAllocationResults.info.professional + '. \n' +
                'KPI Staff : ' + this.totalStaff + ', KPI Hours: ' + this.totalHours + '.\n' +
                'Costed Staff : ' + totalStaffFromCosting + ', Costed Hours: ' + totalHrsFromCosting + '.\n',
              subject: 'Mismatch of KPI Vs Costed Staff Numbers and time , TM Job Number - ' + this.jobAllocationResults.JobNo + ', Site Address - ' + this.jobAllocationResults.info.location,
              sms: null, type: 'email', selectedTpl: null, sufix: null
            }
          }
        }
      }
    });
    let success = await this.saveDetails();

    if (success && this.isEdited) {
      this.storeInScheduleKPIupdate('KPI is Updated');
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'KPI Details Updated', life: 1000 });
    } else if (success && !this.isEdited) {
      this.storeInScheduleKPIupdate('KPI is Created');
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'KPI Details Created', life: 1000 });
    } else if (!success) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'KPI Details Not Updated', life: 1000 });
    }
    this.loading = false;
    this.refereshFlag.emit("KPI Saved");
    }
    else{
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Fill KPI Details', life: 1000 });
    }
    
  }

  storeInScheduleKPIupdate(note) {
    let store_data = {
      id: null,
      job_id: this.jobId,
      job_alloc_id: this.jobAllocationId,
      process_id: 1,
      visit_type: null,
      status: null,
      sched_date: null,
      PMOnly: '2',
      sched_note: note,
      start_time: null,
      end_time: null,
      created_by: this.userDetails.id,
      created_at: new Date(),
      phoned: '1',
      phone_no: null,
      sms: '1',
      sms_no: null,
      email: '1',
      emailaddress: null,
      callback: '1'
    }

    this.crudService.Create(store_data, 'JobSchedule').subscribe((data: any) => {
      //console.log(data);
    });
  }

  generatePdf() {
    this.loading = true;
    let reportId = 'KPI';
    let date = new Date();
    let tempTime = date.getTime();
    let data = this.setData(reportId);
    this.newReportData.Feedback = this.overallFeedback ? this.overallFeedback : '';
    const jobInfoContactNo: string = this.newReportData.CustomerDetails;
    let jobInfoContact: string;
    if (jobInfoContactNo.includes(',')) {
      jobInfoContact = jobInfoContactNo.split(',')[0].trim();
    } else {
      jobInfoContact = '';
    }
    this.newReportData.customerName = jobInfoContact
    this.newReportData.CompletionDate = this.newReportData.CompletionDate ? this.newReportData.CompletionDate : '';
    this.newReportData.InspectionDate = this.newReportData.InspectionDate ? this.newReportData.InspectionDate : '';
    this.newReportData.scheduledAttendanceDate = this.newReportData.scheduledAttendanceDate ? this.newReportData.scheduledAttendanceDate : '';
    this.newReportData.SIGN = this.newReportData.SIGN ? this.newReportData.SIGN : '';
    this.newReportData.SignDate = this.newReportData.SignDate ? this.newReportData.SignDate : '';
    data[0].newReportData = this.newReportData;
    this.crudService.generateNewKPIReport(this.jobId, this.jobAllocationId, data, reportId, tempTime, 1).subscribe((res: any) => {
      this.confirmationService.confirm({
        message: 'Do you want to view the report in separate Window?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Yes, Open in new Tab',
        rejectLabel: 'No, Download the File',
        accept: () => {
          let blob = new Blob([res], { type: "application/pdf" });
          //this.pdfUrl = this.crudService.getAPIRoot() + '/' + this.jobId + '/' + this.jobAllocationId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf';
          const objectUrl = URL.createObjectURL(blob);
          window.open(objectUrl);
          this.deleteDoc();
          this.addUploadDoc(tempTime, reportId);
          this.loading = false;
        },
        reject: () => {
          import('file-saver').then(FileSaver => {
            let blob = new Blob([res], { type: "application/pdf" });
            const a = document.createElement('a')
            const objectUrl = URL.createObjectURL(blob)
            a.href = objectUrl
            a.download = 'KPI Report (' + this.jobAllocationId + ')';
            a.click();
            URL.revokeObjectURL(objectUrl);
            this.deleteDoc();
            this.addUploadDoc(tempTime, reportId);
            this.loading = false;
          })
        }
      })
    })
  }

  addUploadDoc(tempTime, reportId) {
    //add it to doc list
    let docReadyUpload = {
      id: null,
      job_id: parseInt(this.jobId),
      job_alloc_id: parseInt(this.jobAllocationId),
      company_id: 1,
      upload_type: 0,
      process_id: 1,
      inc_quote: 2,
      upload_at: new Date(),
      file_size: 33000,
      hide: 2,
      upload_by: parseInt(this.userDetails.id),
      file_name: 'KPI_' + this.jobAllocationResults.JobNo + '.pdf',
      file_description: 10065,
      upload_type_detail: 0,
      file_path: this.jobId + '/' + this.jobAllocationId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf',
      status: 1,
      s3_url: null,
      sync_status: 0
    }
    this.crudService.Create(docReadyUpload, 'uploaddocuments').subscribe((data: any) => {
      if (data.success === 1) {
      }
    });
  }

  deleteDoc() {
    let fileName = 'KPI_' + this.jobAllocationResults.JobNo + '.pdf'
    this.crudService.deleteUploadPdf(this.jobAllocationId, fileName).subscribe((data: any) => {
      if (data.success === 1) {
      }
    });
  }

  setnewReportData(reportId) {
    this.CSOFlag = false;
    let promiseList = [];
    let data = '';
    this.crudService.getCSOData(this.jobAllocationId).subscribe((data: any[]) => {
      if (data.length > 0) {
        this.CSODetails = data[0];
        let signExists = null;
        if (this.CSODetails.SignaturePath == null) {
          signExists = 'false';
          this.CSOFlag = true;
        } else {
          signExists = 'true';
          this.CSOFlag = false;
          let result = this.crudService.getCSOImgFile('' + this.jobId + '/' + this.jobAllocationId + '/CSO_Sign.jpg').subscribe(blob => {
            this.csoImageURL = URL.createObjectURL(blob);
          });
        }
        this.newReportData = {
          TEMPLATE_ID: this.CSODetails.TEMPLATE_ID, 
          JobNo: this.CSODetails.JobNo, 
          JobRefNo: this.CSODetails.JobRefNo,
          InspectionDate: this.CSODetails.InspectionDate,
          CompletionDate: this.CSODetails.CompletionDate, 
          ClientName: this.CSODetails.ClientName,
          ContactNo: 'Test', 
          CustomerDetails: this.CSODetails.CustomerDetails, 
          Datetime: '18/12/2020 11AM', 
          SiteAddress: this.CSODetails.SiteAddress,
          WorkOrder: this.CSODetails.WorkOrderDetails, 
          Feedback: this.overallFeedback, 
          ImageURL: 'C:/companyCompliances/' + this.CSODetails.WPCompanyId + '/company_logo.png',
          SignExists: signExists, 
          SIGN: this.CSODetails.SignaturePath, 
          SignDate: this.CSODetails.SignDate,
        }
      }
    })
  }

  setData(reportId) {
    let item = [];
    let k = 0;
    let scores = [];
    this.jobKpiValueSet.forEach(jokvs => {
      this.measures.forEach(measure => {
        if (measure.valueDetail == jokvs.valueSetDetailId) {
          measure.score = jokvs.valueset_detail_name;
        }
      });
    });
    let kpiMeasures: any[] = [];
    this.KPITypeList.forEach(kpiType => {
      kpiMeasures.push(kpiType.measures);
    });

    for (let z = 0; z < kpiMeasures.length; z++) {
      let obj = {
        id: z + 1,
        key: kpiMeasures[z].kpi_measure_name,
        score: kpiMeasures[z].score
      }
      item.push(obj);
    }
    let data: any = '';

    
    data = [
      {
        img_src: this.jobId + '/' + this.jobAllocationId + '/CSO_Sign.jpg',
        info: {
          id: '67993bdd-f7d9-48a6-93d9-8026b657041a',
          jobid: 1,
          reportid: reportId
        },
        company: {
          job_num: this.jobAllocationResults.JobNo,
          contractor: 'TEST COMPANY - Enviro Tree Service',
          customerDetails: this.jobAllocationResults.info.contact,
        },
        items: [
          {
            id: 1,
            detail: 'Tree Partner Performance as reviewed ',
            item: item
          }
        ],
        overallFeedback: this.overallFeedback,
        totalStaff: this.totalStaff,
        totalHours: this.totalHours
      }
    ];

    return data;
  }
}
