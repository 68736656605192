import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CRUDService } from 'src/app/utilities/crud.service';
import { CommonService } from 'src/app/utilities/common.service';
import { JOBService } from 'src/app/utilities/job.service';

@Component({
  selector: 'app-sub-contract-agreement-create',
  templateUrl: './sub-contract-agreement-create.component.html',
  styleUrls: ['./sub-contract-agreement-create.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService, JOBService]
})
export class SubContractAgreementCreateComponent implements OnInit {
  companyId: any;
  userDetails: any
  submitted: any;
  loading: Boolean = false;
  reportData: any;
  @Input() jobId: any
  @Input() jobAllocationId: any;
  @Input() jobSummary: any;
  appProps: any;

  subcontractagreement: any = {
    id: null,
    job_id: null,
    job_alloc_id: null,
    bus_name: null,
    bus_abn: null,
    process_id: 1,
    address: null,
    contractor_name: null,
    contractor_abn: null,
    contract_number: null,
    applied_from: null,
    applied_to: null,
    pay_claim_date: null,
    director: null,
    work_comp_date: null,
    full_name: null,
    position: null
  };

  invoice_date: null;
  applied_from_date_str = '';
  showHeader: Boolean = false;



  contractorrForm: FormGroup;
  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public JobService: JOBService,
    private commonservice: CommonService
  ) { }
  options = {
    componentRestrictions: {
      country: ['AU']
    }
  }
  componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    sublocality_level_1: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'
  };

  async ngOnInit() {
    this.appProps = this.commonservice.getAppProperties();
    this.companyId = sessionStorage.getItem('company_id');
    this.userDetails = JSON.parse(sessionStorage.getItem('loggedInUser'));
    if (typeof this.jobId === 'undefined') {
      if (typeof this.config.data == 'undefined' || typeof (this.config.data.jobId) == 'undefined') {
        if (this.jobId == null || this.jobId === '') {
          this.jobId = parseInt(this.route.snapshot.paramMap.get('jobId'));
          if (this.jobAllocationId === null || this.jobAllocationId === '') {
            this.jobAllocationId = '1';
          } else {
            this.jobAllocationId = parseInt(this.route.snapshot.paramMap.get('jobAllocationId'));
          }
        }
        let smryData: any = await this.crudService.getJobSummary(this.jobId, this.jobAllocationId).toPromise();
        this.jobSummary = await this.JobService.getJobInfo(smryData[0]);
        this.showHeader = false;
      } else {
        this.jobId = this.config.data.jobId;
        this.jobAllocationId = this.config.data.jobAllocationId;
        this.jobSummary= this.config.data.jobSummary;
        this.showHeader = true;
      }
    }
    this.crudService.getLoadedAgreement(this.jobId, this.jobAllocationId).subscribe((agreements: any[]) => {
      if (agreements.length > 0) {
        this.subcontractagreement = agreements[0];
        //console.log('this.subcontractagreement', this.subcontractagreement);
        //console.log(this.subcontractagreement.work_comp_date);
        this.subcontractagreement.pay_claim_date = new Date(this.subcontractagreement.pay_claim_date);
        this.subcontractagreement.applied_from = new Date(this.subcontractagreement.applied_from);
        this.subcontractagreement.work_comp_date = new Date(this.subcontractagreement.work_comp_date);
        this.subcontractagreement.applied_to = new Date(this.subcontractagreement.applied_to);

      } else {
        this.subcontractagreement = {
          id: null,
          job_id: this.jobId,
          job_alloc_id: this.jobAllocationId,
          bus_name: null,
          bus_abn: null,
          process_id: 1,
          address: null,
          contractor_name: null,
          contractor_abn: null,
          contract_number: null,
          applied_from: null,
          applied_to: null,
          pay_claim_date: null,
          director: null,
          work_comp_date: null,
          full_name: null,
          position: null
        };
      }
    })

    this.contractorrForm = new FormGroup({
      bus_name: new FormControl('', Validators.required),
      bus_abn: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      contractor_name: new FormControl('', Validators.required),
      contractor_abn: new FormControl('', Validators.required),
      contract_number: new FormControl('', Validators.required),
      applied_from: new FormControl('', Validators.required),
      applied_to: new FormControl('', Validators.required),
      pay_claim_date: new FormControl('', Validators.required),
      director: new FormControl('', Validators.required),
      work_comp_date: new FormControl('', Validators.required),
      full_name: new FormControl('', Validators.required),
      position: new FormControl('', Validators.required),
    })

  }
  save() {
    this.submitted = true;
    //console.log(this.subcontractagreement);
    if (this.contractorrForm.status === 'VALID') {
      // this.subcontractagreement.pay_claim_date =this.DateToLocalTimeString(  this.pay_claim_date);
      //  this.subcontractagreement.applied_from  =this.DateToLocalTimeString(this.applied_from);
      // this.subcontractagreement.work_comp_date  =this.DateToLocalTimeString( this.work_comp_date);
      //  this.subcontractagreement.applied_to_date =this.DateToLocalTimeString( this.applied_to_date)

      if (this.subcontractagreement.id === null) {
        this.crudService.Create(this.subcontractagreement, 'SubContractAgreement').subscribe((res: any) => {
          //console.log('SubContractAgreement created', res);
          if (res.success === 1) {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Sub Contract Agreement Created', life: 1000 });
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Sub Contract Agreement not Saved', life: 1000 });
          }

        })
      }
      if (this.subcontractagreement.id !== null) {
        this.crudService.Update(this.subcontractagreement, 'SubContractAgreement').subscribe((res: any) => {
          //console.log('SubContractAgreement Updated', res);
          if (res.success == 1) {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Sub Contract Agreement Updated', life: 1000 });
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Sub Contract Agreement not Saved', life: 1000 });
          }
        })
      }
    }
  }

  AddressChange(address: any) {
    //console.log(address);
    const fullAddress = [];
    for (let i = 0; i < address.address_components.length; i++) {
      const addressType = address.address_components[i].types[0];
      // }
      if (this.componentForm[addressType]) {

        const val = address.address_components[i][this.componentForm[addressType]];

        if (addressType === 'street_number') {
          fullAddress[0] = val;
        } else if (addressType === 'route') {
          fullAddress[1] = val;
        }


        if (addressType === 'locality') {// suburb
          fullAddress[2] = val;
        } else if (addressType === 'administrative_area_level_1') {// state
          fullAddress[3] = val;

        } else if (addressType === 'administrative_area_level_2') {//council
          //                                                this.suburb = val;
        } else if (addressType === 'country') {
          fullAddress[4] = val;
        } else if (addressType === 'postal_code') {
          fullAddress[5] = val;
        }

      }
    }
    const fullAdr = fullAddress.join(' ');
    this.subcontractagreement.address = fullAdr;

    //console.log(this.subcontractagreement.address);
  }

  preFillInfo() {
    let dflt_dt = new Date(this.jobSummary.Date).toLocaleDateString();

    this.subcontractagreement = {
      id: this.subcontractagreement.id ? this.subcontractagreement.id : null,
      job_id: this.jobId,
      job_alloc_id: this.jobAllocationId,
      bus_name: 'Enviro Frontier Pty Ltd',
      bus_abn: 74100414944,
      process_id: 1,
      address: '51/90 Mona Vale Road, Mona Vale NSW',
      contractor_name: this.jobSummary.info.TPName ,
      contractor_abn: this.jobSummary.info.WP_ABN,
      contract_number: this.jobSummary.location,
      applied_from: this.jobSummary.Date ? new Date(this.jobSummary.Date) : new Date(),
      applied_to: new Date(),
      pay_claim_date: new Date(),
      director: 'Peter Dubiez',
      work_comp_date: new Date('2022-06-30'),
      full_name: 'Peter Dubiez',
      position: 'Manager'
    }
  }

  DateToLocalTimeString(d) {
    let date = new Date(d);
    d = date.toLocaleDateString('en-GB');
    let t = date.toLocaleTimeString();
    return d + ' ' + t;
  }


  async generatePdf() {
    let reportId = '5';
    let date = new Date();
    let tempTime = date.getTime();
    let data = await this.JobService.setSCAData(this.jobId, this.jobAllocationId, reportId, this.jobSummary);
    data[0].newReportData = this.reportData;
    //import('file-saver').then(FileSaver => {
    this.loading = true;
    this.crudService.generateReport(data, reportId, this.jobId, this.jobAllocationId, tempTime, 1).subscribe((res: any) => {
      this.confirmationService.confirm({
        message: 'Do you want to view the report in separate Window?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Yes, Open in new Tab',
        rejectLabel: 'No, Download the File',
        accept: () => {
          let blob = new Blob([res], { type: 'application/pdf' });
          //this.pdfUrl = this.crudService.getAPIRoot() + '/' + this.jobId + '/' + this.jobAllocationId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf';
          const objectUrl = URL.createObjectURL(blob);
          window.open(objectUrl);
          /* const a = document.createElement('a')
          a.href = objectUrl
          a.download = 'SHF Report (' + this.jobAllocationId + ')';
          a.click();
          URL.revokeObjectURL(objectUrl); */
          this.deleteDoc();
          this.addUploadDoc(tempTime, reportId);
          this.loading = false;
        },
        reject: () => {
          import('file-saver').then(FileSaver => {
            let blob = new Blob([res], { type: 'application/pdf' });
            //this.pdfUrl = this.crudService.getAPIRoot() + '/' + this.jobId + '/' + this.jobAllocationId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf'
            /* const objectUrl = URL.createObjectURL(blob);
            window.open(objectUrl); */
            const a = document.createElement('a')
            const objectUrl = URL.createObjectURL(blob)
            a.href = objectUrl
            a.download = 'SCA Report (' + this.jobAllocationId + ')';
            a.click();
            URL.revokeObjectURL(objectUrl);
            this.deleteDoc();
            this.addUploadDoc(tempTime, reportId);
            this.loading = false;
          })
      }
      })
    })
  }

  addUploadDoc(tempTime, reportId) {
    //add it to doc list
    let docReadyUpload = {
      id: null,
      job_id: parseInt(this.jobId),
      job_alloc_id: parseInt(this.jobAllocationId),
      company_id: 1,
      upload_type: 0,
      process_id: 1,
      inc_quote: 2,
      upload_at: new Date(),
      file_size: 120000,
      hide: 2,
      upload_by: parseInt(this.userDetails.id),
      file_name: 'SCA_' + this.jobSummary.JobNo + '.pdf',
      file_description: 10040,
      upload_type_detail: 0,
      file_path: this.jobId + '/' + this.jobAllocationId + '/' + 'report-template-' + reportId + '-' + tempTime + '.pdf',
      status: 1,
      s3_url: null,
      sync_status: 0
    }
    this.crudService.Create(docReadyUpload, 'uploaddocuments').subscribe((data: any) => {
      if (data.success === 1) {
      }
    });
  }

  deleteDoc() {
    let fileName = 'SCA_' + this.jobSummary.JobNo + '.pdf'
    this.crudService.deleteUploadPdf(this.jobAllocationId, fileName).subscribe((data: any) => {
      if (data.success === 1) {
      }
    });
  }

}
